import React, { useEffect, useState, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import moment from "moment";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "../AddBlog.css";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
const AddBlog = () => {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  // const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [error, setError] = useState(false);
  const [reqMsg, setReqMsg] = useState(
    <span style={{ color: "red", fontSize: "0.875rem" }}>Field Required *</span>
  );
  const [saved, setSaved] = useState("");
  const [image, setImage] = useState("");
  const [category, setCategory] = useState([]);
  const [blogData, setBlogData] = useState({
    category: "",
    blog_type: "",
    top_type: "",
    title: "",
    writer_name: "",
    description: "",
    current_date: "",
  });

  let name, value;
  const handleFormData = (e) => {
    // console.log(e);
    name = e.target.name;
    value = e.target.value;

    setBlogData({ ...blogData, [name]: value });
    // console.log({ ...blogData, [name]: value });
  };
  const handleEditorChange = (newContentState) => {
    console.log(newContentState);
    setBlogData((prevData) => ({
      ...prevData,
      // description: JSON.stringify(newContentState),
      description: newContentState,
    }));
  };
  // // Check if formData.description is defined and non-empty
  // const parsedDescription = blogData.description
  //   ? JSON.parse(blogData.description)
  //   : null;
  // const initial = JSON.parse(sessionStorage.getItem("draftail:content"));
  const handleImageData = (e) => {
    // console.log(e);
    setImage(e.target.files[0]);
    // console.log(e.target.files[0]);
  };
  const navigate = useNavigate();

  const collectData = async () => {
    // const admin = localStorage.getItem('admin');
    const date = moment();
    const d = date.format("YYYY-MM-DD");
    if (
      !blogData.title ||
      !blogData.description ||
      // !blogData.current_date ||
      !blogData.category ||
      !blogData.blog_type ||
      !blogData.top_type ||
      !blogData.writer_name ||
      !image
    ) {
      setError(true);
      return false;
    }
    const { title, description, category, blog_type, top_type, writer_name } =
      blogData;

    const formData = new FormData();
    formData.append("category", category);
    formData.append("blog_type", blog_type);
    formData.append("top_type", top_type);
    formData.append("blog_pic", image);
    formData.append("title", title);
    formData.append("writer_name", writer_name);
    formData.append("description", description);
    formData.append("current_date", d);
    console.log(formData);
    const res = await fetch(SERVER_URL + "/blogData", {
      method: "POST",
      body: formData,
    });
    const data = await res.json();
    if (data.status === 422 || !data) {
      window.alert(data.error + " Invalid Information");
    } else if (res.ok) {
      // window.alert("Blog Saved");
      setSaved(<span style={{ color: "green" }}>Blog Saved</span>);
      document.getElementById("reset").reset();
      setImage("");
      setBlogData({
        category: "",
        blog_type: "",
        top_type: "",
        title: "",
        writer_name: "",
        description: "",
        current_date: "",
      });
      setTimeout(() => {
        setSaved("");
      }, 2000);
    }
  };

  //Fetching Category
  useEffect(() => {
    const fetchInfo = async () => {
      const res = await fetch(SERVER_URL + "/get_category");
      const data = await res.json();
      // console.log(data);
      setCategory(data);
    };
    fetchInfo();
  }, []);

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      // ["blockquote", "code-block"],
      [{ list: "ordered" }],
      [{ align: [] }],
      ["link", "image"],
    ],
  };
  const fileInputRef = useRef(null);
  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };
  return (
    <>
      <div>
        <form class="my-form bg_main" encType="multipat/form-data">
          <div class="col-md-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title mb-4 pb-4">ADD BLOG</h4>
                <div className="text-center">{saved}</div>

                <form id="reset">
                  <div class="form-group">
                    <label>Blog Title</label>
                    <input
                      type="text"
                      placeholder="Enter Title"
                      value={blogData.title}
                      name="title"
                      className="form-control"
                      onChange={handleFormData}
                    />
                    {error && !blogData.title && reqMsg}
                  </div>
                  <div className="row">
                    <div className="form-group col">
                      <label>Blog Category</label>

                      <select
                        name="category"
                        className="dropdown form-control"
                        onChange={handleFormData}
                      >
                        <option value="">Select Category From Here</option>
                        {category.map((get_cat) => (
                          <option value={get_cat.category_name}>
                            {get_cat.category_name.toUpperCase()}
                          </option>
                        ))}
                      </select>
                      {error && !blogData.category && reqMsg}
                    </div>
                    <div className="form-group col">
                      <label>Blog Type</label>

                      <select
                        name="blog_type"
                        className="dropdown form-control"
                        onChange={handleFormData}
                      >
                        <option value="">Select Type From Here</option>

                        <option value="free">FREE</option>
                        <option value="premium">PREMIUM</option>
                      </select>
                      {error && !blogData.blog_type && reqMsg}
                    </div>
                    <div class="form-group col">
                      <label>Blog Image</label>
                      <input
                        type="file"
                        name="blog_pic"
                        class="file-upload-default"
                        ref={fileInputRef}
                        onChange={handleImageData}
                      />
                      <div class="input-group col-xs-12">
                        <input
                          type="text"
                          class="form-control file-upload-info"
                          readOnly
                          onClick={handleUploadButtonClick}
                          placeholder={image ? image.name : "Upload Image"}
                        />
                        <span class="input-group-append">
                          <button
                            class="file-upload-browse btn appButton"
                            type="button"
                            onClick={handleUploadButtonClick}
                          >
                            Upload
                          </button>
                        </span>
                      </div>
                      {error && !image && reqMsg}
                    </div>
                    {/* <div className="form-group col">
                      <label>Blog Image</label>
                      <input
                        type="file"
                        name="blog_pic"
                        className="form-control formfile"
                        onChange={handleImageData}
                      />
                      {error && !image && reqMsg}
                    </div> */}
                  </div>
                  <div className="row">
                    <div className="form-group col">
                      <label>Blog Top Type</label>

                      <select
                        onChange={handleFormData}
                        name="top_type"
                        className="form-control"
                      >
                        <option value="">Select</option>
                        <option value="normal">Normal</option>
                        <option value="top 1">Top 1</option>
                        <option value="top 2">Top 2</option>
                        <option value="top 3">Top 3</option>
                        <option value="top 4">Top 4</option>
                        <option value="top 5">Top 5</option>
                      </select>
                      {error && !blogData.top_type && reqMsg}
                    </div>
                    <div class="form-group col">
                      <label>Writer's Name </label>

                      <input
                        type="text"
                        placeholder="Enter Writer's Name"
                        value={blogData.writer_name}
                        name="writer_name"
                        className="form-control"
                        onChange={handleFormData}
                      />
                      {error && !blogData.writer_name && reqMsg}
                    </div>
                  </div>
                  {/* <div className="form-group col">
                      <label>Blog Date</label>

                      <input
                        type="date"
                        className="date form-control"
                        value={blogData.current_date}
                        name="current_date"
                        placeholder="Date"
                        onChange={handleFormData}
                      />
                      {error && !blogData.current_date && reqMsg}
                    </div> */}
                  {/* <div class="form-group">
                    <label>Blog Description</label>

                    <textarea
                      rows="10"
                      placeholder="Enter The Blog Description"
                      value={blogData.description}
                      name="description"
                      className="form-control"
                      onChange={handleFormData}
                    />
                    {error && !blogData.description && reqMsg}
                  </div> */}
                  <div class="form-group">
                    <label>Blog Description</label>

                    <ReactQuill
                      placeholder="Enter The Blog Description"
                      theme="snow"
                      modules={modules}
                      // name="description"
                      value={blogData.description}
                      onChange={handleEditorChange}
                    />
                    {error && !blogData.description && reqMsg}
                  </div>

                  <div className="form-group">
                    {/* <input
                      type="submit"
                      
                      value="Reset"
                      className="btn appButton"
                    /> */}
                    <button
                      onClick={collectData}
                      className="btn appButton"
                      type="button"
                    >
                      Add Blog
                    </button>{" "}
                  </div>
                </form>
                {/* </form> */}
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddBlog;
