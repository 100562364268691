import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
// import "bootstrap/dist/css/bootstrap.min.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
const UpdateEvent = () => {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const params = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [reqMsg, setReqMsg] = useState(
    <span style={{ color: "red", fontSize: "0.875rem" }}>Field Required *</span>
  );
  const [saved, setSaved] = useState("");
  const [image, setImage] = useState("");
  const [title, setTitle] = useState("");

  const [RESImage, setRESImage] = useState("");
  const [writer_name, setWriter_name] = useState("");
  const [description, setDescription] = useState("");
  const [current_date, setCurrent_date] = useState("");
  const [time, setTime] = useState("");
  const [location, setLocation] = useState("");
  const [eventData, setEventData] = useState({
    title: "",
    writer_name: "",
    description: "",
    current_date: "",
    time: "",
    location: "",
  });

  let name, value;
  const handleFormData = (e) => {
    // console.log(e);
    name = e.target.name;
    value = e.target.value;

    setEventData({ ...eventData, [name]: value });
    // console.log({ ...eventData, [name]: value });
  };
  const handleEditorChange = (newContentState) => {
    console.log(newContentState);
    setEventData((prevData) => ({
      ...prevData,
      // description: JSON.stringify(newContentState),
      description: newContentState,
    }));
  };
  const handleImageData = (e) => {
    // console.log(e);
    setImage(e.target.files[0]);
    // console.log(e.target.files[0]);
  };

  const getEventData = async () => {
    // console.warn(params);
    if (params.id.length !== 24) {
      navigate("/manageEvent");
      return false;
    }
    let result = await fetch(`${SERVER_URL}/eventData/${params.id}`);
    result = await result.json();
    setTitle(result.title);
    setWriter_name(result.writer_name);
    setCurrent_date(result.current_date);
    setTime(result.time);
    setLocation(result.location);
    setDescription(result.description);
    setRESImage(result.event_pic);
  };

  useEffect(() => {
    getEventData();
  }, []);

  const updateEvent = async () => {
    // console.warn(product_name, price, category, company)
    const adminId = JSON.parse(localStorage.getItem("admin"))._id;
    if (
      !eventData.title ||
      !eventData.description ||
      !eventData.current_date ||
      !eventData.writer_name ||
      !eventData.time ||
      !eventData.location
    ) {
      setError(true);
      return false;
    }

    const {
      title,
      description,
      current_date,
      time,
      location,

      writer_name,
    } = eventData;

    const formData = new FormData();

    formData.append("event_pic", image || ("event_pic", RESImage));
    formData.append("title", title);
    formData.append("writer_name", writer_name);
    formData.append("description", description);
    formData.append("current_date", current_date);
    formData.append("time", time);
    formData.append("location", location);
    formData.append("adminId", adminId);
    console.log(formData);
    let result = await fetch(`${SERVER_URL}/eventData/${params.id}`, {
      method: "PUT",
      body: formData,
    });
    const data = await result.json();
    if (result.ok) {
      setSaved(<span style={{ color: "green" }}>Event Saved</span>);

      navigate("/manageevent");
    } else if (data.error || data.status === 422) {
      window.alert(data.error + "Invalid");
    }
  };
  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline"],
      [{ color: [] }, { background: [] }],
    ],
  };

  const fileInputRef = useRef(null);
  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };
  return (
    <>
      <div>
        <form class="my-form bg_main">
          <div>
            <div>
              <div class="col-md-12 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title mb-4 pb-4">UPDATE EVENT</h4>
                    <div class="form-group">
                      <label>Title Of Event</label>
                      <input
                        type="text"
                        placeholder="Enter Title"
                        value={eventData.title || (eventData.title = title)}
                        name="title"
                        className="form-control"
                        onChange={handleFormData}
                      />
                    </div>
                    <div className="row">
                      <form
                        class="forms-sample col"
                        enctype="multipart/form-data"
                      >
                        {/* <div className="form-group">
                          <label>Upload Image</label>
                          <input
                            type="file"
                            name="event_pic"
                            className="form-control"
                            onChange={handleImageData}
                          />
                          {error && !image && reqMsg}
                        </div> */}
                        <div class="form-group">
                          <label>Event Image</label>
                          <input
                            type="file"
                            name="event_pic"
                            class="file-upload-default"
                            ref={fileInputRef}
                            onChange={handleImageData}
                          />
                          <div class="input-group col-xs-12">
                            <input
                              type="text"
                              class="form-control file-upload-info"
                              readOnly
                              onClick={handleUploadButtonClick}
                              placeholder="Upload Image"
                            />
                            <span class="input-group-append">
                              <button
                                class="file-upload-browse btn appButton"
                                type="button"
                                onClick={handleUploadButtonClick}
                              >
                                Upload
                              </button>
                            </span>
                          </div>
                        </div>
                      </form>
                      <div class="form-group col">
                        <label>Writer's Name</label>
                        <input
                          type="text"
                          placeholder="Enter Writer's Name"
                          value={
                            eventData.writer_name ||
                            (eventData.writer_name = writer_name)
                          }
                          name="writer_name"
                          className="form-control"
                          onChange={handleFormData}
                        />
                        {error && !eventData.writer_name && reqMsg}
                      </div>
                    </div>
                    <form class="forms-sample">
                      <div class="form-group">
                        <label>Event Description</label>

                        <ReactQuill
                          placeholder="Enter The Event Description"
                          theme="snow"
                          modules={modules}
                          // name="description"
                          value={
                            eventData.description ||
                            (eventData.description = description)
                          }
                          onChange={handleEditorChange}
                        />
                        {error && !eventData.description && reqMsg}
                      </div>
                      {/* <div class="form-group">
                        <label>Description</label>

                        <textarea
                          rows="10"
                          placeholder="Enter The Event Description"
                          value={
                            eventData.description ||
                            (eventData.description = description)
                          }
                          name="description"
                          className="form-control"
                          onChange={handleFormData}
                        />
                        {error && !eventData.description && reqMsg}
                      </div> */}
                      <div className="row">
                        <div className="form-group col">
                          <label>Date</label>
                          <input
                            type="date"
                            value={
                              eventData.current_date ||
                              (eventData.current_date = current_date)
                            }
                            className="date form-control"
                            name="current_date"
                            placeholder="Date"
                            onChange={handleFormData}
                          />
                          {error && !eventData.current_date && reqMsg}
                        </div>

                        <div className="form-group col">
                          <label>Time</label>
                          <input
                            type="time"
                            value={eventData.time || (eventData.time = time)}
                            className="form-control"
                            name="time"
                            placeholder="Time"
                            onChange={handleFormData}
                          />
                          {error && !eventData.time && reqMsg}
                        </div>

                        <div className="form-group col">
                          <label>Location</label>
                          <input
                            type="text"
                            value={
                              eventData.location ||
                              (eventData.location = location)
                            }
                            className="form-control"
                            name="location"
                            placeholder="Enter Event Location"
                            onChange={handleFormData}
                          />
                          {error && !eventData.location && reqMsg}
                        </div>
                      </div>
                      <div className="form-group">
                        <button
                          onClick={updateEvent}
                          className="btn appButton"
                          type="button"
                        >
                          Update Event
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default UpdateEvent;
