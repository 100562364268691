import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ParticleBackground from "react-particle-backgrounds";

const ForgotPassword = () => {
  //   const [image, setImage] = useState("");
  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
  });

  let name, value;
  const handleFormData = (e) => {
    // console.log(e);
    name = e.target.name;
    value = e.target.value;

    setUser({ ...user, [name]: value });
  };

  //   const handleImageData = (e) => {
  //     // console.log(e);
  //     setImage(e.target.files[0]);
  //     console.log(e.target.files[0]);
  //   };
  const navigate = useNavigate();
  useEffect(() => {
    const auth = localStorage.getItem("admin");
    if (auth) {
      navigate("/profile");
    }
  }, []);

  const collectData = async () => {
    const { name, email, password } = user;

    const formData = new FormData();
    // formData.append("pic", image);
    formData.append("name", name);
    formData.append("email", email);
    formData.append("password", password);
    console.log(formData);
    const res = await fetch("http://localhost:3100/register", {
      method: "POST",
      body: formData,
    });
    const data = await res.json();
    if (data.status === 422 || !data) {
      window.alert("Invalid registration");
    } else if (res.ok) {
      window.alert("Registration Successful");
      // localStorage.setItem("admin", JSON.stringify(data.admin))
      // localStorage.setItem("token", JSON.stringify(data.auth))
    }
    // navigate('/')
  };

  const settings = {
    canvas: {
      canvasFillSpace: true,
      width: 200,
      height: 200,
      useBouncyWalls: false,
    },
    particle: {
      particleCount: 60,
      color: "#98D2EB",
      minSize: 2,
      maxSize: 5,
    },
    velocity: {
      directionAngle: 0,
      directionAngleVariance: 360,
      minSpeed: 1,
      maxSpeed: 3,
    },
    opacity: {
      minOpacity: 0,
      maxOpacity: 0.5,
      opacityTransitionTime: 3000,
    },
  };
  return (
    <div class="align-items-center auth ">
      <ParticleBackground
        style={{
          position: "absolute",
          top: 0,
          overflow: "hidden",
          bottom: 0,
          left: 0,
          right: 0,
        }}
        className="particleBackground"
        settings={settings}
      />
      <div class="card col-md-5 mx-auto">
        <div class="card-body px-5 py-5">
          <h3 class="card-title text-center mb-3">FORGOT PASSWORD</h3>
          <form>
            {/* <div class="form-group">
              <label>Upload Image</label>
              <form method="POST" encType="multipart/form-data">
                <input
                  className="form-control"
                  type="file"
                  name="pic"
                  onChange={handleImageData}
                />
              </form>
            </div> */}
            <div class="form-group">
              <label>Name</label>
              <input
                className="form-control"
                type="text"
                placeholder="Enter Name"
                value={user.name}
                name="name"
                onChange={handleFormData}
              />
            </div>
            <div class="form-group">
              <label>Email</label>
              <input
                className="form-control"
                type="text"
                placeholder="Enter Email"
                value={user.email}
                name="email"
                onChange={handleFormData}
              />
            </div>
            {/* <div class="form-group">
              <label>Password</label>
              <input
                className="form-control"
                type="password"
                placeholder="Enter password"
                value={user.password}
                name="password"
                onChange={handleFormData}
              />
            </div> */}

            <div class="text-center">
              <button
                // onClick={collectData}
                onClick={() => navigate("/resetpass")}
                className="btn appButton"
                type="button"
              >
                Forgot Password
              </button>
            </div>

            <p class="sign-up text-center">
              Back to login <Link to="/admin-login">Log In</Link>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};
export default ForgotPassword;
