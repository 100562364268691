import React, { useEffect, useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import queryString from "query-string";
const ResetPassUser = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const [errMsg, setErrMsg] = useState("");

  const [email, setEmail] = useState("");
  const [new_pass, setNew_pass] = useState("");
  const [confirm_pass, setConfirm_pass] = useState("");
  const [error, setError] = useState(false);
  const [invalidToken, setInvalidToken] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saved, setSaved] = useState("");
  const [reqMsg, setReqMsg] = useState(
    <span style={{ color: "red", fontSize: "0.775rem" }}>Field Required *</span>
  );
  // const verifyRstToken = async () => {
  //   const { rstToken, email } = queryString.parse(location.search);
  //   if (!rstToken || !email) {
  //     setError(true);
  //     return false;
  //   }

  //   const res = await fetch(
  //     SERVER_URL + `/verifyRstToken?rstToken=${rstToken}&email=${email}`
  //   );
  //   const data = await res.json();

  //   if (data.status === 422 || !data) {
  //     setErrMsg(<span style={{ color: "red" }}>{data.error}</span>);
  //     setTimeout(() => {
  //       setErrMsg("");
  //       // navigate("/resetpassword");
  //     }, 3000);
  //   }
  // };

  // useEffect(() => {
  //   verifyRstToken();
  // }, []);
  const collectData = async () => {
    const { rstToken } = queryString.parse(location.search);

    if (!new_pass || !confirm_pass) {
      setError(true);
      return false;
    }
    const res = await fetch(
      SERVER_URL + `/resetPassword?rstToken=${rstToken}`,
      {
        method: "post",
        body: JSON.stringify({ new_pass }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await res.json();
    if (data.status === 422 || !data) {
      // window.alert("Invalid registration");
      document.getElementById("reset").reset();
      // setUser({ name: "", email: "", password: "" });
      setErrMsg(<span style={{ color: "red" }}>{data.error}</span>);
      setTimeout(() => {
        setErrMsg("");
        // navigate("/resetpassword");
      }, 3000);
    } else if (res.ok) {
      setSaved(
        <span style={{ color: "green", fontSize: "0.875rem" }}>
          Password changed successfully
        </span>
      );
      document.getElementById("reset").reset();
      setEmail("");
      localStorage.removeItem("verifyUserEmail");

      setTimeout(() => {
        setSaved("");
        navigate("/login");
      }, 3000);
    }
  };
  const [storeMatch, setStoreMatch] = useState("");

  function match() {
    if (!new_pass || !confirm_pass) {
      setStoreMatch(<span> </span>);
    } else if (new_pass === confirm_pass) {
      setStoreMatch(
        <span style={{ color: "green", fontSize: "0.875rem" }}>
          New And Confirm Password Matched
        </span>
      );
    } else {
      setStoreMatch(
        <span style={{ color: "red", fontSize: "0.875rem" }}>
          New And Confirm Password Not Matched
        </span>
      );
      // postData();
    }
  }

  return (
    <>
      <form id="reset">
        {/* <h3 class="card-title text-center mb-3">LOGIN</h3> */}
        <div className="d-md-flex bgImg half">
          <div className="loginSide">
            <div className="loginSideImg"></div>
            {/* <div className="bg-text">
            <h3>Welcome To Brand Name</h3>
          </div> */}
          </div>
          <div className="content">
            <div className="container">
              <div className="row align-items-center justify-content-center">
                <div className="col-md-12">
                  <div class="auth">
                    <div className="log_card mx-auto">
                      <div className="text-center">{saved}</div>
                      <div className="text-center">{errMsg}</div>

                      <h3>Reset Password</h3>

                      <form>
                        <div className="form-group">
                          <label>New Password</label>

                          <input
                            type="password"
                            className="form-control"
                            name="new_pass"
                            placeholder="Enter New Password"
                            onKeyUp={match}
                            onChange={(e) => setNew_pass(e.target.value)}
                          />
                          {error && !new_pass && reqMsg}
                        </div>

                        <div className="form-group">
                          <label>Confirm Password</label>
                          <input
                            type="password"
                            className="form-control"
                            name="confirm_pass"
                            placeholder="Confirm Password"
                            onChange={(e) => setConfirm_pass(e.target.value)}
                            onKeyUp={match}
                          />{" "}
                          {error && !confirm_pass && reqMsg}
                        </div>
                        <div className="form-group" style={{ color: "green" }}>
                          {storeMatch}
                        </div>

                        <div class="text-center">
                          <button
                            onClick={collectData}
                            className="btn login_btn"
                            type="button"
                          >
                            Reset
                          </button>
                        </div>

                        <p class="log-in text-center mt-3">
                          Token expired ? or Email not exist!
                        </p>
                        <p class="log-in text-center mt-3">
                          Back to{" "}
                          <Link to="/forgotpassword" className="link">
                            Forgot Password
                          </Link>
                        </p>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default ResetPassUser;
