import React, { useState, useEffect } from "react";

const UserProfile = () => {
  const loggedIn = localStorage.getItem("loggedIn");
  const [errMsg, setErrMsg] = useState("");
  const [userData, setUserData] = useState([]);

  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  useEffect(() => {
    const getData = async () => {
      const user = localStorage.getItem("userEmail");
      const email = user;

      const res = await fetch(SERVER_URL + "/get_user_data", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
        }),
      });

      const data = await res.json();
      setUserData(data);

      // console.log(data._id);
    };
    getData();
  }, []);
  if (loggedIn === "true") {
    return (
      <div class="container emp-profile">
        <form method="post">
          <div class="row">
            <div class="col-md-4">
              <div class="profile-img">
                {/* <img
                  src={SERVER_URL + "/user_img/" + userData.user_pic}
                  alt=""
                /> */}
                {/* <div class="file btn btn-lg btn-primary">
                Change Photo
                <input type="file" name="file" />
              </div> */}
              </div>
            </div>
            <div class="col-md-6">
              <div class="profile-head">
                <h5>Welcome {userData.userName}</h5>

                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      id="home-tab"
                      data-toggle="tab"
                      href="#home"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                    >
                      About
                    </a>
                  </li>
                  {/* <li class="nav-item">
                  <a
                    class="nav-link"
                    id="profile-tab"
                    data-toggle="tab"
                    href="#profile"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                  >
                    Timeline
                  </a>
                </li> */}
                </ul>
              </div>
            </div>
            {/* <div class="col-md-2">
            <input
              type="submit"
              class="profile-edit-btn"
              name="btnAddMore"
              value="Edit Profile"
            />
          </div> */}
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="profile-work">
                <p>Website Links</p>
                <a href="#">Website Link</a>
                <br />
                <a href="#">Website Link</a>
                <br />
                <a href="#">Website Link</a>

                <br />
              </div>
            </div>
            <div class="col-md-8">
              <div class="tab-content profile-tab" id="myTabContent">
                <div
                  class="tab-pane fade show active"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  {/* <div class="row">
                    <div class="col-md-6">
                      <label>User Id :</label>
                    </div>
                    <div class="col-md-6">
                      <p>{userData._id}</p>
                    </div>
                  </div> */}
                  <div class="row">
                    <div class="col-md-6">
                      <label>Name :</label>
                    </div>
                    <div class="col-md-6">
                      <p>{userData.userName}</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <label>Email :</label>
                    </div>
                    <div class="col-md-6">
                      <p>{userData.email}</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <label>Phone :</label>
                    </div>
                    <div class="col-md-6">
                      <p>123 456 7890</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <label>Profession :</label>
                    </div>
                    <div class="col-md-6">
                      <p>Web Developer and Designer</p>
                    </div>
                  </div>
                </div>
                {/* <div
                class="tab-pane fade"
                id="profile"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                Tab For Timeline
              </div> */}
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
};

export default UserProfile;
