import React, { useEffect, useState } from "react";
import { NavLink, Link, useLocation } from "react-router-dom";
import $ from "jquery";
// import "jquery-ui-dist/jquery-ui";
const Dashboard = () => {
  const auth = localStorage.getItem("admin");

  const [currentVal, setCurrentVal] = useState("");
  const location = useLocation();
  // useEffect(() => {
  //   var winLoc = window.location.pathname
  //     .split("/")
  //     .slice(-1)[0]
  //     .replace(/^\/|\/$/g, "");
  //   var sidebar = $(".sidebar");
  //   function addActiveClass(element) {
  //     if (current === "") {
  //       //for root url
  //       if (element.attr("href").indexOf("index.html") !== -1) {
  //         element.parents(".nav-item").last().addClass("active");
  //         if (element.parents(".sub-menu").length) {
  //           element.closest(".collapse").addClass("show");
  //           element.addClass("active");
  //         }
  //       }
  //       // } else if (current !== winLoc) {
  //       //   if (element.attr("href").indexOf(current) !== -1) {
  //       //     element.parents(".nav-item").last().addClass("");
  //       //     if (element.parents(".sub-menu").length) {
  //       //       element.closest(".collapse").addClass("");
  //       //       element.addClass("");
  //       //     }
  //       //     if (element.parents(".submenu-item").length) {
  //       //       element.addClass("");
  //       //     }
  //       //   }
  //     } else {
  //       if (element.attr("href").indexOf(current) !== -1) {
  //         element.parents(".nav-item").last().addClass("active");
  //         if (element.parents(".sub-menu").length) {
  //           element.closest(".collapse").addClass("show");
  //           element.addClass("active");
  //         }
  //         if (element.parents(".submenu-item").length) {
  //           element.addClass("active");
  //         }
  //       }
  //     }
  //   }
  //   var current = location.pathname
  //     .split("/")
  //     .slice(-1)[0]
  //     .replace(/^\/|\/$/g, "");
  //   $(".nav li a", sidebar).each(function () {
  //     var $this = $(this);
  //     addActiveClass($this);
  //     // console.log($this);
  //   });

  //   $(".horizontal-menu .nav li a").each(function () {
  //     var $this = $(this);
  //     addActiveClass($this);
  //   });
  //   console.log("This is Current " + current);
  //   console.log("This is OnClick Val " + currentVal);
  //   console.log("This is Window Loc " + winLoc);

  //   setCurrentVal(current);
  //   console.log(current === currentVal);
  //   sidebar.on("show.bs.collapse", ".collapse", function () {
  //     sidebar.find(".collapse.show").collapse("hide");
  //   });
  // }, [currentVal]);
  if (auth) {
    return (
      <>
        {/* <h1>Dashboard</h1> */}
        {/* <button
          className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
          type="button"
          data-toggle="offcanvas"
        >
          <span className="mdi mdi-format-line-spacing"></span>
        </button> */}
        <nav className="sidebar sidebar-offcanvas" id="sidebar">
          <div class="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top">
            <a
              class="sidebar-brand brand-logo"
              href="/profile"
              style={{ color: "#fff", textDecoration: "none" }}
            >
              {/* <img src="assets/images/logo.svg" alt="logo" /> */}
              <h1>LOGO</h1>
            </a>
            <a
              class="sidebar-brand brand-logo-mini"
              href="/profile"
              style={{ color: "#fff", textDecoration: "none" }}
            >
              {/* <img src="assets/images/logo-mini.svg" alt="logo" /> */}
              <h5>LOGO</h5>
            </a>
          </div>
          <ul className="nav">
            {/* <li className="nav-item profile">
              <div className="profile-desc">
                <div className="profile-pic">
                  <div className="count-indicator">
                    <img
                      className="img-xs rounded-circle "
                      src="assets/images/faces/face15.jpg"
                      alt=""
                    />
                    <span className="count bg-success"></span>
                  </div>
                  <div className="profile-name">
                    <h2>
                      <Link to="/profile" className="btn btn-info">
                        {" "}
                        Profile{" "}
                      </Link>
                    </h2>
                    <h5 className="mb-0 font-weight-normal">Henry Klein</h5>
                  </div>
                </div>
                <a href="#" id="profile-dropdown" data-toggle="dropdown">
                  <i className="mdi mdi-dots-vertical"></i>
                </a>
                <div
                  className="dropdown-menu dropdown-menu-right sidebar-dropdown preview-list"
                  aria-labelledby="profile-dropdown"
                >
                  <a href="#" className="dropdown-item preview-item">
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-dark rounded-circle">
                        <i className="mdi mdi-settings text-primary"></i>
                      </div>
                    </div>
                    <div className="preview-item-content">
                      <p className="preview-subject ellipsis mb-1 text-small">
                        Account settings
                      </p>
                    </div>
                  </a>
                  <div className="dropdown-divider"></div>
                  <a href="#" className="dropdown-item preview-item">
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-dark rounded-circle">
                        <i className="mdi mdi-onepassword  text-info"></i>
                      </div>
                    </div>
                    <div className="preview-item-content">
                      <p className="preview-subject ellipsis mb-1 text-small">
                        Change Password
                      </p>
                    </div>
                  </a>
                  <div className="dropdown-divider"></div>
                  <a href="#" className="dropdown-item preview-item">
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-dark rounded-circle">
                        <i className="mdi mdi-calendar-today text-success"></i>
                      </div>
                    </div>
                    <div className="preview-item-content">
                      <p className="preview-subject ellipsis mb-1 text-small">
                        To-do list
                      </p>
                    </div>
                  </a>
                </div>
              </div>
            </li> */}
            {/* <li className="nav-item nav-category">
            <span className="nav-link">Navigation</span>
          </li> */}
            {/* <li className="nav-item menu-items">
            <a className="nav-link" href="#">
              <span className="menu-icon">
                <i className="mdi mdi-speedometer"></i>
              </span>
              <span className="menu-title">Dashboard</span>
            </a>
          </li> */}

            {/* Category */}

            <li
              className={`nav-item menu-items ${
                location.pathname === "/addcategory" ||
                location.pathname === "/rmcategory"
                  ? "active"
                  : ""
              }`}
            >
              {/* <NavLink
              className="nav-link"
              // onClick={(e) => console.log(e)}
              data-toggle="collapse"
              to="#category"
              aria-expanded="false"
              aria-controls="category"
            > */}
              <a
                className="nav-link"
                // onClick={(e) => console.log(e)}
                data-toggle="collapse"
                href="#category"
                aria-expanded="false"
                aria-controls="category"
              >
                <span className="menu-icon">
                  <i className="mdi mdi-view-list"></i>
                </span>
                <span className="menu-title">Category</span>
                <i className="menu-arrow"></i>
              </a>
              {/* </NavLink> */}
              <div className="collapse" id="category">
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    {/* <a href="#" className="nav-link"> */}
                    <NavLink to="/addcategory" className="nav-link">
                      Add Category{" "}
                    </NavLink>
                    {/* </a> */}
                  </li>
                  <li className="nav-item">
                    {" "}
                    <NavLink to="/rmcategory" className="nav-link">
                      Remove Category{" "}
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>

            {/* Blogs */}
            {/* <NavLink
              to="#blogs"
              className="nav-link"
              data-toggle="collapse"
              aria-expanded="false"
              aria-controls="blogs"
            > */}
            <li
              className={`nav-item menu-items ${
                location.pathname === "/addblog" ||
                location.pathname === "/manageblog"
                  ? "active"
                  : ""
              }`}
            >
              <a
                className="nav-link"
                data-toggle="collapse"
                href="#blogs"
                aria-expanded="false"
                aria-controls="blogs"
              >
                <span className="menu-icon">
                  <i className="mdi mdi-note-text"></i>
                </span>
                <span className="menu-title">Blogs</span>
                <i className="menu-arrow"></i>
              </a>
              <div className="collapse" id="blogs">
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <NavLink
                      to="/addblog"
                      className="nav-link"
                      onClick={() => setCurrentVal("addblog")}
                    >
                      Add Blog{" "}
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <NavLink
                      to="/manageblog"
                      className="nav-link"
                      onClick={() => setCurrentVal("manageblog")}
                    >
                      Manage Blogs
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>
            {/* </NavLink> */}

            {/* News */}

            <li
              className={`nav-item menu-items ${
                location.pathname === "/addnews" ||
                location.pathname === "/managenews"
                  ? "active"
                  : ""
              }`}
            >
              <a
                className="nav-link"
                data-toggle="collapse"
                href="#news"
                aria-expanded="false"
                aria-controls="news"
              >
                <span className="menu-icon">
                  <i className="mdi mdi-newspaper"></i>
                </span>
                <span className="menu-title">News</span>
                <i className="menu-arrow"></i>
              </a>
              <div className="collapse" id="news">
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <NavLink
                      to="/addnews"
                      className="nav-link"
                      onClick={() => setCurrentVal("addnews")}
                    >
                      Add News{" "}
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <NavLink
                      to="/managenews"
                      className="nav-link"
                      onClick={() => setCurrentVal("managenews")}
                    >
                      Manage News
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>

            {/* Events */}

            <li
              className={`nav-item menu-items ${
                location.pathname === "/addevent" ||
                location.pathname === "/manageevent"
                  ? "active"
                  : ""
              }`}
            >
              <a
                className="nav-link"
                data-toggle="collapse"
                href="#events"
                aria-expanded="false"
                aria-controls="events"
              >
                <span className="menu-icon">
                  <i className="mdi mdi-calendar-clock"></i>
                </span>
                <span className="menu-title">Events</span>
                <i className="menu-arrow"></i>
              </a>
              <div className="collapse" id="events">
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <NavLink to="/addevent" className="nav-link">
                      Add Events{" "}
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <NavLink to="/manageevent" className="nav-link">
                      Manage Events
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>

            {/* Visibility */}

            <li
              className={`nav-item menu-items ${
                location.pathname === "/managevisibility" ? "active" : ""
              }`}
            >
              <NavLink
                to="/managevisibility"
                className="nav-link"
                data-toggle="collapse"
                aria-expanded="false"
              >
                <span className="menu-icon">
                  <i className="mdi mdi-eye"></i>
                </span>
                <span className="menu-title">Manage Visibility</span>
              </NavLink>
            </li>

            {/* <li className="nav-item menu-items">
              <a className="nav-link" href="pages/forms/basic_elements.html">
                <span className="menu-icon">
                  <i className="mdi mdi-playlist-play"></i>
                </span>
                <span className="menu-title">Form Elements</span>
              </a>
            </li>
            <li className="nav-item menu-items">
              <a className="nav-link" href="pages/tables/basic-table.html">
                <span className="menu-icon">
                  <i className="mdi mdi-table-large"></i>
                </span>
                <span className="menu-title">Tables</span>
              </a>
            </li>
            <li className="nav-item menu-items">
              <a className="nav-link" href="pages/charts/chartjs.html">
                <span className="menu-icon">
                  <i className="mdi mdi-chart-bar"></i>
                </span>
                <span className="menu-title">Charts</span>
              </a>
            </li>
            <li className="nav-item menu-items">
              <a className="nav-link" href="pages/icons/mdi.html">
                <span className="menu-icon">
                  <i className="mdi mdi-contacts"></i>
                </span>
                <span className="menu-title">Icons</span>
              </a>
            </li> */}
          </ul>
        </nav>

        {/* NavBar */}
      </>
    );
  }
};
export default Dashboard;
