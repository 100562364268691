import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";

const Verfication = () => {
  const navigate = useNavigate();

  const [errMsg, setErrMsg] = useState("");
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [error, setError] = useState(false);
  const [saved, setSaved] = useState("");
  const [reqMsg, setReqMsg] = useState(
    <span style={{ color: "red", fontSize: "0.775rem" }}>Field Required *</span>
  );
  const [verifyError, setVerifyError] = useState(false);

  const collectData = async () => {
    const user = localStorage.getItem("verifyUserEmail")?.replace(/"/g, "");
    const email = user;
    // console.log(email);
    setEmail(email);
    if (!otp) {
      setError(true);

      return false;
    }
    const res = await fetch(SERVER_URL + "/verifyEmail", {
      method: "post",
      body: JSON.stringify({ email, otp }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await res.json();
    if (data.status === 402) {
      // setVerifyError(true);
      setErrMsg(<span style={{ color: "red" }}>{data.error}</span>);
      setTimeout(() => {
        setErrMsg("");
      }, 2000);
      return false;
    }
    if (data.status === 422 || !data) {
      // window.alert("Invalid registration");
      document.getElementById("reset").reset();
      // setUser({ name: "", email: "", password: "" });
      setErrMsg(<span style={{ color: "red" }}>{data.error}</span>);
      setTimeout(() => {
        setErrMsg("");
        navigate("/verifyemail");
      }, 3000);
    } else if (res.ok) {
      // window.alert("Registration Successful");
      setSaved(
        <span style={{ color: "green" }}>Email verification successful</span>
      );
      localStorage.removeItem("verifyUserEmail");
      setTimeout(() => {
        setSaved("");
        navigate("/login");
      }, 3000);
    }
  };

  // ============================================================================================================
  const resendData = async (e) => {
    e.preventDefault();
    const user = localStorage.getItem("verifyUserEmail")?.replace(/"/g, "");
    const email = user;
    if (!email) {
      setError(true);
      return false;
    }
    const res = await fetch(SERVER_URL + "/newVerifyEmail", {
      method: "post",
      body: JSON.stringify({ email }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await res.json();
    if (data.status === 422 || !data) {
      document.getElementById("reset").reset();
      setErrMsg(<span style={{ color: "red" }}>{data.error}</span>);
      setTimeout(() => {
        setErrMsg("");
        // navigate("/verifyemail");
      }, 3000);
    } else if (res.ok) {
      setSaved(
        <span style={{ color: "green", fontSize: "0.875rem" }}>
          Email sent successfully
        </span>
      );

      localStorage.setItem("verifyUserEmail", JSON.stringify(data.email));

      setTimeout(() => {
        setSaved("");
        // navigate("/verifyemail");
      }, 3000);
    }
  };
  return (
    <>
      <form id="reset">
        {/* <h3 class="card-title text-center mb-3">LOGIN</h3> */}
        <div className="d-md-flex bgImg half">
          <div className="loginSide">
            <div className="loginSideImg"></div>
            {/* <div className="bg-text">
            <h3>Welcome To Brand Name</h3>
          </div> */}
          </div>
          <div className="content">
            <div className="container">
              <div className="row align-items-center justify-content-center">
                <div className="col-md-12">
                  <div class="auth">
                    <div className="log_card mx-auto">
                      <div className="text-center">{saved}</div>
                      <div className="text-center">{errMsg}</div>

                      <h3>Verify OTP</h3>

                      <form>
                        <div class="form-group">
                          {/* <label>Email *</label> */}
                          <input
                            hidden
                            type="text"
                            className="form-control"
                            placeholder="Enter Email"
                            // onChange={(e) => setEmail(e.target.value)}
                            value={email}
                          />
                          {/* {error && !email && reqMsg} */}
                        </div>
                        <div class="form-group">
                          <label>OTP *</label>
                          <input
                            type="password"
                            className="form-control"
                            placeholder="Enter Password"
                            onChange={(e) => setOtp(e.target.value)}
                            value={otp}
                          />
                          {error && !otp && reqMsg}
                        </div>
                        <div class="text-center">
                          <button
                            onClick={collectData}
                            className="btn login_btn"
                            type="button"
                          >
                            Confirm
                          </button>
                        </div>
                        <p class="log-in text-center mt-3">
                          Please do not reload or close this page!
                        </p>
                        {/* {verifyError && ( */}
                        <p class="log-in text-center mt-3">
                          <button class="btn verifyBtn" onClick={resendData}>
                            Resend OTP
                          </button>
                        </p>
                        {/* )} */}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default Verfication;
