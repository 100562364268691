import React, { useState, useEffect } from "react";
import {
  useParams,
  Navigate,
  Outlet,
  Link,
  useNavigate,
} from "react-router-dom";
import _ from "lodash";
const Visibility = () => {
  // const auth = localStorage.getItem("token");
  const params = useParams();
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [storeId, setStoreId] = useState("");
  console.log(storeId);
  const [saved, setSaved] = useState("");
  const [visibilityData, setVisibilityData] = useState({
    page: "",
    page_visibility: "",
  });

  useEffect(() => {
    getvisibility();
  }, []);

  const getvisibility = async () => {
    let result = await fetch(SERVER_URL + "/visibility", {
      headers: {
        authorization: JSON.parse(localStorage.getItem("token")),
      },
    });
    result = await result.json();
    setVisibilityData(result);
  };

  const [page, setPage] = useState("");
  const [reqMsg, setReqMsg] = useState(
    <span style={{ color: "red", fontSize: "0.875rem" }}>
      Select visible or not visible *
    </span>
  );
  const [UpdatevisibilityData, setUpdateVisibilityData] = useState({
    page: "",
    page_visibility: "",
  });
  let name, value;
  const handleFormData = (e) => {
    // console.log(e);
    name = e.target.name;
    value = e.target.value;
    setUpdateVisibilityData({ ...UpdatevisibilityData, [name]: value });
    console.log({ ...UpdatevisibilityData, [name]: value });
  };
  const updateVisibility = async () => {
    if (!UpdatevisibilityData.page_visibility) {
      setError(true);
      return false;
    }
    const { page_visibility } = UpdatevisibilityData;
    console.log(page_visibility);

    const formData = new FormData();
    // formData.append("page", page);
    formData.append("page_visibility", page_visibility);
    formData.append("storeId", storeId);
    console.log(formData);
    let result = await fetch(`${SERVER_URL}/visibility`, {
      method: "PUT",
      body: formData,
    });
    const data = await result.json();
    if (result.ok) {
      setSaved(<span style={{ color: "green" }}>Visibility Saved</span>);
      getvisibility();
      navigate("/managevisibility");
    }
  };

  return (
    // auth ? <Outlet /> : <Navigate to="/" />
    <>
      <div className="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4 pb-4">MANAGE VISIBILITY</h4>
            <div className="table-responsive">
              <table className="table table-dark table-bordered">
                <thead>
                  <th>S.No</th>
                  <th>Pages</th>
                  <th>Visibility</th>
                  <th>Action</th>
                </thead>
                <tbody>
                  {visibilityData.length > 0 ? (
                    visibilityData.map((items, index) => (
                      <tr key={items._id}>
                        <td>{index + 1}</td>
                        <td>{_.startCase(items.page)}</td>
                        <td>{_.startCase(items.page_visibility)}</td>
                        <td>
                          <button
                            className="btn btn-primary btnUpdate"
                            // onClick={() => {
                            //   navigate("/updatevisibility/" + items._id);
                            // }}
                            onClick={() => {
                              setStoreId(items._id);
                            }}
                            data-toggle="modal"
                            data-target="#exampleModalCenter"
                          >
                            Update
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <h1>No Result Found</h1>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {/* <!-- Modal --> */}
            <div
              class="modal fade"
              id="exampleModalCenter"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalCenterTitle"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLongTitle">
                      Select Visibility
                    </h5>
                    <button
                      type="button"
                      class="close btnClose"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <div className="form-group pt-3">
                      {/* <label>Select Visibility</label> */}

                      <select
                        name="page_visibility"
                        className="dropdown form-control "
                        onChange={handleFormData}
                      >
                        <option value="visible">Visible</option>
                        <option value="not visible">Not Visible</option>
                      </select>

                      {/* <div className="form-check">
                        <label className="form-check-label">
                          0
                          <input
                            type="radio"
                            name="page_visibility"
                            className="form-check-input"
                            value="0"
                            onChange={handleFormData}
                          />
                          <i className="input-helper"></i>
                        </label>
                      </div>
                      <div className="form-check">
                        <label className="form-check-label">
                          1
                          <input
                            type="radio"
                            name="page_visibility"
                            className="form-check-input"
                            value="1"
                            onChange={handleFormData}
                          />
                          <i className="input-helper"></i>
                        </label>
                      </div> */}
                      {error && !UpdatevisibilityData.page_visibility && reqMsg}
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      data-dismiss="modal"
                      class="btn btn-dark appButton"
                      onClick={updateVisibility}
                    >
                      Save changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Visibility;
