import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
// import "bootstrap/dist/css/bootstrap.min.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const UpdateBlog = () => {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const params = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [reqMsg, setReqMsg] = useState(
    <span style={{ color: "red", fontSize: "0.875rem" }}>Field Required *</span>
  );
  const [saved, setSaved] = useState("");
  const [image, setImage] = useState("");
  const [category, setCategory] = useState([]);
  const [title, setTitle] = useState("");
  const [REScategory, setRESCategory] = useState("");
  const [top_type, setTop_type] = useState("");
  const [blog_type, setblog_type] = useState("");
  const [RESImage, setRESImage] = useState("");
  const [writer_name, setWriter_name] = useState("");
  const [description, setDescription] = useState("");
  const [current_date, setCurrent_date] = useState("");
  const [blogData, setBlogData] = useState({
    category: "",
    blog_type: "",
    top_type: "",
    title: "",
    writer_name: "",
    description: "",
    current_date: "",
  });

  let name, value;
  const handleFormData = (e) => {
    // console.log(e);
    name = e.target.name;
    value = e.target.value;

    setBlogData({ ...blogData, [name]: value });
    console.log({ ...blogData, [name]: value });
  };

  const handleImageData = (e) => {
    // console.log(e);
    setImage(e.target.files[0]);
    // console.log(e.target.files[0]);
  };
  const handleEditorChange = (newContentState) => {
    console.log(newContentState);
    setBlogData((prevData) => ({
      ...prevData,
      // description: JSON.stringify(newContentState),
      description: newContentState,
    }));
  };
  const getBlogData = async () => {
    // console.warn(params);
    if (params.id.length !== 24) {
      navigate("/manageBlog");
      return false;
    }
    let result = await fetch(`${SERVER_URL}/blogData/${params.id}`);
    result = await result.json();
    // setBlogData(result);
    // console.log(typeof result);
    setTitle(result.title);
    setWriter_name(result.writer_name);
    setCurrent_date(result.current_date);
    setDescription(result.description);
    setRESCategory(result.category);
    setblog_type(result.blog_type);
    setTop_type(result.top_type);
    setRESImage(result.blog_pic);
  };

  useEffect(() => {
    getBlogData();
  }, []);

  const updateBlog = async () => {
    // console.warn(product_name, price, category, company)
    const adminId = JSON.parse(localStorage.getItem("admin"))._id;
    const date = new Date();
    const d = date.toDateString();

    if (
      !blogData.title ||
      !blogData.description ||
      // !blogData.current_date ||
      !blogData.category ||
      !blogData.blog_type ||
      !blogData.top_type ||
      !blogData.writer_name
    ) {
      setError(true);
      return false;
    }
    const { title, description, category, blog_type, top_type, writer_name } =
      blogData;

    const formData = new FormData();
    formData.append("blog_pic", image || ("blog_pic", RESImage));

    formData.append("category", category);
    formData.append("blog_type", blog_type);
    formData.append("top_type", top_type);
    formData.append("title", title);
    formData.append("writer_name", writer_name);
    formData.append("description", description);
    formData.append("current_date", current_date);
    formData.append("adminId", adminId);
    console.log(formData);
    let result = await fetch(`${SERVER_URL}/blogData/${params.id}`, {
      method: "PUT",
      body: formData,
    });
    const data = await result.json();
    if (result.ok) {
      setSaved(<span style={{ color: "green" }}>Blog Saved</span>);

      navigate("/manageblog");
    } else if (data.error || data.status === 422) {
      window.alert(data.error + "Invalid");
    }
  };

  //Fetching Category
  useEffect(() => {
    const fetchInfo = async () => {
      const res = await fetch(SERVER_URL + "/get_category");
      const data = await res.json();
      // console.log(data);
      setCategory(data);
    };
    fetchInfo();
  }, []);
  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      // ["blockquote", "code-block"],
      [{ list: "ordered" }],
      [{ align: [] }],
      ["link", "image"],
    ],
  };
  const fileInputRef = useRef(null);
  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };
  return (
    <>
      <div>
        <form class="my-form bg_main">
          <div>
            <div>
              <div class="col-md-12 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title mb-4 pb-4">UPDATE BLOG</h4>
                    <form>
                      <div class="form-group">
                        <label>Title Of Blog</label>
                        <input
                          type="text"
                          placeholder="Enter Title"
                          value={blogData.title || (blogData.title = title)}
                          name="title"
                          className="form-control"
                          onChange={handleFormData}
                        />
                      </div>
                      <div className="row">
                        <div className="form-group col">
                          <label>Select Category</label>
                          <select
                            name="category"
                            className="dropdown form-control"
                            style={{
                              color: "white",
                            }}
                            value={
                              blogData.category ||
                              (blogData.category = REScategory)
                            }
                            onChange={handleFormData}
                          >
                            {/* <option value="">Select Category From Here</option> */}
                            {/* <option value={REScategory}>{REScategory}</option> */}
                            {category.map((get_cat) => (
                              <option value={get_cat.category_name}>
                                {get_cat.category_name.toUpperCase()}
                              </option>
                            ))}
                          </select>
                          {error && !blogData.category && reqMsg}
                        </div>
                        <div className="form-group col">
                          <label>Blog Type</label>

                          <select
                            name="blog_type"
                            className="dropdown form-control"
                            value={
                              blogData.blog_type ||
                              (blogData.blog_type = blog_type)
                            }
                            onChange={handleFormData}
                          >
                            <option value="free">FREE</option>
                            <option value="premium">PREMIUM</option>
                          </select>
                          {error && !blogData.blog_type && reqMsg}
                        </div>
                        <form
                          class="forms-sample col"
                          enctype="multipart/form-data"
                        >
                          <div class="form-group">
                            <label>Blog Image</label>
                            <input
                              type="file"
                              name="blog_pic"
                              class="file-upload-default"
                              ref={fileInputRef}
                              onChange={handleImageData}
                            />
                            <div class="input-group col-xs-12">
                              <input
                                type="text"
                                class="form-control file-upload-info"
                                readOnly
                                onClick={handleUploadButtonClick}
                                placeholder={
                                  image ? image.name : "Upload Image"
                                }
                              />
                              <span class="input-group-append">
                                <button
                                  class="file-upload-browse btn appButton"
                                  type="button"
                                  onClick={handleUploadButtonClick}
                                >
                                  Upload
                                </button>
                              </span>
                            </div>
                            {error && !image && reqMsg}
                          </div>
                          {/* <div className="form-group">
                            <label>Upload Image</label>
                            <input
                              type="file"
                              name="blog_pic"
                              className="form-control"
                              onChange={handleImageData}
                            />                            
                          </div> */}
                        </form>
                      </div>
                      <div className="row">
                        <div className="form-group col">
                          <label>Blog Top Type</label>

                          <select
                            onChange={handleFormData}
                            name="top_type"
                            className="form-control"
                            value={
                              blogData.top_type ||
                              (blogData.top_type = top_type)
                            }
                          >
                            <option value="normal">Normal</option>
                            <option value="top 1">Top 1</option>
                            <option value="top 2">Top 2</option>
                            <option value="top 3">Top 3</option>
                            <option value="top 4">Top 4</option>
                            <option value="top 5">Top 5</option>
                          </select>
                          {error && !blogData.top_type && reqMsg}
                        </div>
                        <div class="form-group col">
                          <label>Writer's Name</label>
                          <input
                            type="text"
                            placeholder="Enter Writer's Name"
                            value={
                              blogData.writer_name ||
                              (blogData.writer_name = writer_name)
                            }
                            name="writer_name"
                            className="form-control"
                            onChange={handleFormData}
                          />
                          {error && !blogData.writer_name && reqMsg}
                        </div>
                      </div>

                      <form class="forms-sample">
                        <div className="row">
                          {/* <div className="form-group col">
                          <label>Date</label>
                          <input
                            type="date"
                            value={
                              blogData.current_date ||
                              (blogData.current_date = current_date)
                            }
                            className="date form-control"
                            name="current_date"
                            placeholder="Date"
                            onChange={handleFormData}
                          />
                          {error && !blogData.current_date && reqMsg}
                        </div> */}
                        </div>
                        {/* <div class="form-group">
                          <label>Description</label>

                          <textarea
                            rows="10"
                            placeholder="Enter The Blog Description"
                            value={
                              blogData.description ||
                              (blogData.description = description)
                            }
                            name="description"
                            className="form-control"
                            onChange={handleFormData}
                          />
                          {error && !blogData.description && reqMsg}
                        </div> */}
                        <div class="form-group">
                          <label>Blog Description</label>
                          <ReactQuill
                            placeholder="Enter The Blog Description"
                            theme="snow"
                            modules={modules}
                            // name="description"
                            value={
                              blogData.description ||
                              (blogData.description = description)
                            }
                            onChange={handleEditorChange}
                          />
                          {error && !blogData.description && reqMsg}
                        </div>
                        <div className="form-group">
                          <button
                            onClick={updateBlog}
                            className="btn appButton"
                            type="button"
                          >
                            Update Blog
                          </button>{" "}
                          {saved}
                        </div>
                      </form>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default UpdateBlog;
