import React, { useEffect } from "react";

export default function Banner() {
  useEffect(() => {
    counter();
  }, []);

  function counter() {
    const counters = document.querySelectorAll(".counter");
    counters.forEach((counter) => {
      counter.innerText = "0";
      const updateCounter = () => {
        const target = +counter.getAttribute("data-target");
        const count = +counter.innerText;
        const increment = target / 200;
        if (count < target) {
          counter.innerText = `${Math.ceil(count + increment)}`;
          setTimeout(updateCounter, 1);
        } else counter.innerText = target + " +";
      };
      updateCounter();
    });
  }

  return (
    <>
      <div className="banner">
        <div className="container">
          <div className="row banner_row align-items-center">
            <div className="col-md-6 banner_box_1">
              <div className="banner_content_box">
                <h1 className="banner_title">
                  Know All About Cyber Attacks,
                  <br />
                  <span>Act Before They Hit</span>
                </h1>
                <div className="container px-0">
                  <div className="row">
                    <div className="col-md-12 px-0">
                      <div className="tabbable-panel">
                        <div className="tabbable-line">
                          <ul className="nav nav-tabs ">
                            <li className="nav-item">
                              <a
                                href="#tab_default_1"
                                data-bs-toggle="tab"
                                className="nav-link active"
                              >
                                Sigma Rules
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                href="#tab_default_2"
                                data-bs-toggle="tab"
                                className="nav-link"
                              >
                                MITRE ATT&CK
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                href="#tab_default_3"
                                data-bs-toggle="tab"
                                className="nav-link"
                              >
                                CVE
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                href="#tab_default_4"
                                data-bs-toggle="tab"
                                className="nav-link"
                              >
                                Ransomware
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                href="#tab_default_5"
                                data-bs-toggle="tab"
                                className="nav-link"
                              >
                                Log Sources
                              </a>
                            </li>
                          </ul>
                          <div className="tab-content">
                            <div className="tab-pane active" id="tab_default_1">
                              <form
                                action=""
                                method="GET"
                                className="search"
                                autoComplete="off"
                              >
                                <i className="fa fa-search"></i>
                                <input
                                  type="text"
                                  name="search"
                                  className="form-control"
                                  placeholder="try: Lateral Movement, cert.gov.ua, Florian Roth"
                                />
                                <button
                                  type="submit"
                                  className="btn btn-primary search_btn"
                                >
                                  Search
                                </button>
                              </form>
                            </div>
                            <div className="tab-pane" id="tab_default_2">
                              <form
                                action=""
                                method="GET"
                                className="search"
                                autoComplete="off"
                              >
                                <i className="fa fa-search"></i>
                                <input
                                  type="text"
                                  name="search"
                                  className="form-control"
                                  placeholder="try:  APT28, t1110.001, cobalt strike"
                                />
                                <button
                                  type="submit"
                                  className="btn btn-primary search_btn"
                                >
                                  Search
                                </button>
                              </form>
                            </div>
                            <div className="tab-pane" id="tab_default_3">
                              <form
                                action=""
                                method="GET"
                                className="search"
                                autoComplete="off"
                              >
                                <i className="fa fa-search"></i>
                                <input
                                  type="text"
                                  name="search"
                                  className="form-control"
                                  placeholder="try: CVE-2022-42889, text4shell, exploit"
                                />
                                <button
                                  type="submit"
                                  className="btn btn-primary search_btn"
                                >
                                  Search
                                </button>
                              </form>
                            </div>
                            <div className="tab-pane" id="tab_default_4">
                              <form
                                action=""
                                method="GET"
                                className="search"
                                autoComplete="off"
                              >
                                <i className="fa fa-search"></i>
                                <input
                                  type="text"
                                  name="search"
                                  className="form-control"
                                  placeholder="try: Lockbit 3.0, Black Basta"
                                />
                                <button
                                  type="submit"
                                  className="btn btn-primary search_btn"
                                >
                                  Search
                                </button>
                              </form>
                            </div>
                            <div className="tab-pane" id="tab_default_5">
                              <form
                                action=""
                                method="GET"
                                className="search"
                                autoComplete="off"
                              >
                                <i className="fa fa-search"></i>
                                <input
                                  type="text"
                                  name="search"
                                  className="form-control"
                                  placeholder="try: linux, aws, docker, azure, proxy, process_creation"
                                />
                                <button
                                  type="submit"
                                  className="btn btn-primary search_btn"
                                >
                                  Search
                                </button>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 banner_box_2">
              <div className="banner_box">
                <div className="proxz-nav">
                  <div className="proxz-nav__system">
                    <li className="proxz-nav__orbit">
                      <a className="proxz-nav__satellite" href="#">
                        <span className="proxz-nav__label">
                          Step 1
                          <span className="proxz-nav__description">
                            Step 1 Title
                          </span>
                        </span>
                      </a>
                    </li>
                    <li className="proxz-nav__orbit">
                      <a className="proxz-nav__satellite" href="#">
                        <span className="proxz-nav__label">
                          Step 2
                          <span className="proxz-nav__description">
                            Step 2 Title
                          </span>
                        </span>
                      </a>
                    </li>
                    <li className="proxz-nav__orbit">
                      <a className="proxz-nav__satellite" href="#">
                        <span className="proxz-nav__label">
                          Step 3
                          <span className="proxz-nav__description">
                            Step 3 Title
                          </span>
                        </span>
                      </a>
                    </li>
                    <li className="proxz-nav__orbit">
                      <a className="proxz-nav__satellite" href="#">
                        <span className="proxz-nav__label">
                          Step 4
                          <span className="proxz-nav__description">
                            Step 4 Title
                          </span>
                        </span>
                      </a>
                    </li>
                    <li className="proxz-nav__orbit">
                      <a className="proxz-nav__satellite" href="#">
                        <span className="proxz-nav__label">
                          Step 5
                          <span className="proxz-nav__description">
                            Step 5 Title
                          </span>
                        </span>
                      </a>
                    </li>
                    <li className="proxz-nav__orbit">
                      <a className="proxz-nav__satellite" href="#">
                        <span className="proxz-nav__label">
                          Step 6
                          <span className="proxz-nav__description">
                            Step 6 Title
                          </span>
                        </span>
                      </a>
                    </li>
                  </div>
                  <div className="hologram"></div>
                </div>
              </div>
            </div>
            <div className="col-md-12 counter_box">
              <div className="row">
                <div className="col-md-4">
                  <div className="inner_counter_box">
                    <div className="counter-container">
                      <i
                        className="fa fa-users fa-3x counter_icon"
                        aria-hidden="true"
                      ></i>
                      <div className="counter" data-target="7500"></div>
                      <span className="counter_title">Users</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="inner_counter_box">
                    <div className="counter-container">
                      <i
                        className="fa fa-line-chart fa-3x counter_icon"
                        aria-hidden="true"
                      ></i>

                      <div className="counter" data-target="5000"></div>
                      <span className="counter_title">Threat researchers</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="counter-container">
                    <i
                      className="fa fa-industry fa-3x counter_icon"
                      aria-hidden="true"
                    ></i>
                    <div className="counter" data-target="12000"></div>
                    <span className="counter_title">Organizations</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
