import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import _ from "lodash";

import DataTable from "react-data-table-component";
const ManageNews = () => {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const navigate = useNavigate();
  const [newsData, setNewsData] = useState([]);
  const [paginationData, setPaginationData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  function truncate(str, n) {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  }
  useEffect(() => {
    getNewsData();
  }, []);

  const getNewsData = async () => {
    let result = await fetch(SERVER_URL + "/newsData", {
      headers: {
        authorization: JSON.parse(localStorage.getItem("token")),
      },
    });
    result = await result.json();
    setNewsData(result);
    setPaginationData(_(result).slice(0).take(pageSize).value());
  };

  const deleteNews = async (id) => {
    console.log(id);
    const confirmation = window.confirm(
      "Are You Sure ? Want to Delete this News"
    );
    if (confirmation) {
      let result = await fetch(`${SERVER_URL}/newsData/${id}`, {
        method: "Delete",
      });
      result = await result.json();
      if (result) {
        getNewsData();
      }
    }
  };

  const pageSize = 10;
  const pageCount = newsData ? Math.ceil(newsData.length / pageSize) : 0;
  // console.log(pageCount);
  // if (pageCount === 0) {
  //   return null;
  // }
  const pages = _.range(1, pageCount + 1);
  function pagination(pageNo) {
    setCurrentPage(pageNo);
    const startIndex = (pageNo - 1) * pageSize;
    const paginationData = _(newsData).slice(startIndex).take(pageSize).value();
    setPaginationData(paginationData);
  }
  function dateChange(str) {
    let date = new Date(str);
    return date.toDateString();
  }
  return (
    <div className="col-md-12 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4 pb-4">MANAGE NEWS</h4>

          <div className="table-responsive">
            <table className="table table-dark table-bordered">
              <thead>
                <th>S.No</th>
                <th>Title</th>
                <th>Writer's Name</th>
                {/* <th>Description</th> */}
                <th>Date</th>
                <th>Time</th>
                <th>Image</th>
                <th>Action</th>
              </thead>
              <tbody>
                {paginationData.length > 0 ? (
                  paginationData.map((items, index) => (
                    <tr key={items._id}>
                      <td>{index + 1}</td>
                      <td>{truncate(items.title, 30)}</td>
                      <td>{items.writer_name}</td>
                      {/* <td>{truncate(items.description, 50)}</td> */}
                      <td>{dateChange(items.current_date)}</td>
                      <td>{items.time.slice(0, 9)}</td>
                      <td>
                        <img
                          src={SERVER_URL + "/news_img/" + items.news_pic}
                          height="50px"
                          width="50px"
                          alt=""
                        />
                      </td>
                      <td>
                        <button
                          className="btn btn-danger btnDel"
                          onClick={() => deleteNews(items._id)}
                        >
                          Delete
                        </button>{" "}
                        &nbsp;{" "}
                        <button
                          className="btn btn-primary btnUpdate"
                          onClick={() => navigate("/updatenews/" + items._id)}
                        >
                          Update
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr class="center">
                    <td colSpan="7">No Result Found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="bg_pageNum">
            <nav className="d-flex justify-content-center">
              <ul className="pagination">
                {pages.map((page) => {
                  return (
                    <div>
                      <li
                        className={`page-item ${
                          page === currentPage ? "active" : ""
                        }
                          `}
                      >
                        {/* {page} */}
                        <button
                          className="page-link"
                          onClick={() => pagination(page)}
                        >
                          {page}
                        </button>
                      </li>
                    </div>
                  );
                })}

                {/* <li className="page-link">1</li> */}
              </ul>
            </nav>
            <div className="d-flex justify-content-center fnt">
              <p className="ln-ht">Total Rows - {newsData.length}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageNews;
