import React from "react";
import { useEffect, useState } from "react";
import { Outlet, Link } from "react-router-dom";

function Footer() {
  const auth = localStorage.getItem("token");

  const [showButton, setShowButton] = useState(false);
  const [themeBtn, setThemeBtn] = useState(true);
  const [themeBtnSetting, setThemeBtnSetting] = useState(true);
  useEffect(() => {
    toggleTheme(false);
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);

  // This function will scroll the window to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smoothly scrolling
    });
  };

  function setTheme() {
    var theme = localStorage.getItem("theme");
    if (theme == "light") {
      //light them
      document.querySelector(":root").style.setProperty("--white", "#ffffff");
      document.querySelector(":root").style.setProperty("--black", "#292c3d");
      setThemeBtn(false);
    } else {
      //dark them
      document.querySelector(":root").style.setProperty("--white", "#292c3d");
      document.querySelector(":root").style.setProperty("--black", "#ffffff");
      setThemeBtn(true);
    }
  }

  function toggleTheme(checkTheme) {
    var theme = localStorage.getItem("theme");
    if (checkTheme) {
      if (theme == "undefined") {
        localStorage.setItem("theme", "dark");
      } else {
        if (theme == "light") {
          //light them
          localStorage.setItem("theme", "dark");
        } else {
          //dark them
          localStorage.setItem("theme", "light");
        }
      }
    }
    setTheme();
    setThemeColors();
  }

  function setThemeColors() {
    var primaryColor = localStorage.getItem("primaryColor");
    var secondaryColor = localStorage.getItem("secondaryColor");
    console.log("set theme color: " + primaryColor);
    console.log("set theme color: " + secondaryColor);

    if (primaryColor == "null" || secondaryColor == "null") {
      localStorage.setItem("primaryColor", "#fb4230");
      localStorage.setItem("secondaryColor", "#ff9800e6");
    } else {
      setPrimaryColor(primaryColor);
      setSecondaryColor(secondaryColor);
    }
  }

  function websiteSettingBox() {
    var boxId = document.getElementById("theme_setting_box");
    if (themeBtnSetting) {
      boxId.style.right = "0px";
      setThemeBtnSetting(false);
    } else {
      boxId.style.right = "-250px";
      setThemeBtnSetting(true);
    }
  }

  function setPrimaryColor(primaryColor) {
    document
      .querySelector(":root")
      .style.setProperty("--primary", primaryColor);
    document.getElementById("primary_color").value = primaryColor;
    localStorage.setItem("primaryColor", primaryColor);
  }

  function setSecondaryColor(secondaryColor) {
    document
      .querySelector(":root")
      .style.setProperty("--secondary", secondaryColor);
    document.getElementById("secondary_color").value = secondaryColor;
    localStorage.setItem("secondaryColor", secondaryColor);
  }
  function resetThemeSetting() {
    localStorage.setItem("primaryColor", "#fb4230");
    localStorage.setItem("secondaryColor", "#ff9800e6");
    setThemeColors();
  }
  return (
    <>
      {showButton && (
        <div onClick={scrollToTop} className="back-to-top" title="Back to Top">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            className="bi bi-arrow-up-short"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z"
            />
          </svg>
        </div>
      )}

      {/* <button
        className="js__dark-mode-toggle dark-mode-toggle"
        title="Dark/Light Theme"
        onClick={() => toggleTheme(true)}
        type="button"
      >
        {(themeBtn && <i className="fa-solid fa-sun theme_icon_sun"></i>) || (
          <i className="fa-sharp fa-solid fa-moon theme_icon_moon"></i>
        )}
      </button> */}
      {/* ======================================================= */}
      <button
        className="website_setting_button"
        title="Dark/Light Theme"
        type="button"
        onClick={() => websiteSettingBox()}
      >
        {(themeBtnSetting && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            className="bi bi-gear-fill theme_icon_sun"
            viewBox="0 0 16 16"
          >
            <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z" />
          </svg>
        )) || (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            className="bi bi-x"
            viewBox="0 0 16 16"
          >
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
          </svg>
        )}
      </button>

      <div className="theme_setting_box" id="theme_setting_box">
        <div className="theme_setting_box_header">
          <p className="theme_setting_box_label">Theme Setting</p>
          <i className="fa-solid fa-circle-xmark theme_setting_box_close"></i>
        </div>
        <div className="light_dark_box">
          <h6 className="m-0 light_dark_label">Light/Dark Theme</h6>
          <button
            className="js__dark-mode-toggle dark-mode-toggle"
            title="Dark/Light Theme"
            onClick={() => toggleTheme(true)}
            type="button"
          >
            {(themeBtn && (
              <i className="fa-solid fa-sun theme_icon_sun"></i>
            )) || <i className="fa-sharp fa-solid fa-moon theme_icon_moon"></i>}
          </button>
        </div>

        <div className="color_picker_box padding_box">
          <p className="m-0 light_dark_label">Primary Color</p>
          <input
            type="color"
            name="primary_color"
            id="primary_color"
            onChange={(e) => setPrimaryColor(e.target.value)}
          />
        </div>
        <div className="color_picker_box padding_box">
          <p className="m-0 light_dark_label">Secondary Color</p>
          <input
            type="color"
            name="secondary_color"
            id="secondary_color"
            onChange={(e) => setSecondaryColor(e.target.value)}
          />
        </div>
        <p className="reset_theme" onClick={() => resetThemeSetting()}>
          Reset Theme color
        </p>
      </div>

      {/* ========================================================= */}
      <footer className="footer-section">
        <div className="container">
          <canvas className="footer_section"></canvas>
          <div className="footer-cta">
            <div className="row">
              <div className="col-xl-4 col-md-4 mb-30">
                <div className="single-cta">
                  <i className="fas fa-map-marker-alt"></i>
                  <div className="cta-text">
                    <h4>Find us</h4>
                    <span>1010 xyz, sw 54321, indore MP</span>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-4 mb-30">
                <div className="single-cta">
                  <i className="fas fa-phone"></i>
                  <div className="cta-text">
                    <h4>Call us</h4>
                    <span>+91 9758456284</span>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-4 mb-30">
                <div className="single-cta">
                  <i className="far fa-envelope-open"></i>
                  <div className="cta-text">
                    <h4>Mail us</h4>
                    <span>mail@info.com</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-content pt-5 pb-5">
            <div className="row">
              <div className="col-xl-4 col-lg-4 mb-50">
                <div className="footer-widget">
                  <div className="footer-logo">
                    <h1 className="text-white">
                      <Link className="text-white" to="/">
                        LOGO
                      </Link>
                    </h1>
                    {/* <a href="index.html">
                      <img
                        src="https://i.ibb.co/QDy827D/ak-logo.png"
                        className="img-fluid"
                        alt="logo"
                      />
                    </a> */}
                  </div>
                  <div className="footer-text">
                    <p>
                      Lorem ipsum dolor sit amet, consec tetur adipisicing elit,
                      sed do eiusmod tempor incididuntut consec tetur
                      adipisicing elit,Lorem ipsum dolor sit amet.
                    </p>
                  </div>
                  <div className="footer-social-icon">
                    <span>Follow us</span>
                    <a href="#">
                      <i className="fab fa-facebook-f facebook-bg"></i>
                    </a>
                    <a href="#">
                      <i className="fab fa-twitter twitter-bg"></i>
                    </a>
                    <a href="#">
                      <i className="fab fa-google-plus-g google-bg"></i>
                    </a>
                    <a href="#">
                      <i className="fab fa-twitter twitter-bg"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <h3>Useful Links</h3>
                  </div>
                  <ul>
                    <li>
                      <i className="fa fa-chevron-right"></i>
                      <Link to="/">Home</Link>
                    </li>
                    {
                      <li>
                        <i className="fa fa-chevron-right"></i>
                        <Link to="/blogs">Blogs</Link>
                      </li>
                    }
                    <li>
                      <i className="fa fa-chevron-right"></i>
                      <Link to="/news">News</Link>
                    </li>
                    <li>
                      <i className="fa fa-chevron-right"></i>
                      <Link to="/events ">Events</Link>
                    </li>
                    <li>
                      <i className="fa fa-chevron-right"></i>
                      <Link to="/blogs">Contact</Link>
                    </li>
                    <li>
                      <i className="fa fa-chevron-right"></i>
                      <a href="#">About us</a>
                    </li>
                    <li>
                      <i className="fa fa-chevron-right"></i>
                      <a href="#">Our Services</a>
                    </li>
                    <li>
                      <i className="fa fa-chevron-right"></i>
                      <a href="#">Expert Team</a>
                    </li>
                    <li>
                      <i className="fa fa-chevron-right"></i>
                      <a href="#">Contact us</a>
                    </li>
                    <li>
                      <i className="fa fa-chevron-right"></i>
                      <a href="#">Latest News</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <h3>Useful Links</h3>
                  </div>
                  <ul>
                    <li>
                      <i className="fa fa-chevron-right"></i>
                      <a href="#">Home</a>
                    </li>
                    <li>
                      <i className="fa fa-chevron-right"></i>
                      <a href="#">about</a>
                    </li>
                    <li>
                      <i className="fa fa-chevron-right"></i>
                      <a href="#">services</a>
                    </li>
                    <li>
                      <i className="fa fa-chevron-right"></i>
                      <a href="#">portfolio</a>
                    </li>
                    <li>
                      <i className="fa fa-chevron-right"></i>
                      <a href="#">Contact</a>
                    </li>
                    <li>
                      <i className="fa fa-chevron-right"></i>
                      <a href="#">About us</a>
                    </li>
                    <li>
                      <i className="fa fa-chevron-right"></i>
                      <a href="#">Our Services</a>
                    </li>
                    <li>
                      <i className="fa fa-chevron-right"></i>
                      <a href="#">Expert Team</a>
                    </li>
                    <li>
                      <i className="fa fa-chevron-right"></i>
                      <a href="#">Contact us</a>
                    </li>
                    <li>
                      <i className="fa fa-chevron-right"></i>
                      <a href="#">Latest News</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-area">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-6 col-lg-6 text-center text-lg-left">
                <div className="copyright-text">
                  <p>
                    Copyright &copy;2023, All Right Reserved &nbsp;
                    <a href="https://bitbyteindia.com/" target="_blank">
                      Bitbyteindia.com
                    </a>
                  </p>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 d-none d-lg-block text-right">
                <div className="footer-menu">
                  <ul className="m-0">
                    <li>
                      <a href="#">Home</a>
                    </li>
                    <li>
                      <a href="#">Terms</a>
                    </li>
                    <li>
                      <a href="#">Privacy</a>
                    </li>
                    <li>
                      <a href="#">Policy</a>
                    </li>
                    <li>
                      <a href="#">Contact</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
