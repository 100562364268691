import React, { useEffect, useState, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "../AddEvent.css";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import jQuery from "jquery";
const AddEvent = () => {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const [error, setError] = useState(false);
  const [reqMsg, setReqMsg] = useState(
    <span style={{ color: "red", fontSize: "0.875rem" }}>Field Required *</span>
  );
  const [saved, setSaved] = useState("");
  const [image, setImage] = useState("");

  const [eventData, setEventData] = useState({
    title: "",
    writer_name: "",
    description: "",
    current_date: "",
    time: "",
    location: "",
  });

  let name, value;
  const handleFormData = (e) => {
    // console.log(e);
    name = e.target.name;
    value = e.target.value;

    setEventData({ ...eventData, [name]: value });
    // console.log({ ...eventData, [name]: value });
  };
  const handleEditorChange = (newContentState) => {
    console.log(newContentState);
    setEventData((prevData) => ({
      ...prevData,
      // description: JSON.stringify(newContentState),
      description: newContentState,
    }));
  };
  const handleImageData = (e) => {
    // console.log(e);
    setImage(e.target.files[0]);
    // console.log(e.target.files[0]);
  };
  const navigate = useNavigate();

  const collectData = async () => {
    // const admin = localStorage.getItem('admin');
    if (
      !eventData.title ||
      !eventData.description ||
      !eventData.current_date ||
      !eventData.writer_name ||
      !eventData.time ||
      !eventData.location ||
      !image
    ) {
      setError(true);
      return false;
    }

    const {
      title,
      description,
      current_date,
      time,
      location,

      writer_name,
    } = eventData;

    const formData = new FormData();

    formData.append("event_pic", image);
    formData.append("title", title);
    formData.append("writer_name", writer_name);
    formData.append("description", description);
    formData.append("current_date", current_date);
    formData.append("time", time);
    formData.append("location", location);
    console.log(formData);
    const res = await fetch(SERVER_URL + "/eventData", {
      method: "POST",
      body: formData,
    });
    const data = await res.json();
    if (data.status === 422 || !data) {
      window.alert("Invalid Information");
      window.alert(data.status);
    } else if (res.ok) {
      // window.alert("Event Saved");
      setSaved(<span style={{ color: "green" }}>Event Saved</span>);
      document.getElementById("reset").reset();
      setImage("");
      setEventData({
        title: "",
        writer_name: "",
        description: "",
        current_date: "",
        time: "",
        location: "",
      });
      setTimeout(() => {
        setSaved("");
      }, 2000);
    }
  };
  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline"],
      [{ color: [] }, { background: [] }],
    ],
  };
  const fileInputRef = useRef(null);
  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };
  return (
    <>
      <div>
        <form class="my-form bg_main">
          <div>
            <div>
              <div class="col-md-12 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title mb-4 pb-4">ADD EVENT</h4>
                    <div className="text-center">{saved}</div>

                    <form id="reset" enctype="multipart/form-data">
                      <div class="form-group">
                        <label>Event Title</label>

                        <input
                          type="text"
                          placeholder="Enter Title"
                          value={eventData.title}
                          name="title"
                          className="form-control"
                          onChange={handleFormData}
                        />
                        {error && !eventData.title && reqMsg}
                      </div>
                      <div className="row">
                        {/* <div className="form-group col">
                          <label>Event Image</label>

                          <input
                            type="file"
                            name="event_pic"
                            className="form-control"
                            onChange={handleImageData}
                          />
                          {error && !image && reqMsg}
                        </div> */}

                        <div class="form-group col">
                          <label>Event Image</label>
                          <input
                            type="file"
                            name="event_pic"
                            class="file-upload-default"
                            ref={fileInputRef}
                            onChange={handleImageData}
                          />
                          <div class="input-group col-xs-12">
                            <input
                              type="text"
                              class="form-control file-upload-info"
                              readOnly
                              onClick={handleUploadButtonClick}
                              placeholder={image ? image.name : "Upload Image"}
                            />
                            <span class="input-group-append">
                              <button
                                class="file-upload-browse btn appButton"
                                type="button"
                                onClick={handleUploadButtonClick}
                              >
                                Upload
                              </button>
                            </span>
                          </div>
                          {error && !image && reqMsg}
                        </div>
                        <div class="form-group col">
                          <label>Writer's Name </label>

                          <input
                            type="text"
                            placeholder="Enter Writer's Name"
                            value={eventData.writer_name}
                            name="writer_name"
                            className="form-control"
                            onChange={handleFormData}
                          />
                          {error && !eventData.writer_name && reqMsg}
                        </div>
                      </div>
                      <div class="form-group">
                        <label>Event Description</label>

                        <ReactQuill
                          placeholder="Enter The Event Description"
                          theme="snow"
                          modules={modules}
                          // name="description"
                          value={eventData.description}
                          onChange={handleEditorChange}
                        />
                        {error && !eventData.description && reqMsg}
                      </div>
                      {/* <div class="form-group">
                        <label>Event Description</label>

                        <textarea
                          rows="10"
                          placeholder="Enter The Event Description"
                          value={eventData.description}
                          name="description"
                          className="form-control"
                          onChange={handleFormData}
                        />
                        {error && !eventData.description && reqMsg}
                      </div> */}

                      <div className="row">
                        <div className="form-group col">
                          <label>Event Date</label>

                          <input
                            type="date"
                            className="date form-control"
                            name="current_date"
                            value={eventData.current_date}
                            placeholder="Date"
                            onChange={handleFormData}
                          />
                          {error && !eventData.current_date && reqMsg}
                        </div>

                        <div className="form-group col">
                          <label>Event Time</label>

                          <input
                            type="time"
                            className="form-control"
                            value={eventData.time}
                            name="time"
                            placeholder="Time"
                            onChange={handleFormData}
                          />
                          {error && !eventData.time && reqMsg}
                        </div>

                        <div className="form-group col">
                          <label>Event Location</label>

                          <input
                            type="text"
                            className="form-control"
                            name="location"
                            value={eventData.location}
                            placeholder="Enter Event Location"
                            onChange={handleFormData}
                          />
                          {error && !eventData.location && reqMsg}
                        </div>
                      </div>

                      <div className="form-group">
                        <button
                          onClick={collectData}
                          className="btn appButton"
                          type="button"
                        >
                          Add Event
                        </button>{" "}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddEvent;
