import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

const SignUpUser = () => {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  // const [for_email, setForEmail] = useState(
  //   /^[a-z]+[0-9]*[@]{1}[a-z]{2,8}[.]{1}[a-z]{2,8}$/gm
  // );
  const [for_email, setForEmail] = useState(
    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/gm
  );

  const [errMsg, setErrMsg] = useState("");
  const [image, setImage] = useState("");

  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
  });

  let name, value;
  const handleFormData = (e) => {
    // console.log(e);
    name = e.target.name;
    value = e.target.value;

    setUser({ ...user, [name]: value });
  };

  // const handleImageData = (e) => {
  //   // console.log(e);
  //   setImage(e.target.files[0]);
  //   console.log(e.target.files[0]);
  // };
  const navigate = useNavigate();
  // useEffect(() => {
  //   const auth = localStorage.getItem("admin");
  //   if (auth) {
  //     navigate("/profile");
  //   }
  // }, []);
  const [error, setError] = useState(false);
  const [valError, setValError] = useState(false);
  const [reqMsg, setReqMsg] = useState(
    <span style={{ color: "red", fontSize: "0.775rem" }}>Field Required *</span>
  );

  const [validateEmail, setValidateEmail] = useState(
    <span style={{ color: "red", fontSize: "0.775rem" }}>
      Enter Valid Email *
    </span>
  );
  const [saved, setSaved] = useState("");

  const collectData = async () => {
    const { name, email, password } = user;
    if (!user.name || !user.email || !user.password) {
      setError(true);
      return false;
    }
    if (!user.email.match(for_email)) {
      setValError(true);
      return false;
    }
    const formData = new FormData();
    // formData.append("user_pic", image);
    formData.append("name", name);
    formData.append("email", email);
    formData.append("password", password);
    console.log(formData);
    const res = await fetch(SERVER_URL + "/userRegister", {
      method: "POST",
      body: formData,
    });
    const data = await res.json();
    if (data.status === 422 || !data) {
      // window.alert("Invalid registration");
      document.getElementById("reset").reset();
      // setUser({ name: "", email: "", password: "" });
      setErrMsg(<span style={{ color: "red" }}>{data.error}</span>);
      setTimeout(() => {
        setErrMsg("");
        navigate("/signup");
      }, 3000);
    } else if (res.ok) {
      // window.alert("Registration Successful");
      setSaved(
        <span style={{ color: "green", fontSize: "0.875rem" }}>
          Registration Successful
        </span>
      );
      setTimeout(() => {
        setSaved("");
        navigate("/verifyemail");
      }, 3000);
      localStorage.setItem("verifyUserEmail", JSON.stringify(data.email));
    }
  };
  const fileInputRef = useRef(null);
  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };
  return (
    <div>
      {/* <h3 class="card-title text-center mb-3">LOGIN</h3> */}
      <div className="d-md-flex bgImg half">
        <div className="loginSide">
          <div className="loginSideImg"></div>
          {/* <div className="bg-text">
            <h3>Welcome To Brand Name</h3>
          </div> */}
        </div>
        <div className="content">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-md-12">
                <div class="auth">
                  <div className="log_card mx-auto">
                    <div className="text-center">{saved}</div>
                    <div className="text-center">{errMsg}</div>

                    <h3>Sign Up</h3>

                    <form
                      id="reset"
                      method="POST"
                      encType="multipart/form-data"
                    >
                      {/* <div class="form-group">
                        <label>Upload Image *</label>
                        <input
                          type="file"
                          name="user_pic"
                          class="file-upload-default"
                          ref={fileInputRef}
                          onChange={handleImageData}
                        />
                        <div class="input-group col-xs-12">
                          <input
                            type="text"
                            class="form-control file-upload-info"
                            disabled
                            placeholder={image ? image.name : "Upload Image"}
                          />
                          <span class="input-group-append">
                            <button
                              class="file-upload-browse btn appButton"
                              type="button"
                              onClick={handleUploadButtonClick}
                            >
                              Upload
                            </button>
                          </span>
                        </div>
                        {error && !image && reqMsg}
                      </div> */}
                      {/* <div class="form-group">
                        <label>Upload Image *</label>
                        <form method="POST" encType="multipart/form-data">
                          <input
                            className="form-control"
                            type="file"
                            name="user_pic"
                            onChange={handleImageData}
                          />
                          {error && !image && reqMsg}
                        </form>
                      </div> */}
                      <div class="form-group">
                        <label>Name *</label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Name"
                          value={user.name}
                          name="name"
                          onChange={handleFormData}
                        />
                        {error && !user.name && reqMsg}
                      </div>
                      <div class="form-group">
                        <label>Email *</label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Email"
                          value={user.email}
                          name="email"
                          onChange={handleFormData}
                        />
                        {error && !user.email && reqMsg}
                        {valError && user.email && validateEmail}
                      </div>
                      <div class="form-group">
                        <label>Password *</label>
                        <input
                          className="form-control"
                          type="password"
                          placeholder="Enter password"
                          value={user.password}
                          name="password"
                          onChange={handleFormData}
                        />
                        {error && !user.password && reqMsg}
                      </div>

                      <div class="text-center">
                        <button
                          onClick={collectData}
                          className="btn signup_btn"
                          type="button"
                        >
                          Sign Up
                        </button>
                      </div>

                      <p class="sign-up text-center">
                        Already have an Account?{" "}
                        <Link to="/login" className="link">
                          Log In
                        </Link>
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpUser;
