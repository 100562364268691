import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
const ResetPassword = () => {
  const navigate = useNavigate();
  const [storeMatch, setStoreMatch] = useState("");

  const [user, setUser] = useState({
    email: "",
    new_pass: "",
    confirm_pass: "",
  });

  let name, value;
  const handleFormData = (e) => {
    // console.log(e);
    name = e.target.name;
    value = e.target.value;

    setUser({ ...user, [name]: value });
  };
  function match() {
    if (user.new_pass === user.confirm_pass) {
      setStoreMatch(
        <span style={{ color: "green" }}>New And Confirm Password Matched</span>
      );
    } else {
      setStoreMatch(
        <span style={{ color: "red" }}>
          New And Confirm Password Not Matched
        </span>
      );
    }
  }
  //   const postData = async (e) => {
  //     e.preventDefault();
  //     const email = localStorage.getItem("email");
  //     const { password } = user;
  //     console.log(email, password);
  //     const res = await fetch("http://localhost:3100/check_admin_password", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         email,
  //         password,
  //       }),
  //     });
  //     const data = await res.json();
  //     console.log(data);
  //     if (res.ok && user.new_pass === user.confirm_pass) {
  //       updateData();
  //       window.alert("Password Changed");
  //     } else {
  //       window.alert("Password Did Not Match");
  //     }
  //   };

  //   const updateData = async () => {
  //     const email = localStorage.getItem("email");
  //     const { new_pass } = user;
  //     console.log(user);
  //     const res = await fetch("http://localhost:3100/change_admin_password", {
  //       method: "POST",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         email,
  //         new_pass,
  //       }),
  //     });
  //     const data = await res.json();
  //     if (res.ok) {
  //       console.log("Working Api Of Change Password");
  //     } else {
  //       console.log(data.error);
  //     }
  //   };

  return (
    <>
      <div class="card col-lg-4 mx-auto">
        <div class="card-body px-5 py-5">
          <h3 class="card-title">RESET PASSWORD</h3>

          {/* <div className="form-group">
              <label>Current Password</label>

              <input
                type="text"
                className="form-control"
                name="password"
                placeholder="Enter Current Password"
                onChange={handleFormData}
              ></input>
            </div> */}

          <div className="form-group">
            <label>New Password</label>

            <input
              type="text"
              className="form-control"
              name="new_pass"
              placeholder="Enter New Password"
              onChange={handleFormData}
            ></input>
          </div>

          <div className="form-group">
            <label>Confirm Password</label>

            <input
              type="text"
              className="form-control"
              name="confirm_pass"
              placeholder="Confirm Password"
              onChange={handleFormData}
              onKeyUp={match}
            ></input>
          </div>
          <div className="form-group" style={{ color: "green" }}>
            {storeMatch}
          </div>
          {/* <div className="mt-5 text-center">
            <button className="btn appButton">Reset Password</button>{" "}
            <button
              className="btn appButton"
              onClick={() => navigate("/forgotpass")}
            >
              Back To Forgot Password
            </button>
          </div> */}
          <p class="sign-up text-center">
            Back to <Link to="/forgotpass">Forgot Password</Link>
          </p>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
