import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
const ChangePassword = () => {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const navigate = useNavigate();
  const [storeMatch, setStoreMatch] = useState("");
  const [error, setError] = useState(false);
  const [reqMsg, setReqMsg] = useState(
    <span style={{ color: "red", fontSize: "0.875rem" }}>Field Required *</span>
  );
  const [saved, setSaved] = useState("");
  const [user, setUser] = useState({
    email: "",
    new_pass: "",
    confirm_pass: "",
  });

  let name, value;
  const handleFormData = (e) => {
    // console.log(e);
    name = e.target.name;
    value = e.target.value;

    setUser({ ...user, [name]: value });
  };
  function match() {
    if (user.new_pass === user.confirm_pass) {
      setStoreMatch(
        <span style={{ color: "green" }}>New And Confirm Password Matched</span>
      );
    } else {
      setStoreMatch(
        <span style={{ color: "red" }}>
          New And Confirm Password Not Matched
        </span>
      );
      postData();
    }
  }
  const postData = async (e) => {
    e.preventDefault();
    const email = localStorage.getItem("email");
    if (!user.password || !user.confirm_pass) {
      setError(true);
      return false;
    }
    const { password } = user;
    console.log(email, password);
    const res = await fetch(SERVER_URL + "/check_admin_password", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        password,
      }),
    });
    const data = await res.json();
    console.log(data.message);
    if (res.ok) {
      updateData();
      // window.alert("Password Changed");
      setSaved(<span style={{ color: "green" }}>Password Changed</span>);
    } else {
      window.alert("Current Password Did Not Match");
    }
  };

  const updateData = async () => {
    const email = localStorage.getItem("email");
    if (!user.new_pass) {
      setError(true);
      return false;
    }
    const { new_pass } = user;
    console.log(user);
    const res = await fetch(SERVER_URL + "/change_admin_password", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        new_pass,
      }),
    });
    const data = await res.json();
    if (res.ok) {
      console.log("Working Api Of Change Password");
    } else {
      console.log(data.error);
    }
  };

  return (
    <>
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4 pb-4">CHANGE PASSWORD</h4>

            <div className="form-group">
              <label>Current Password</label>

              <input
                type="password"
                className="form-control"
                name="password"
                placeholder="Enter Current Password"
                onChange={handleFormData}
              />
              {error && !user.password && reqMsg}
            </div>

            <div className="form-group">
              <label>New Password</label>

              <input
                type="password"
                className="form-control"
                name="new_pass"
                placeholder="Enter New Password"
                onChange={handleFormData}
              />
              {error && !user.new_pass && reqMsg}
            </div>

            <div className="form-group">
              <label>Confirm Password</label>
              <input
                type="password"
                className="form-control"
                name="confirm_pass"
                placeholder="Confirm Password"
                onChange={handleFormData}
                onKeyUp={match}
              />{" "}
              {error && !user.confirm_pass && reqMsg}
            </div>
            <div className="form-group" style={{ color: "green" }}>
              {storeMatch}
            </div>
            <div className="mt-5 text-center">
              <button className="btn appButton" onClick={postData}>
                Update Password
              </button>{" "}
              {saved}{" "}
              <button
                className="btn appButton"
                onClick={() => navigate("/profile")}
              >
                Back To Profile
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
