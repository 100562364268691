import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";

const NewVerification = () => {
  const navigate = useNavigate();

  const [errMsg, setErrMsg] = useState("");
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [saved, setSaved] = useState("");
  const [reqMsg, setReqMsg] = useState(
    <span style={{ color: "red", fontSize: "0.775rem" }}>Field Required *</span>
  );
  const [valError, setValError] = useState(false);

  const [for_email, setForEmail] = useState(
    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/gm
  );
  const [validateEmail, setValidateEmail] = useState(
    <span style={{ color: "red", fontSize: "0.775rem" }}>
      Enter Valid Email *
    </span>
  );
  const collectData = async () => {
    if (!email) {
      setError(true);
      return false;
    }
    if (!email.match(for_email)) {
      setValError(true);
      return false;
    }
    const res = await fetch(SERVER_URL + "/newVerifyEmail", {
      method: "post",
      body: JSON.stringify({ email }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await res.json();
    if (data.status === 422 || !data) {
      // window.alert("Invalid registration");
      document.getElementById("reset").reset();
      // setUser({ name: "", email: "", password: "" });
      setErrMsg(<span style={{ color: "red" }}>{data.error}</span>);
      setTimeout(() => {
        setErrMsg("");
        navigate("/newverifyemail");
      }, 3000);
    } else if (res.ok) {
      setSaved(
        <span style={{ color: "green", fontSize: "0.875rem" }}>
          Email sent successfully
        </span>
      );

      localStorage.setItem("verifyUserEmail", JSON.stringify(data.email));

      setTimeout(() => {
        setSaved("");
        navigate("/verifyemail");
      }, 3000);
    }
  };
  return (
    <>
      <form id="reset">
        {/* <h3 class="card-title text-center mb-3">LOGIN</h3> */}
        <div className="d-md-flex bgImg half">
          <div className="loginSide">
            <div className="loginSideImg"></div>
            {/* <div className="bg-text">
            <h3>Welcome To Brand Name</h3>
          </div> */}
          </div>
          <div className="content">
            <div className="container">
              <div className="row align-items-center justify-content-center">
                <div className="col-md-12">
                  <div class="auth">
                    <div className="log_card mx-auto">
                      <div className="text-center">{saved}</div>
                      <div className="text-center">{errMsg}</div>

                      <h3>Email Verification</h3>

                      <form>
                        <div class="form-group">
                          <label>Email *</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Email"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                          />
                          {error && !email && reqMsg}
                        </div>

                        <div class="text-center">
                          <button
                            onClick={collectData}
                            className="btn login_btn"
                            type="button"
                          >
                            Send
                          </button>
                        </div>

                        {/* <p class="log-in text-center mt-3">
                          Please do not reload or close this page!
                        </p> */}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default NewVerification;
