import React from "react";
import { useEffect, useState } from "react";

function About() {
  //
  const [activeClass1, setActiveClass1] = useState("active");
  const [activeClass2, setActiveClass2] = useState("");
  const [activeClass3, setActiveClass3] = useState("");
  const [activeClass4, setActiveClass4] = useState("");
  const [activeClass5, setActiveClass5] = useState("");
  const [activeClass6, setActiveClass6] = useState("");
  function activeTab(check) {
    setActiveClass1("");
    setActiveClass2("");
    setActiveClass3("");
    setActiveClass4("");
    setActiveClass5("");
    setActiveClass6("");
    if (check == 1) {
      setActiveClass1("active");
    } else if (check == 2) {
      setActiveClass2("active");
    } else if (check == 3) {
      setActiveClass3("active");
    } else if (check == 4) {
      setActiveClass4("active");
    } else if (check == 5) {
      setActiveClass5("active");
    } else {
      setActiveClass6("active");
    }
  }

  return (
    <>
      <section id="about-us" className="about-us-bg section-common-space">
        <div className="container">
          <div className="section-header section-header-v2 text-center">
            <h2 className="section-title">
              Small teams.
              <span className="highlighed_title"> Big results.</span>
            </h2>
            <p className="section-subtitle ft-fm-2">
              You'll be working with a small, dedicated team built to fit the
              <br />
              needs of your project.
            </p>
          </div>
          <div className="tabbed-about-us tabbed-about-us-v2">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <ul className="tabs-nav" role="tablist">
                  <li
                    className={"nav-item " + activeClass1}
                    role="presentation"
                    onClick={(e) => {
                      activeTab(1);
                    }}
                  >
                    <a
                      href="#web"
                      aria-controls="web"
                      role="tab"
                      className="nav-link active"
                      data-bs-toggle="tab"
                    >
                      <span className="icon">
                        <i className="fas fa-server"></i>
                      </span>
                      Web Design
                    </a>
                    <span className="bgcolor-major-gradient-overlay"></span>
                  </li>
                  <li
                    className={"nav-item " + activeClass2}
                    // className="nav-item "
                    role="presentation"
                    onClick={(e) => {
                      activeTab(2);
                    }}
                  >
                    <a
                      href="#print"
                      aria-controls="print"
                      className="nav-link"
                      role="tab"
                      data-bs-toggle="tab"
                    >
                      <span className="icon">
                        <i className="fas fa-print"></i>
                      </span>
                      Print Design
                    </a>
                    <span className="bgcolor-major-gradient-overlay"></span>
                  </li>
                  <li
                    // className="nav-item"
                    className={"nav-item " + activeClass3}
                    role="presentation"
                    onClick={(e) => {
                      activeTab(3);
                    }}
                  >
                    <a
                      href="#development"
                      aria-controls="development"
                      role="tab"
                      className="nav-link"
                      data-bs-toggle="tab"
                    >
                      <span className="icon">
                        <i className="fab fa-buromobelexperte"></i>
                      </span>
                      Development
                    </a>
                    <span className="bgcolor-major-gradient-overlay"></span>
                  </li>
                  <li
                    // className="nav-item"
                    className={"nav-item " + activeClass4}
                    role="presentation"
                    onClick={(e) => {
                      activeTab(4);
                    }}
                  >
                    <a
                      href="#branding"
                      aria-controls="branding"
                      role="tab"
                      className="nav-link"
                      data-bs-toggle="tab"
                    >
                      <span className="icon">
                        <i className="far fa-building"></i>
                      </span>
                      Branding
                    </a>
                    <span className="bgcolor-major-gradient-overlay"></span>
                  </li>
                  <li
                    className={"nav-item " + activeClass5}
                    // className="nav-item"
                    role="presentation"
                    onClick={(e) => {
                      activeTab(5);
                    }}
                  >
                    <a
                      href="#marketing"
                      aria-controls="marketing"
                      className="nav-link"
                      role="tab"
                      data-bs-toggle="tab"
                    >
                      <span className="icon">
                        <i className="fab fa-codiepie"></i>
                      </span>
                      Marketing
                    </a>
                    <span className="bgcolor-major-gradient-overlay"></span>
                  </li>
                  <li
                    // className="nav-item"
                    className={"nav-item " + activeClass6}
                    role="presentation"
                    onClick={(e) => {
                      activeTab(6);
                    }}
                  >
                    <a
                      href="#support"
                      aria-controls="marketing"
                      role="tab"
                      className="nav-link"
                      data-bs-toggle="tab"
                    >
                      <span className="icon">
                        <i className="fas fa-headset"></i>
                      </span>
                      support
                    </a>
                    <span className="bgcolor-major-gradient-overlay"></span>
                  </li>
                </ul>
              </div>
              <div className="col-sm-6">
                <div className="tab-content">
                  <div role="tabpanel" className="tab-pane active" id="web">
                    <div className="details-wrapper">
                      <div className="details">
                        <p>
                          Webdesign ipsum dolor sit amet, consectetur
                          adipisicing elit, sed do eiusmod tempor incididunt ut
                          labore et dolore magna aliqua. Ut enim ad minim
                          veniam. quis nostrud exercitation ullamco.
                        </p>
                        <p>
                          Laboris nisi ut aliquip ex ea commodo consequat. Duis
                          aute irure dolor in reprehenderit in voluptate velit
                          esse cillum dolore.eu
                        </p>
                      </div>
                    </div>
                  </div>
                  <div role="tabpanel" className="tab-pane" id="print">
                    <div className="details-wrapper">
                      <div className="details">
                        <p>
                          Print Design ipsum dolor sit amet, consectetur
                          adipisicing elit, sed do eiusmod tempor incididunt ut
                          labore et dolore magna aliqua. Ut enim ad minim
                          veniam. quis nostrud exercitation ullamco.
                        </p>
                        <p>
                          Laboris nisi ut aliquip ex ea commodo consequat. Duis
                          aute irure dolor in reprehenderit in voluptate velit
                          esse cillum dolore.eu
                        </p>
                      </div>
                    </div>
                  </div>
                  <div role="tabpanel" className="tab-pane" id="development">
                    <div className="details-wrapper">
                      <div className="details">
                        <p>
                          Development ipsum dolor sit amet, consectetur
                          adipisicing elit, sed do eiusmod tempor incididunt ut
                          labore et dolore magna aliqua. Ut enim ad minim
                          veniam. quis nostrud exercitation ullamco.
                        </p>
                        <p>
                          Laboris nisi ut aliquip ex ea commodo consequat. Duis
                          aute irure dolor in reprehenderit in voluptate velit
                          esse cillum dolore.eu
                        </p>
                      </div>
                    </div>
                  </div>
                  <div role="tabpanel" className="tab-pane" id="branding">
                    <div className="details-wrapper">
                      <div className="details">
                        <p>
                          Branding ipsum dolor sit amet, consectetur adipisicing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam. quis
                          nostrud exercitation ullamco.
                        </p>
                        <p>
                          Laboris nisi ut aliquip ex ea commodo consequat. Duis
                          aute irure dolor in reprehenderit in voluptate velit
                          esse cillum dolore.eu
                        </p>
                      </div>
                    </div>
                  </div>
                  <div role="tabpanel" className="tab-pane" id="marketing">
                    <div className="details-wrapper">
                      <div className="details">
                        <p>
                          Marketing ipsum dolor sit amet, consectetur
                          adipisicing elit, sed do eiusmod tempor incididunt ut
                          labore et dolore magna aliqua. Ut enim ad minim
                          veniam. quis nostrud exercitation ullamco.
                        </p>
                        <p>
                          Laboris nisi ut aliquip ex ea commodo consequat. Duis
                          aute irure dolor in reprehenderit in voluptate velit
                          esse cillum dolore.eu
                        </p>
                      </div>
                    </div>
                  </div>
                  <div role="tabpanel" className="tab-pane" id="support">
                    <div className="details-wrapper">
                      <div className="details">
                        <p>
                          Support ipsum dolor sit amet, consectetur adipisicing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam. quis
                          nostrud exercitation ullamco.
                        </p>
                        <p>
                          Laboris nisi ut aliquip ex ea commodo consequat. Duis
                          aute irure dolor in reprehenderit in voluptate velit
                          esse cillum dolore.eu
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default About;
