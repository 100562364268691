import React, { useState, useEffect } from "react";
import { BrowserRouter, Link, NavLink, Route, Routes } from "react-router-dom";
import Moment from "react-moment";
import moment from "moment";
import _ from "lodash";
import "../css/event.css";
import "../css/web_blogs.css";
import "../css/web_style.css";
const bcrypt = require("bcryptjs");
// For Redux trial
// import { useSelector } from "react-redux";
// import "bootstrap/dist/css/bootstrap.min.css";

const Blog = () => {
  // For Redux trial

  // const token = useSelector((state) => state.token);
  // console.log("Token - " + token + " - Not Coming");

  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  let array = [1, 2, 3, 4];
  let array_blogs = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  const [auth, setAuth] = useState("false");
  const [userData, setUserData] = useState([]);
  const [userId, setUserId] = useState([]);
  const [blogData, setBlogData] = useState([]);
  const [tokenData, setTokenData] = useState([]);
  const [topBlogData, setTopBlogData] = useState([]);
  const [normalBlogData, setNormalBlogData] = useState([]);
  const [remainingPosition, setRemainingPosition] = useState();
  const normalBlogs = normalBlogData.slice(0, remainingPosition);
  const mergedBlogs = [...topBlogData, ...normalBlogs];
  const [category, setCategory] = useState([]);
  const [activeCategory, setActiveCategory] = useState("");
  const [blogFilter, setBlogFilter] = useState([]);
  const [premiumFilter, setPremiumFilter] = useState([]);
  const [premiumType, setPremiumType] = useState("");
  // let catInData = blogData.map((cat) => {
  //   return cat.category;
  // });
  // console.log(catInData);
  function checkCategory(activeCategory) {
    console.log(activeCategory);
    setActiveCategory(activeCategory);
  }

  const getBlogData = async () => {
    let result = await fetch(SERVER_URL + "/blogData", {
      headers: {
        authorization: JSON.parse(localStorage.getItem("token")),
      },
    });
    result = await result.json();
    // setPaginationData(_(result).slice(0).take(pageSize).value());
    setBlogData(result);
  };

  useEffect(() => {
    getBlogData();
  }, []);

  const getTopBlogData = async () => {
    let result = await fetch(SERVER_URL + "/topBlogData", {
      headers: {
        authorization: JSON.parse(localStorage.getItem("token")),
      },
    });
    result = await result.json();
    setRemainingPosition(5 - result.length);
    setTopBlogData(result);
  };

  useEffect(() => {
    getTopBlogData();
  }, []);

  const getNormalBlogData = async () => {
    let result = await fetch(SERVER_URL + "/normalBlogData", {
      headers: {
        authorization: JSON.parse(localStorage.getItem("token")),
      },
    });
    result = await result.json();

    setNormalBlogData(result);
  };
  useEffect(() => {
    getNormalBlogData();
  }, []);

  function truncate(str, n) {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  }

  //Fetching Category
  useEffect(() => {
    const fetchInfo = async () => {
      const res = await fetch(SERVER_URL + "/get_category");
      const data = await res.json();
      // console.log(data);
      setCategory(data);
    };
    fetchInfo();
  }, []);

  function filterFun() {
    category.map(() => {
      const blogs = blogData.filter(
        (blog) => blog.category === activeCategory
        // console.log(blog.category === activeCategory)
      );
      setBlogFilter(blogs);
      console.log(blogs);
    });
  }
  useEffect(() => {
    filterFun();
  }, [activeCategory]);

  function premiumFilterFun() {
    blogData.map(() => {
      const blogs = blogData.filter(
        (blog) => blog.blog_type === premiumType
        // console.log(blog.category === activeCategory)
      );
      setPremiumFilter(blogs);
      console.log(blogs);
    });
  }
  useEffect(() => {
    premiumFilterFun();
  }, [premiumType]);

  function dateChange(str) {
    let date = new Date(str);
    return date.toDateString();
  }

  // const date_create = moment().format("YYYY-MM-DD");
  // var tempDate = new Date();
  // var date =
  //   tempDate.getFullYear() +
  //   "-" +
  //   (tempDate.getMonth() + 1) +
  //   "-" +
  //   tempDate.getDate();
  // ===========================================[For the premium content]==============================================
  const email = localStorage.getItem("userEmail");
  useEffect(() => {
    const getData = async () => {
      const user = localStorage.getItem("userEmail");
      const email = user;

      const res = await fetch(SERVER_URL + "/get_user_data", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
        }),
      });

      const data = await res.json();
      setUserData(data);
      setUserId(data._id);
      // console.log(data._id);
    };
    getData();
  }, [email]);

  //=========================================[Fetching Token]========================================
  useEffect(() => {
    const fetchToken = async () => {
      const user = localStorage.getItem("user_id")?.replace(/"/g, "");
      const user_id = user;
      const res = await fetch(SERVER_URL + "/tokenData", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id,
        }),
      });
      const data = await res.json();
      // console.log(data);
      setTokenData(data);
      // console.log("Check whats comming - " + data.user_id);
      // console.log("Check whats comming - " + data.user_id);
    };
    fetchToken();
  }, [email]);
  // ===========================================[Check Token]==============================================

  const presentToken = localStorage.getItem("userToken")?.replace(/"/g, "");
  // console.log(tokenData[0]?.token);
  async function validateToken() {
    const check = await bcrypt.compare(tokenData.token, presentToken);
    if (check) {
      // console.log("True");
      setAuth("true");
    } else {
      // console.log("False");
      setAuth("false");
    }
  }

  validateToken();

  return (
    <section className="blog_section">
      <div className="container">
        <h2 className="page_title">Blogs </h2>
        {/* ---------------------------------[Top Left Main Blog]-------------------------------------- */}

        <div className="row">
          {topBlogData.slice(0, 1).map((items, index) => {
            return (
              <div
                className={`col-md-12 col-lg-6 ${
                  auth === "false" &&
                  items.blog_type !== "free" &&
                  "premiumImage"
                } `}
              >
                <div className="cover_blog_img">
                  <img
                    src={SERVER_URL + "/blog_img/" + items.blog_pic}
                    alt=""
                    className="img-fluid img-cover"
                  />
                  {auth === "false" && items.blog_type !== "free" && (
                    <div class="overlayPremiumTop">
                      <div class="icon">
                        <i class="fa fa-lock"></i>
                      </div>
                    </div>
                  )}
                </div>
                <p className="publish_info_title">
                  {dateChange(items.current_date)}

                  {/* Jan 19/2023 3 min read | Latest Threats */}
                </p>
                {items.blog_type === "premium" && auth === "true" ? (
                  <Link to={`/premium-blog-details/${items._id}`}>
                    <h4 className="blog_title">{truncate(items.title, 70)}</h4>
                  </Link>
                ) : items.blog_type === "free" ? (
                  <Link to={`/blog-details/${items._id}`}>
                    <h4 className="blog_title">{truncate(items.title, 70)}</h4>
                  </Link>
                ) : (
                  <Link to={"/blogs"}>
                    <h4 className="blog_title">
                      {truncate(items.title, 70)} &nbsp;
                      <span className="premium_lock">
                        <i className="fa fa-lock"> </i>
                      </span>
                    </h4>
                  </Link>
                )}
                <p className="publisher_name">
                  - {items.writer_name}
                  {/* John Deo */}
                </p>
              </div>
            );
          })}
          {/* ---------------------------------[Merged Top Right Blogs]-------------------------------------- */}

          <div className="col-md-12 col-lg-6">
            {mergedBlogs.slice(1, 5).map((items, index) => {
              return (
                <>
                  <div
                    className={`row ${
                      auth === "false" &&
                      items.blog_type !== "free" &&
                      "premiumImage"
                    } `}
                  >
                    <div className={`col-md-4  `}>
                      <div className="blog_cover_img">
                        <img
                          src={SERVER_URL + "/blog_img/" + items.blog_pic}
                          alt=""
                          className="img-fluid img-side-cover"
                        />
                        {auth === "false" && items.blog_type !== "free" && (
                          <div class="overlayPremium">
                            <div class="icon">
                              <i class="fa fa-lock"></i>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-8">
                      <p className="publish_info_title">
                        {dateChange(items.current_date)}
                        {/* Jan 19/2023 3 min read | Latest Threats */}
                      </p>
                      {items.blog_type === "premium" && auth === "true" ? (
                        <Link to={`/premium-blog-details/${items._id}`}>
                          <h4 className="blog_title">
                            {truncate(items.title, 70)}
                          </h4>
                        </Link>
                      ) : items.blog_type === "free" ? (
                        <Link to={`/blog-details/${items._id}`}>
                          <h4 className="blog_title">
                            {truncate(items.title, 70)}
                          </h4>
                        </Link>
                      ) : (
                        <Link to={"/blogs"}>
                          <h4 className="blog_title">
                            {truncate(items.title, 70)} &nbsp;
                            <span className="premium_lock">
                              <i className="fa fa-lock"> </i>
                            </span>
                          </h4>
                        </Link>
                      )}
                      <p className="publisher_name">
                        - {items.writer_name}
                        {/* John Deo */}
                      </p>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>

        {/* ---------------------------------[Categories Including Premium Tab Head]-------------------------------------- */}

        <div className="row">
          <div className="col-md-12">
            <form
              action=""
              method="GET"
              className="search blog_search_box"
              autoComplete="off"
            >
              <i className="fa fa-search"></i>
              <input
                type="text"
                name="search"
                className="form-control"
                placeholder="Blogs, Research, guides, interviews"
              />
              <button type="submit" className="btn btn-primary search_btn">
                Search
              </button>
            </form>
          </div>
          <div className="col-md-12">
            <div className="tabbable-panel blog_tabbable-panel">
              <div className="tabbable-line">
                <ul className="nav nav-tabs ">
                  <li className="nav-item">
                    <a
                      href="#tab_default_1"
                      data-bs-toggle="tab"
                      className="nav-link active"
                    >
                      All Categories
                    </a>
                  </li>
                  <li
                    className="nav-item"
                    onClick={() => {
                      setPremiumType("premium");
                    }}
                  >
                    <a
                      href="#tab_default_2"
                      data-bs-toggle="tab"
                      className="nav-link"
                    >
                      Premium
                    </a>
                  </li>
                  {category.map((get_cat, index) => {
                    return (
                      <li className="nav-item">
                        <a
                          href={`#tab_default_${index + 3}`}
                          data-bs-toggle="tab"
                          className="nav-link"
                          onClick={() => checkCategory(get_cat.category_name)}
                        >
                          {_.startCase(get_cat.category_name)}
                        </a>
                      </li>
                    );
                  })}
                </ul>
                {/* ---------------------------------[Tab for All Blogs]-------------------------------------- */}

                <div className="tab-content">
                  <div className="tab-pane active" id="tab_default_1">
                    <div className="row">
                      {blogData.map((items, key) => {
                        return (
                          <div
                            className={`col-md-6 col-lg-4 ${
                              auth === "false" &&
                              items.blog_type !== "free" &&
                              "premiumImage"
                            } `}
                          >
                            <div className={`blog_cover_img tab_blog_img`}>
                              <img
                                src={SERVER_URL + "/blog_img/" + items.blog_pic}
                                alt=""
                                className="img-fluid img-cat-cover"
                              />

                              {auth === "false" &&
                                items.blog_type !== "free" && (
                                  <div class="overlayPremium">
                                    <div class="icon">
                                      <i class="fa fa-lock"></i>
                                    </div>
                                  </div>
                                )}
                            </div>
                            <p className="publish_info_title">
                              {dateChange(items.current_date)}
                            </p>
                            {items.blog_type === "premium" &&
                            auth === "true" ? (
                              <Link to={`/premium-blog-details/${items._id}`}>
                                <h4 className="blog_title">
                                  {truncate(items.title, 70)}
                                </h4>
                              </Link>
                            ) : items.blog_type === "free" ? (
                              <Link to={`/blog-details/${items._id}`}>
                                <h4 className="blog_title">
                                  {truncate(items.title, 70)}
                                </h4>
                              </Link>
                            ) : (
                              <Link to={"/blogs"}>
                                <h4 className="blog_title">
                                  {truncate(items.title, 70)} &nbsp;
                                  <span className="premium_lock">
                                    <i className="fa fa-lock "> </i>
                                  </span>
                                </h4>
                              </Link>
                            )}
                            <p className="publisher_name">
                              - {items.writer_name}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  {/* ---------------------------------[Tab for Premium Blogs]-------------------------------------- */}

                  <div className="tab-pane premiumTab" id="tab_default_2">
                    {/* {auth === "false" && (
                      <div className="head align-center mb-2 text-center">
                        Get access to premium content on Login
                      </div>
                    )} */}
                    <div className="row">
                      {premiumFilter.map((items, key) => {
                        return (
                          <div
                            className={`col-md-6 col-lg-4 ${
                              auth === "false" &&
                              items.blog_type !== "free" &&
                              "premiumImage"
                            } `}
                          >
                            <div className={`blog_cover_img tab_blog_img `}>
                              <img
                                src={SERVER_URL + "/blog_img/" + items.blog_pic}
                                alt=""
                                className="img-fluid img-cat-cover"
                              />
                              {auth === "false" &&
                                items.blog_type !== "free" && (
                                  <div class="overlayPremium">
                                    <div class="icon">
                                      <i class="fa fa-lock"></i>
                                    </div>
                                  </div>
                                )}
                            </div>
                            <p className="publish_info_title">
                              {dateChange(items.current_date)}
                            </p>
                            {auth === "true" ? (
                              <Link to={`/premium-blog-details/${items._id}`}>
                                <h4 className="blog_title">
                                  {truncate(items.title, 70)}
                                </h4>
                              </Link>
                            ) : (
                              <Link to={"/blogs"}>
                                <h4 className="blog_title">
                                  {truncate(items.title, 70)}&nbsp;
                                  <span className="premium_lock">
                                    <i className="fa fa-lock "> </i>
                                  </span>
                                </h4>
                              </Link>
                            )}
                            <p className="publisher_name">
                              - {items.writer_name}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  {/* ---------------------------------[Tab For Categorised Blogs]-------------------------------------- */}

                  {category.map((size, index) => {
                    return (
                      <div className="tab-pane" id={`tab_default_${index + 3}`}>
                        <div className="row">
                          {blogFilter.map((items, index) => {
                            return (
                              <div
                                className={`col-md-6 col-lg-4 ${
                                  auth === "false" &&
                                  items.blog_type !== "free" &&
                                  "premiumImage"
                                } `}
                              >
                                <div className="blog_cover_img tab_blog_img">
                                  <img
                                    src={
                                      SERVER_URL + "/blog_img/" + items.blog_pic
                                    }
                                    alt=""
                                    className="img-fluid img-cat-cover"
                                  />
                                  {auth === "false" &&
                                    items.blog_type !== "free" && (
                                      <div class="overlayPremium">
                                        <div class="icon">
                                          <i class="fa fa-lock"></i>
                                        </div>
                                      </div>
                                    )}
                                </div>
                                <p className="publish_info_title">
                                  {dateChange(items.current_date)}
                                </p>
                                {items.blog_type === "premium" &&
                                auth === "true" ? (
                                  <Link
                                    to={`/premium-blog-details/${items._id}`}
                                  >
                                    <h4 className="blog_title">
                                      {truncate(items.title, 70)}
                                    </h4>
                                  </Link>
                                ) : items.blog_type === "free" ? (
                                  <Link to={`/blog-details/${items._id}`}>
                                    <h4 className="blog_title">
                                      {truncate(items.title, 70)}
                                    </h4>
                                  </Link>
                                ) : (
                                  <Link to={"/blogs"}>
                                    <h4 className="blog_title">
                                      {truncate(items.title, 70)} &nbsp;
                                      <span className="premium_lock">
                                        <i className="fa fa-lock "> </i>
                                      </span>
                                    </h4>
                                  </Link>
                                )}
                                <p className="publisher_name">
                                  - {items.writer_name}
                                </p>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}

                  {/* <div className="tab-pane" id="tab_default_4">
                    <div className="row">
                      {array_blogs.map((key) => {
                        return (
                          <div className="col-md-6 col-lg-4">
                            <div className="blog_cover_img tab_blog_img">
                              <img
                                src="https://themeger.shop/html/katen/html/images/posts/editor-lg.jpg"
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                            <p className="publish_info_title">
                              Jan 19/2023 3 min read | Latest Threats
                            </p>
                            <Link to={`/blog-details/${key}`}>
                              <h4 className="blog_title">
                                Lorem Ipsum is simply dummy text of the printing
                                and typesetting industry. Lorem Ipsum has been
                              </h4>
                            </Link>
                            <p className="publisher_name">- John Deo</p>
                          </div>
                        );
                      })}
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Blog;
