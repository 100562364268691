import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Moment from "react-moment";
import moment from "moment";
const Event = () => {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  let upcomingArray = [1, 2, 3, 4];
  const navigate = useNavigate();
  const [eventData, setEventData] = useState([]);
  const [dates, setDates] = useState([]);
  // console.log(dates);
  function truncate(str, n) {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  }
  function dateChange(str) {
    let date = new Date(str);
    return date.toDateString();
  }
  function monthChange(str) {
    let month = new Date(str);
    let newMonth = month.getMonth();
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return months[newMonth];
  }
  // function timeChange(str) {
  //   // let time = new Date(str);
  //   // let newTime = time.getTime();
  //   // return newTime;
  //   // const str = moment();
  //   const time = moment(str, "HH:mm");
  //   return time.toString().slice(16);
  // }

  function onlyDate(str) {
    let date = new Date(str);
    let newDate = date.getDate();
    return newDate;
  }

  useEffect(() => {
    getEventData();
  }, []);
  const getEventData = async () => {
    let result = await fetch(SERVER_URL + "/eventData", {
      headers: {
        authorization: JSON.parse(localStorage.getItem("token")),
      },
    });
    result = await result.json();
    setEventData(result);
  };

  const now = Date.now();
  const filteredEvents = eventData.filter((event) => {
    const eventDate = new Date(event.current_date).getTime();
    return eventDate > now;
  });

  const pastEvents = eventData.filter((event) => {
    const eventDate = new Date(event.current_date).getTime();
    return eventDate < now;
  });

  return (
    <section className="event_section">
      <div className="container">
        <h2 className="page_title">Events </h2>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="page-event">
              <div className="container">
                <div className="upcoming-sec">
                  <div className="heading">Upcoming Events</div>
                </div>
                {filteredEvents.map((items, index) => {
                  let rnd = Math.round(Math.random() * 31);
                  return (
                    <div className="upcoming-event-list">
                      <div className="event-block">
                        <div className="row align-items-center">
                          <div className="col-lg-2 sec-1">
                            <table>
                              <tr>
                                <td>
                                  <div className="month">
                                    {/* {months[items.current_date.slice(6, 7) - 1]} */}
                                    {monthChange(items.current_date)}
                                  </div>
                                  <div className="month-date-devider"></div>
                                  <div className="date">
                                    {onlyDate(items.current_date)}
                                    {/* {rnd == 0 ? 1 : rnd} */}
                                    {/* {items.current_date.slice(8)} */}
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </div>
                          <div className="col-lg-5 sec-2">
                            <img
                              src={SERVER_URL + "/event_img/" + items.event_pic}
                              className="img-fluid img-event"
                            />
                          </div>
                          <div className="col-lg-5 sec-3">
                            <div className="title">
                              {/* DASTAAN - a tribute to Rekha */}
                              {/* Lorem Ipsum - Dummy text */}
                              {items.title}
                            </div>
                            <div className="venue">
                              <table>
                                <tr>
                                  <td>
                                    <i className="fa fa-map-marker"></i>
                                  </td>
                                  <td>
                                    <div>
                                      {/* Indore M.P */}
                                      {items.location}
                                    </div>
                                    {/* <div className="dim-color">
                                      <a href="#" target="blank">
                                        Get Directions
                                      </a>
                                    </div> */}
                                  </td>
                                </tr>
                              </table>
                            </div>
                            <div className="time">
                              <table>
                                <tr>
                                  <td>
                                    <i className="fa fa-clock-o"></i>
                                  </td>
                                  <td>
                                    <div>
                                      {/* {items.current_date} at {items.time} */}
                                      {dateChange(items.current_date)} at{" "}
                                      {items.time}
                                      {/* Saturday, Jan 31 , 2023 at 5:30 PM */}
                                    </div>
                                    <div
                                      data-livestamp="1517054400"
                                      className="dim-color"
                                    ></div>
                                  </td>
                                </tr>
                              </table>
                            </div>
                            <div className="sort-story">
                              {/* "Contrary to popular belief, Lorem Ipsum is not
                              simply random text. It has roots in a piece of
                              classical Latin literature from 45 BC, making it
                              over 2000 years old. "  */}
                              {/* {truncate(items.description, 150)} */}
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: items.description,
                                }}
                              />{" "}
                              - {items.writer_name}
                              {/* John Deo */}
                            </div>
                            {/* <div className="group-of-btn">
                              <a
                                href="#"
                                target="blank"
                                className="btn book-ticket"
                              >
                                Read more
                              </a>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <h2 className="page_title"> </h2>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="page-event">
              <div className="container">
                <div className="upcoming-sec">
                  <div className="heading">Past Events</div>
                </div>
                {pastEvents.map((items, index) => {
                  let rnd = Math.round(Math.random() * 31);
                  return (
                    <div className="upcoming-event-list">
                      <div className="event-block">
                        <div className="row align-items-center">
                          <div className="col-lg-2 sec-1">
                            <table>
                              <tr>
                                <td>
                                  <div className="month">
                                    {/* {months[items.current_date.slice(6, 7) - 1]} */}
                                    {monthChange(items.current_date)}
                                  </div>
                                  <div className="month-date-devider"></div>
                                  <div className="date">
                                    {onlyDate(items.current_date)}
                                    {/* {rnd == 0 ? 1 : rnd} */}
                                    {/* {items.current_date.slice(8)} */}
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </div>
                          <div className="col-lg-5 sec-2">
                            <img
                              src={SERVER_URL + "/event_img/" + items.event_pic}
                              className="img-fluid img-event"
                            />
                          </div>
                          <div className="col-lg-5 sec-3">
                            <div className="title">
                              {/* DASTAAN - a tribute to Rekha */}
                              {/* Lorem Ipsum - Dummy text */}
                              {items.title}
                            </div>
                            <div className="venue">
                              <table>
                                <tr>
                                  <td>
                                    <i className="fa fa-map-marker"></i>
                                  </td>
                                  <td>
                                    <div>
                                      {/* Indore M.P */}
                                      {items.location}
                                    </div>
                                    <div className="dim-color">
                                      <a href="#" target="blank">
                                        Get Directions
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                              </table>
                            </div>
                            <div className="time">
                              <table>
                                <tr>
                                  <td>
                                    <i className="fa fa-clock-o"></i>
                                  </td>
                                  <td>
                                    <div>
                                      {/* {items.current_date} at {items.time} */}
                                      {dateChange(items.current_date)} at{" "}
                                      {items.time}
                                      {/* Saturday, Jan 31 , 2023 at 5:30 PM */}
                                    </div>
                                    <div
                                      data-livestamp="1517054400"
                                      className="dim-color"
                                    ></div>
                                  </td>
                                </tr>
                              </table>
                            </div>
                            <div className="sort-story">
                              {/* "Contrary to popular belief, Lorem Ipsum is not
                              simply random text. It has roots in a piece of
                              classical Latin literature from 45 BC, making it
                              over 2000 years old. " */}
                              {/* {truncate(items.description, 150)} -{" "}
                              {items.writer_name} */}
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: items.description,
                                }}
                              />{" "}
                              - {items.writer_name}
                              {/* John Deo */}
                            </div>
                            {/* <div className="group-of-btn">
                              <a
                                href="#"
                                target="blank"
                                className="btn book-ticket"
                              >
                                Read more
                              </a>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Event;
