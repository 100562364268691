import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import ParticleBackground from "react-particle-backgrounds";

const Login = () => {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const [errMsg, setErrMsg] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const auth = localStorage.getItem("admin");
    if (auth) {
      navigate("/profile");
    }
  }, []);

  const handleLogin = async () => {
    let result = await fetch(SERVER_URL + "/login", {
      method: "post",
      body: JSON.stringify({ email, password }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    result = await result.json();
    console.warn(result);
    if (result.auth) {
      // localStorage.setItem("logged_in", "true");
      localStorage.setItem("email", email);
      localStorage.setItem("admin", JSON.stringify(result.admin));
      localStorage.setItem("token", JSON.stringify(result.auth));
      navigate("/profile");
      // To load because other jquery is not running without it
      window.location.reload(false);
    } else {
      // alert("Please enter correct details");
      setErrMsg(
        <span style={{ color: "red" }}>Please enter correct details !</span>
      );
    }
  };

  const settings = {
    canvas: {
      canvasFillSpace: true,
      width: 200,
      height: 200,
      useBouncyWalls: false,
    },
    particle: {
      particleCount: 60,
      color: "#98D2EB",
      minSize: 2,
      maxSize: 5,
    },
    velocity: {
      directionAngle: 0,
      directionAngleVariance: 360,
      minSpeed: 1,
      maxSpeed: 3,
    },
    opacity: {
      minOpacity: 0,
      maxOpacity: 0.5,
      opacityTransitionTime: 3000,
    },
  };
  return (
    <div class="align-items-center auth">
      <ParticleBackground
        style={{
          position: "absolute",
          top: 0,
          overflow: "hidden",
          bottom: 0,
          left: 0,
          right: 0,
        }}
        className="particleBackground"
        settings={settings}
      />
      <div class="card col-md-5 mx-auto">
        <div class="card-body px-5 py-5">
          <h3 class="card-title text-center mb-3">LOGIN</h3>
          <div className="pb-2 mb-4 text-center">{errMsg}</div>
          <form>
            <div class="form-group">
              <label>Email *</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </div>
            <div class="form-group">
              <label>Password *</label>
              <input
                type="password"
                className="form-control"
                placeholder="Enter Password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
            </div>

            <div class="text-center">
              <button
                onClick={handleLogin}
                className="btn appButton"
                type="button"
              >
                Login
              </button>
            </div>

            <p class="sign-up">
              <Link to="/forgotpass">Forgot Password ?</Link>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
