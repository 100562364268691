import React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

const RemoveCategory = () => {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const [category, setCategory] = useState([]);
  const [paginationData, setPaginationData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const getCategoryData = async () => {
    let result = await fetch(SERVER_URL + "/category", {
      headers: {
        authorization: JSON.parse(localStorage.getItem("token")),
      },
    });
    result = await result.json();
    setCategory(result);
    setPaginationData(_(result).slice(0).take(pageSize).value());
  };

  useEffect(() => {
    getCategoryData();
  }, []);

  const deleteCategory = async (id) => {
    console.log(id);
    const confirmation = window.confirm(
      "Are You Sure ? You want to Delete This Category !"
    );
    if (confirmation) {
      const result = await fetch(`${SERVER_URL}/category/${id}`, {
        method: "Delete",
      });
      const data = await result.json();
      if (data.status === 422) {
        window.alert(data.message);
      } else if (result.ok) {
        getCategoryData();
      }
    }
  };

  const searchHandle = async (e) => {
    let key = e.target.value;
    // console.log(key)
    if (key) {
      let result = await fetch(`${SERVER_URL}/searchCategory/${key}`);
      result = await result.json();
      if (result) {
        // console.log(result);
        // Commented out category search
        // setCategorySearch(result)
        setCategory(result);
        setPaginationData(_(result).slice(0).take(pageSize).value());
      }
    } else {
      getCategoryData();
    }
  };

  const pageSize = 10;
  const pageCount = category ? Math.ceil(category.length / pageSize) : 0;
  // console.log(pageCount);
  // if (pageCount === 0) {
  //   return null;
  // }
  const pages = _.range(1, pageCount + 1);
  function pagination(pageNo) {
    setCurrentPage(pageNo);
    const startIndex = (pageNo - 1) * pageSize;
    const paginationData = _(category).slice(startIndex).take(pageSize).value();
    setPaginationData(paginationData);
  }

  return (
    <div>
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4 pb-4">CATEGORY LIST</h4>

            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Search Category"
                onChange={searchHandle}
              />
            </div>
            <div className="form-group">
              <div className="table-responsive">
                <table className="table table-bordered table-dark">
                  <thead>
                    <tr>
                      <th>S. No.</th>
                      <th>Name</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paginationData.length > 0 ? (
                      paginationData.map((item, index) => (
                        <tr key={item._id}>
                          <td>{index + 1}</td>
                          <td>{item.category_name.toUpperCase()}</td>
                          <td>
                            <button
                              onClick={() => deleteCategory(item._id)}
                              className="btn btn-danger btnDel"
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr class="center">
                        <td colSpan="3">No Result Found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="bg_pageNum">
                <nav className="d-flex justify-content-center">
                  <ul className="pagination">
                    {pages.map((page) => {
                      return (
                        <div>
                          <li
                            className={`page-item ${
                              page === currentPage ? "active" : ""
                            }
                          `}
                          >
                            {/* {page} */}
                            <button
                              className="page-link"
                              onClick={() => pagination(page)}
                            >
                              {page}
                            </button>
                          </li>
                        </div>
                      );
                    })}

                    {/* <li className="page-link">1</li> */}
                  </ul>
                </nav>
                <div className="d-flex justify-content-center fnt">
                  <p className="ln-ht">Total Rows - {category.length}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RemoveCategory;
