import React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const AddCategory = () => {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const navigate = useNavigate();
  const [category_name, setCategory_name] = useState("");
  const [error, setError] = useState(false);
  const [reqMsg, setReqMsg] = useState(
    <span style={{ color: "red", fontSize: "0.875rem" }}>Field Required *</span>
  );
  const [saved, setSaved] = useState("");
  // -------------------Table Logic----------------------------------------------------------
  const [categorySearch, setCategorySearch] = useState([]);

  // ----------------------------------------------------------------------------------------
  const addcategory = async () => {
    if (!category_name) {
      setError(true);
      return false;
    }

    const adminId = JSON.parse(localStorage.getItem("admin"))._id;
    const result = await fetch(SERVER_URL + "/add-category", {
      method: "post",
      body: JSON.stringify({ category_name, adminId }),
      headers: {
        "Content-type": "application/json",
      },
    });
    const data = await result.json();
    console.log(data);
    if (data.status === 422 || !data) {
      console.log(data.status);
      window.alert(data.error + " Invalid Information");
    } else if (result.ok) {
      setSaved(<span style={{ color: "green" }}>Category Saved</span>);
      // document.getElementById("reset").reset();
      setCategory_name("");
      setTimeout(() => {
        setSaved("");
      }, 2000);
      // window.alert("Category Added");
    }
  };

  return (
    <div>
      <div class="col-md-6 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4 pb-4">ADD CATEGORY</h4>
            <div className="text-center">{saved}</div>
            <div className="row">
              <div className="col-md-12">
                {/* <form id="reset"> */}
                <div className="form-group mt-2">
                  <label>Add Category</label>
                  <input
                    type="text"
                    placeholder="Enter Category Name"
                    className="form-control"
                    value={category_name}
                    onChange={(e) => {
                      setCategory_name(e.target.value);
                    }}
                  />
                  {error && !category_name && reqMsg}
                  <div className="mt-4">
                    <button onClick={addcategory} className="btn appButton">
                      Add Category
                    </button>
                  </div>
                </div>
                {/* </form> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCategory;
