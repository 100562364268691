import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
const NavExample = () => {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const auth = localStorage.getItem("admin");
  const navigate = useNavigate();
  const logout = () => {
    // localStorage.clear();
    localStorage.removeItem("token");
    localStorage.removeItem("admin");
    localStorage.removeItem("email");
    navigate("/");
  };

  const [storeData, setStoreData] = useState("");
  useEffect(() => {
    const postData = async () => {
      const user = localStorage.getItem("email");
      const email = user;

      const res = await fetch(SERVER_URL + "/get_admin_data", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
        }),
      });

      const data = await res.json();
      setStoreData(data);
      // console.log(data);
    };
    postData();
  }, [auth]);
  if (auth) {
    return (
      <div>
        {/* NavBar */}

        <nav class="navbar p-0 fixed-top d-flex flex-row">
          <div class="navbar-brand-wrapper d-flex d-lg-none align-items-center justify-content-center">
            <a
              class="navbar-brand brand-logo-mini"
              href="/profile"
              style={{ color: "#fff", textDecoration: "none" }}
            >
              {/* <img src="assets/images/logo-mini.svg" alt="logo" /> */}
              <h5>LOGO</h5>
            </a>
          </div>
          <div class="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
            <button
              class="navbar-toggler navbar-toggler align-self-center"
              type="button"
              data-toggle="minimize"
            >
              <span class="mdi mdi-menu"></span>
            </button>
            <ul class="navbar-nav w-100">
              <li class="nav-item w-100">
                {/* <form class="nav-link mt-2 mt-md-0 d-none d-lg-flex search">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search products"
                />
              </form> */}
              </li>
            </ul>
            <ul class="navbar-nav navbar-nav-right">
              <li class="nav-item dropdown">
                <a
                  class="nav-link"
                  id="profileDropdown"
                  href={"#"}
                  data-toggle="dropdown"
                >
                  <div class="navbar-profile">
                    {/* With create-react-app there is public folder (with index.html...). 
                  If you place your "myImage.png" there, say under img sub-folder, then you can access them through:
                  <img src={window.location.origin + '/img/myImage.png'} />
                  This should be marked as the correct answer, because it is actually reactjs solution for all
                   types of paths of any files – Sami */}
                    <img
                      class="img-xs rounded-circle"
                      src={SERVER_URL + "/admin_img/" + storeData.pic}
                      alt=""
                    />
                    <p class="mb-0 d-none d-sm-block navbar-profile-name">
                      {storeData.userName}
                    </p>
                    <i class="mdi mdi-menu-down d-none d-sm-block"></i>
                  </div>
                </a>
                <div
                  class="dropdown-menu dropdown-menu-right navbar-dropdown preview-list"
                  aria-labelledby="profileDropdown"
                >
                  <h6 class="p-3 mb-0">Profile</h6>
                  <div class="dropdown-divider"></div>
                  <Link to={"/profile"} style={{ textDecoration: "none" }}>
                    <a class="dropdown-item preview-item">
                      <div class="preview-thumbnail">
                        <div class="preview-icon bg-dark rounded-circle">
                          <i class="mdi mdi-account-circle text-success"></i>
                        </div>
                      </div>
                      <div class="preview-item-content">
                        <p class="preview-subject mb-1"> Account </p>
                      </div>
                    </a>
                  </Link>
                  <div class="dropdown-divider"></div>
                  <a onClick={logout} style={{ textDecoration: "none" }}>
                    <a class="dropdown-item preview-item">
                      <div class="preview-thumbnail">
                        <div class="preview-icon bg-dark rounded-circle">
                          <i class="mdi mdi-logout text-danger"></i>
                        </div>
                      </div>
                      <div class="preview-item-content">
                        <p class="preview-subject mb-1">
                          Logout ({storeData.userName})
                        </p>
                      </div>
                    </a>
                  </a>
                </div>
              </li>
            </ul>
            <button
              class="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
              type="button"
              data-toggle="offcanvas"
            >
              <span class="mdi mdi-format-line-spacing"></span>
            </button>
          </div>
        </nav>
      </div>
    );
  }
};
export default NavExample;
