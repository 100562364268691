import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
// import "bootstrap/dist/css/bootstrap.min.css";
const AdminProfile = () => {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const navigate = useNavigate();
  const auth = localStorage.getItem("admin");
  if (!auth) {
    navigate("/nopage");
  }
  const [error, setError] = useState(false);
  const [reqMsg, setReqMsg] = useState(
    <span style={{ color: "red", fontSize: "0.875rem" }}>Field Required *</span>
  );
  const [saved, setSaved] = useState("");
  const [storeData, setStoreData] = useState("");
  const [edit, setEdit] = useState(false);
  const [new_image, setNewImage] = useState("");
  const [user, setUser] = useState({
    name: "",
    email: "",
  });

  let name, value;
  const handleFormData = (e) => {
    // console.log(e);
    name = e.target.name;
    value = e.target.value;

    setUser({ ...user, [name]: value });
  };

  const handleImageData = (e) => {
    // console.log(e);
    setNewImage(e.target.files[0]);
    // console.log(e.target.files[0]);
  };

  function editProfile(e) {
    e.preventDefault();
    setEdit(true);
  }
  function cancel() {
    setEdit(false);
  }

  useEffect(() => {
    const postData = async () => {
      const user = localStorage.getItem("email");
      const email = user;

      const res = await fetch(SERVER_URL + "/get_admin_data", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
        }),
      });

      const data = await res.json();
      setStoreData(data);
    };
    postData();
  }, [1]);

  const updateData = async () => {
    const email = localStorage.getItem("email");
    if (!user.name) {
      setError(true);
      return false;
    }
    const { name, password } = user;
    const formData = new FormData();
    formData.append("pic", new_image || ("pic", storeData.pic));
    formData.append("name", name);
    formData.append("email", email);
    formData.append("password", password);

    const res = await fetch(SERVER_URL + "/update_admin_data", {
      method: "POST",
      body: formData,
    });
    const data = await res.json();

    if (res.ok) {
      // window.alert("Updated");
      setEdit(false);
      setSaved(<span style={{ color: "green" }}>Profile Saved</span>);
      window.location.reload(false);
      navigate("/profile");
    } else {
      console.log(data.error);
    }
  };

  return (
    <>
      <div className="bg_main">
        <div class="col-md-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <h3
                class="card-title text-center"
                style={{ textTransform: "uppercase" }}
              >
                Welcome {storeData.userName}
              </h3>
              <div className="row">
                <div className="col-md-6 border-right">
                  <div className="d-flex flex-column align-items-center text-center p-3 py-4">
                    {
                      <img
                        src={SERVER_URL + "/admin_img/" + storeData.pic}
                        className="rounded-circle mt-4"
                        height="150px"
                        width="150px"
                      />
                    }
                    <span className="font-weight-bold">
                      {storeData.userName}
                    </span>
                    <span className="font-weight-bold">{storeData.email}</span>
                    <span> </span>
                    {!edit && (
                      <div className="mt-4 text-center">
                        {/* <button
                          className="btn appButton"
                          type="button"
                          onClick={editProfile}
                        >
                          Edit Profile
                        </button>{" "} */}
                        <button
                          className="btn appButton"
                          type="button"
                          onClick={() => navigate("/changepass")}
                        >
                          Change Password
                        </button>
                      </div>
                    )}

                    {/* <div className="mt-4 text-center">
                      {edit && (
                        <button className="btn appButton" onClick={cancel}>
                          Cancel
                        </button>
                      )}
                    </div> */}
                  </div>
                </div>
                {!edit && (
                  <div className="col-md-6">
                    <div className="p-3 py-4">
                      <div className="d-flex justify-content-between align-items-center mb-2">
                        <h4 className="text-right">Update Profile</h4>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-12">
                          <label>Upload Image</label>
                          <form method="POST" encType="multipart/form-data">
                            <input
                              type="file"
                              className="form-control"
                              name="new_image"
                              onChange={handleImageData}
                            />
                          </form>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-12">
                          <label>Name</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Name"
                            name="name"
                            value={
                              user.name || (user.name = storeData.userName)
                            }
                            onChange={handleFormData}
                          />
                          {error && !user.name && reqMsg}
                        </div>
                      </div>
                      {/* <div className="row mt-3">
                    <div className="col-md-12"><label className="labels">Area</label><input type="text" className="form-control" placeholder="enter address line 2" value="" /></div>
                    <div className="col-md-12"><label className="labels">Email ID</label><input type="text" className="form-control" placeholder="enter email id" value="" /></div>
                    <div className="col-md-12"><label className="labels">Education</label><input type="text" className="form-control" placeholder="education" value="" /></div>
                  </div> */}

                      <div className="mt-5 pt-2 text-center">
                        <button
                          className="btn appButton"
                          type="button"
                          onClick={updateData}
                        >
                          Update
                        </button>{" "}
                        {saved}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminProfile;
