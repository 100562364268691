import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
// For Redux trial

const LoginUser = () => {
  const [errMsg, setErrMsg] = useState("");
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  // useEffect(() => {
  //   const auth = localStorage.getItem("admin");
  //   if (auth) {
  //     navigate("/");
  //   }
  // }, []);
  // For Redux trial
  const [saved, setSaved] = useState("");
  const [captchaErr, setCaptchaErr] = useState("");

  const [error, setError] = useState(false);
  const [verifyError, setVerifyError] = useState(false);
  const [reqMsg, setReqMsg] = useState(
    <span style={{ color: "red", fontSize: "0.775rem" }}>Field Required *</span>
  );
  const [genCaptcha, setGenCaptcha] = useState("");
  const [verify, setVerify] = useState("");
  const [check, setCheck] = useState("false");
  const handleLogin = async () => {
    if (!email || !password || !verify) {
      setError(true);
      generate();
      return false;
    }
    if (verify && verify !== genCaptcha) {
      // alert("Captcha Did Not Match");

      setCaptchaErr(
        <span style={{ color: "red" }}>Captcha did not match</span>
      );
      // setVerify("");
      setTimeout(() => {
        setCaptchaErr("");
      }, 2000);
      generate();
      return false;
    }
    let result = await fetch(SERVER_URL + "/userLogin", {
      method: "post",
      body: JSON.stringify({ email, password }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (verify === genCaptcha) {
      result = await result.json();
    }
    // console.warn(result);
    if (result.status === 422) {
      setVerifyError(true);
      // console.log(verifyError);
      setErrMsg(<span style={{ color: "red" }}>{result.error}</span>);
      setTimeout(() => {
        setErrMsg("");
      }, 2000);
      return false;
    }
    if (result.token && verify === genCaptcha) {
      localStorage.setItem("userEmail", email);
      localStorage.setItem("loggedIn", "true");
      localStorage.setItem("userToken", JSON.stringify(result.token));
      // localStorage.setItem("randToken", JSON.stringify(result.randToken));
      localStorage.setItem("user_id", JSON.stringify(result.user_id));
      // For Redux trial
      setSaved(
        <span style={{ color: "green", fontSize: "0.875rem" }}>
          Login Successful
        </span>
      );
      setTimeout(() => {
        navigate("/userprofile");
        setSaved("");
        window.location.reload(false);
      }, 3000);

      // To load because other jquery is not running without it
    } else if (!result.token) {
      // alert("Please enter correct details");
      document.getElementById("reset").reset();

      // setEmail("");
      // setPassword("");
      // setVerify("");
      if (verify !== genCaptcha) {
        // alert("Captcha Did Not Match");
        setCaptchaErr(
          <span style={{ color: "red" }}>Captcha Did Not Match</span>
        );
      }
      generate();
      setErrMsg(
        // <span style={{ color: "red" }}>Please enter correct details !</span>
        <span style={{ color: "red" }}>{result.error}</span>
      );
      setTimeout(() => {
        setErrMsg("");
      }, 2000);
    }
  };

  function generate() {
    var uniquechar = "";

    // const randomchar =
    //   "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!#$%&'*+-/=?^_`{|}~";
    const randomchar =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 1; i <= 6; i++) {
      uniquechar += randomchar.charAt(Math.random() * randomchar.length);
    }

    // Store generated input
    setGenCaptcha(uniquechar);
  }
  useEffect(() => {
    generate();
  }, []);
  const [rotation, setRotation] = useState(0);
  function rotate() {
    generate();
    setRotation(rotation + 360);
  }
  return (
    <form id="reset">
      {/* <h3 class="card-title text-center mb-3">LOGIN</h3> */}
      <div className="d-md-flex bgImg half">
        <div className="loginSide">
          <div className="loginSideImg"></div>
          {/* <div className="bg-text">
            <h3>Welcome To Brand Name</h3>
          </div> */}
        </div>
        <div className="content">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-md-12">
                <div class="auth">
                  <div className="log_card mx-auto">
                    <div className="text-center">{saved}</div>
                    <div className="text-center">{errMsg}</div>
                    <div className="text-center">{captchaErr}</div>

                    <h3>Login</h3>

                    <form>
                      <div class="form-group">
                        <label>Email *</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Email"
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                        />
                        {error && !email && reqMsg}
                      </div>
                      <div class="form-group">
                        <label>Password *</label>
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Enter Password"
                          onChange={(e) => setPassword(e.target.value)}
                          value={password}
                        />
                        {error && !password && reqMsg}
                      </div>
                      <div class="form-group">
                        <label>Verify *</label>
                        <input
                          type="text"
                          className="form-control"
                          name="verify"
                          id="verify"
                          onChange={(e) => setVerify(e.target.value)}
                          placeholder="Enter Captcha Here"
                          required
                        />
                        {error && !verify && reqMsg}
                      </div>
                      <div className="row">
                        <div class="form-group col-sm-4 captchaBox">
                          <label>
                            Captcha &nbsp;{" "}
                            <i
                              class="fa fa-refresh"
                              onClick={rotate}
                              style={{ transform: `rotate(${rotation}deg)` }}
                            />
                          </label>
                        </div>

                        <div class="form-group col-sm-8 captchaBox">
                          <input
                            type="text"
                            placeholder={genCaptcha}
                            disabled
                            className="form-control captcha"
                          />
                        </div>
                      </div>

                      {/* <div className="row">
                        <div class="form-group col-sm-6 ">
                          <div class="captchaBox">
                            <label>
                              Captcha &nbsp;{" "}
                              <i
                                class="fa fa-refresh fa-lg"
                                onClick={generate}
                              />
                            </label>

                            <input
                              type="text"
                              placeholder={genCaptcha}
                              disabled
                              className="form-control captcha"
                            />
                          </div>
                        </div>
                        <div class="form-group col">
                          <label>Verify *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="verify"
                            id="verify"
                            onChange={(e) => setVerify(e.target.value)}
                            placeholder="Enter Captcha"
                            required
                          />
                          {error && !verify && reqMsg}
                        </div>
                      </div> */}

                      <div class="text-center">
                        <button
                          onClick={handleLogin}
                          className="btn login_btn"
                          type="button"
                        >
                          Log in
                        </button>
                      </div>

                      {/* <div class="text-center">
                        <button
                          onClick={generate}
                          className="btn login_btn"
                          type="button"
                        >
                          Generate
                        </button>
                      </div> */}

                      <p class="log-in text-center mt-3">
                        <Link to="/forgotpassword" class="link">
                          Forgot Password ?
                        </Link>
                      </p>
                      {verifyError && (
                        <p class="log-in text-center mt-3">
                          <Link to="/newverifyemail" class="btn verifyBtn">
                            Verify Your Email First
                          </Link>
                        </p>
                      )}
                      <p class="log-in text-center mt-3">
                        Not have an account?{" "}
                        <Link to="/signup" class="link">
                          Sign Up
                        </Link>
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default LoginUser;
