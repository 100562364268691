import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import news_img from "../images/matrix-2503236__340.jpg";

export default function NewsDetail() {
  const params = useParams();
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const navigate = useNavigate();
  const [RESImage, setRESImage] = useState("");
  const [title, setTitle] = useState("");
  const [writer_name, setWriter_name] = useState("");
  const [description, setDescription] = useState("");
  const [current_date, setCurrent_date] = useState("");
  const [newsData, setNewsData] = useState({
    title: "",
    writer_name: "",
    description: "",
    current_date: "",
  });
  const getNewsData = async () => {
    // console.warn(params);
    if (params.id.length !== 24) {
      navigate("/news");
      return false;
    }
    let result = await fetch(`${SERVER_URL}/newsData/${params.id}`);
    result = await result.json();
    setTitle(result.title);
    setWriter_name(result.writer_name);
    setCurrent_date(result.current_date);
    setDescription(result.description);

    setRESImage(result.news_pic);
  };

  useEffect(() => {
    getNewsData();
  }, []);
  function truncate(str, n) {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  }
  function dateChange(str) {
    let date = new Date(str);
    return date.toDateString();
  }
  return (
    <>
      <section className="blog_section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-10">
              <p className="breadcrumb">
                <Link to="/">Home </Link> / <Link to="/news">News </Link> /{" "}
                {title}
                {/* Contrary to popular belief, Lorem Ipsum is not simply random
                text... */}
              </p>

              <h2 className="blog_detail_title">
                {title}
                {/* Contrary to popular belief, Lorem Ipsum is not simply random
                text. It has roots in a piece of classical -  */}
              </h2>
              <div className="publisher_box">
                <span className="blog_publish_details">
                  <i className="fa-regular fa-clock"></i>{" "}
                  {dateChange(current_date)}
                  {/* Wed 28 Fab 2022 */}
                </span>
              </div>
              <div className="blog_detail_cover_img">
                <img
                  src={SERVER_URL + "/news_img/" + RESImage}
                  //   src="https://media.istockphoto.com/id/1290904409/photo/abstract-digital-news-concept.jpg?b=1&s=170667a&w=0&k=20&c=WMVA8KKLRL3KbUtPCmw5RQf2frl995MtI1qEmsqKdII="
                  alt=""
                  className="img-fluid"
                />
              </div>
              <div className="blog_description">
                <h2 className="blog_sub_title">
                  {title}
                  {/* Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been */}
                </h2>
                {/* <p>{description}</p> */}
                <p>
                  <div dangerouslySetInnerHTML={{ __html: description }} />
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
