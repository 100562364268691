import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link, useLocation } from "react-router-dom";
import blog_img from "../images/security-5043368__340.jpg";

const PremiumBlogsDetails = () => {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const params = useParams();
  const navigate = useNavigate();
  // console.log(params);
  const [image, setImage] = useState("");
  const [category, setCategory] = useState([]);
  const [title, setTitle] = useState("");
  const [REScategory, setRESCategory] = useState("");
  const [RESblogType, setRESBlogType] = useState("");
  const [RESImage, setRESImage] = useState("");
  const [writer_name, setWriter_name] = useState("");
  const [description, setDescription] = useState("");
  const [current_date, setCurrent_date] = useState("");
  const [blogData, setBlogData] = useState({
    category: "",
    title: "",
    writer_name: "",
    description: "",
    current_date: "",
  });
  const getBlogData = async () => {
    // console.warn(params);
    if (params.id.length !== 24) {
      navigate("/blogs");
      return false;
    }
    let result = await fetch(`${SERVER_URL}/premiumBlogData/${params.id}`);
    result = await result.json();
    setBlogData(result);
    setTitle(result.title);
    setWriter_name(result.writer_name);
    setCurrent_date(result.current_date);
    setDescription(result.description);
    setRESCategory(result.category);
    setRESBlogType(result.blog_type);
    setRESImage(result.blog_pic);
  };

  useEffect(() => {
    getBlogData();
  }, []);

  function dateChange(str) {
    let date = new Date(str);
    return date.toDateString();
  }

  return (
    <>
      <section className="blog_section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-10">
              <p className="breadcrumb">
                <Link to="/">Home </Link> / <Link to="/blogs">Blogs </Link>{" "}
                &nbsp;/ {title}
                {/* Lorem Ipsum is simply dummy text of the printing... */}
              </p>

              <h2 className="blog_detail_title">
                {title}
                {/* Lorem Ipsum is simply dummy text of the printing and typesetting
                industry - {blogId} */}{" "}
              </h2>
              <div className="publisher_box">
                {/* <div className="publisher_img_box">
                  <img
                    className="img-fluid publisher_img"
                    src="https://cdn-icons-png.flaticon.com/512/149/149071.png"
                  />
                </div> */}
                <span className="blog_publisher_name">
                  {writer_name} /&nbsp;
                  {/* Publisher name  */}
                </span>
                <span className="blog_publish_details">
                  {dateChange(current_date)}
                  {/* Wed 26 Jan 2023 */}
                </span>
              </div>
              <div className="blog_detail_cover_img">
                <img
                  // src={window.location.origin + "/" + RESImage}
                  src={SERVER_URL + "/blog_img/" + RESImage}
                  alt=""
                  className="img-fluid"
                />
              </div>
              <div className="blog_description">
                {/* <h2 className="blog_sub_title"> */}
                {/* {title} */}
                {/* Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy */}
                {/* </h2> */}
                {/* <p>{description}</p> */}
                <p>
                  <div dangerouslySetInnerHTML={{ __html: description }} />
                </p>

                {/* {RESblogType === "free" ? (
                  <p>
                    <div dangerouslySetInnerHTML={{ __html: description }} />
                  </p>
                ) : (
                  <p>
                    <h3>Buy Premium To Read The Blog</h3>
                  </p>
                )} */}

                {/* <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldus PageMaker including versions of Lorem Ipsum.
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PremiumBlogsDetails;
