import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function News() {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  let newArray = [1, 2, 3, 4, 5, 6];
  let newArray1 = [1, 2, 3];

  const [newsData, setNewsData] = useState([]);
  const getNewsData = async () => {
    let result = await fetch(`${SERVER_URL}/newsData`);
    result = await result.json();
    setNewsData(result);
  };

  useEffect(() => {
    getNewsData();
  }, []);
  function truncate(str, n) {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  }
  function dateChange(str) {
    let date = new Date(str);
    return date.toDateString();
  }
  return (
    <section className="news_section">
      {/* <div className="page_cover_box">
        <h1 className="text-white text-center my-5">News</h1>
      </div> */}

      <div className="container">
        <h2 className="page_title">News </h2>
        <div className="row">
          <div className="col-lg-8 col-md-6">
            <div className="row ">
              {newsData.map((items, index) => {
                return (
                  <div className="col-lg-11 col-md-12 py-2">
                    <div className="news_box">
                      <div className="news_img_box">
                        <img
                          src={SERVER_URL + "/news_img/" + items.news_pic}
                          alt=""
                          className="img-fluid news_img img-news-cover"
                        />
                      </div>
                      <div className="news_content_box">
                        <p className="news_publish_info_title">
                          {dateChange(items.current_date)}
                          {/* Jan 19/2023 3 min read | Latest Threats */}
                        </p>
                        <Link to={"/news-description/" + items._id}>
                          <h4 className="news_title">
                            {truncate(items.title, 120)}
                            {/* There are many variations of passages of Lorem Ipsum
                            available, but the majority have suffered alteration */}
                          </h4>
                        </Link>

                        <Link
                          to={"/news-description/" + items._id}
                          className="button button--aylen button--round-l button--text-thick"
                        >
                          Read more
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="row">
              <div className="col-lg-12 col-md-12 py-2">
                <div className="p-3 border latest_news_box">
                  <div className="border-bottom mb-3 pb-3 px-2 d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                      <h6 className="latest_news_title text-capitalize font-weight-bold mb-0">
                        Latest News
                      </h6>
                    </div>
                  </div>
                  {newsData.slice(0, 5).map((items, index) => {
                    return (
                      <div className="mt-3 pb-3 border-bottom">
                        <Link
                          to={"/news-description/" + items._id}
                          className="text-dark"
                          style={{ fontWeight: 600, lineHeight: 1 }}
                        >
                          {truncate(items.title, 50)}
                          {/* Lorem Ipsum is simply dummy text of the printing. */}
                        </Link>
                        <div className="d-flex align-items-start justify-content-between mt-2">
                          <div>
                            <small className="text-secondary">
                              {/* {truncate(items.description, 70)} */}

                              {/* <div
                                dangerouslySetInnerHTML={{
                                  __html: truncate(items.description, 70),
                                }}
                              /> */}

                              {/* Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry... */}
                            </small>
                            <div className="new_publish_time d-flex align-items-baseline mt-1">
                              <i className="fa-solid fa-clock"></i>
                              <span>
                                &nbsp;
                                {/* {index + Math.round(Math.random() * 60)}
                                min ago */}
                                {dateChange(items.current_date)}
                              </span>
                            </div>
                          </div>
                          <img
                            className="img-fluid mr-3"
                            style={{ width: "110px" }}
                            src={SERVER_URL + "/news_img/" + items.news_pic}
                            alt=""
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default News;
