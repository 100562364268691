import React, { useState, useEffect } from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";
import _ from "lodash";
import moment from "moment";

function Header() {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const loggedIn = localStorage.getItem("loggedIn");
  const navigate = useNavigate();
  function logOut() {
    // localStorage.clear();
    localStorage.removeItem("user_id");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("userToken");
    localStorage.removeItem("loggedIn");
    localStorage.removeItem("randToken");
    navigate("/");
  }
  let subMenu;
  // const auth = localStorage.getItem("token");
  function menu_main(e) {
    const menu = document.getElementById("menu");
    if (!menu.classList.contains("active")) {
      return;
    }
    if (e.target.closest(".menu-item-has-children")) {
      const hasChildren = e.target.closest(".menu-item-has-children");
      showSubMenu(hasChildren);
    }
  }
  function showSubMenu(hasChildren) {
    const menu = document.getElementById("menu");
    subMenu = hasChildren.querySelector(".sub-menu");
    subMenu.classList.add("active");
    subMenu.style.animation = "slideLeft 0.5s ease forwards";
    const menuTitle =
      hasChildren.querySelector("i").parentNode.childNodes[0].textContent;
    menu.querySelector(".current-menu-title").innerHTML = menuTitle;
    menu.querySelector(".mobile-menu-head").classList.add("active");
  }

  function hideSubMenu() {
    const menu = document.getElementById("menu");
    subMenu.style.animation = "slideRight 0.5s ease forwards";
    setTimeout(() => {
      subMenu.classList.remove("active");
    }, 300);
    menu.querySelector(".current-menu-title").innerHTML = "";
    menu.querySelector(".mobile-menu-head").classList.remove("active");
  }
  function goBack() {
    hideSubMenu();
  }
  function mobileMenuTrigger() {
    toggleMenu();
  }

  function toggleMenu() {
    const menu = document.getElementById("menu");
    menu.classList.toggle("active");
    document.getElementById("menu-overlay").classList.toggle("active");
  }

  function menuOverlay() {
    toggleMenu();
  }
  function mobileMenuClose() {
    toggleMenu();
  }
  window.onresize = function () {
    const menu = document.getElementById("menu");
    if (this.innerWidth > 991) {
      if (menu.classList.contains("active")) {
        toggleMenu();
      }
    }
  };
  const [visibilityData, setVisibilityData] = useState([]);

  useEffect(() => {
    getvisibility();
  }, []);

  const getvisibility = async () => {
    let result = await fetch(SERVER_URL + "/visibility", {
      headers: {
        authorization: JSON.parse(localStorage.getItem("token")),
      },
    });
    result = await result.json();
    setVisibilityData(result);
  };
  const [tokenData, setTokenData] = useState([]);
  const [checkTime, setCheckTime] = useState(false);

  useEffect(() => {
    const fetchToken = async () => {
      const user = localStorage.getItem("user_id")?.replace(/"/g, "");
      const user_id = user;
      const res = await fetch(SERVER_URL + "/tokenData", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id,
        }),
      });
      const data = await res.json();
      // console.log(data);
      setTokenData(data);
      // console.log("Check whats comming - " + data.user_id);
      // console.log("Check whats comming - " + data.user_id);
    };
    fetchToken();
  }, [loggedIn]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentTime = moment().valueOf();
      if (tokenData.endTime <= currentTime) {
        setCheckTime(true);
      }
    }, 1000); // Check every second

    return () => clearInterval(intervalId);
  }, [tokenData]);

  useEffect(() => {
    if (checkTime) {
      logOut();
      // window.location.reload(false);
    }
  }, [checkTime]);

  // console.log("Current Time = " + currentTime);
  // console.log("EndTime = " + tokenData.endTime);
  return (
    <>
      <header className="header">
        <div className="container">
          <div className="v-center">
            <div className="header-item item-left">
              <div className="logo">
                {/* <a to="/">logo</a> */}
                <Link to="/">logo</Link>
              </div>
            </div>
            <div className="header-item item-center">
              <div
                className="menu-overlay"
                id="menu-overlay"
                onClick={menuOverlay}
              ></div>
              <nav className="menu" id="menu">
                <div className="mobile-menu-head">
                  <div className="go-back" id="go-back" onClick={goBack}>
                    <i className="fa fa-angle-left"></i>
                  </div>
                  <div className="current-menu-title"></div>
                  <div
                    className="mobile-menu-close"
                    id="mobile-menu-close"
                    onClick={mobileMenuClose}
                  >
                    &times;
                  </div>
                </div>
                <ul
                  className="menu-main mb-0"
                  id="menu-main"
                  onClick={(e) => menu_main(e)}
                >
                  <li className="menu-item-has-children first_list">
                    <a href="#">
                      Why Future Prime
                      <i className="fa fa-angle-down"></i>
                    </a>
                    <div className="sub-menu mega-menu mega-menu-column-4">
                      <div className="list-item text-center">
                        <a href="#">
                          <img
                            src="https://img.freepik.com/free-photo/male-technician-repairing-computer-motherboard-wooden-desk_23-2147923493.jpg?w=740&t=st=1671765056~exp=1671765656~hmac=4c8dffc9558819b4c21b126aaa394150f48adc3be9c8004c0b65d0420709ec00"
                            alt="Fashion"
                          />
                          <h4 className="title">Category 1</h4>
                        </a>
                      </div>
                      <div className="list-item text-center">
                        <a href="#">
                          <img
                            src="https://img.freepik.com/free-photo/male-technician-repairing-computer-motherboard-wooden-desk_23-2147923493.jpg?w=740&t=st=1671765056~exp=1671765656~hmac=4c8dffc9558819b4c21b126aaa394150f48adc3be9c8004c0b65d0420709ec00"
                            alt="Electronics"
                          />
                          <h4 className="title">Category 2</h4>
                        </a>
                      </div>
                      <div className="list-item text-center">
                        <a href="#">
                          <img
                            src="https://images.unsplash.com/photo-1571380401583-72ca84994796?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=764&q=80"
                            alt="Mobiles"
                          />
                          <h4 className="title">Category 3</h4>
                        </a>
                      </div>
                      <div className="list-item text-center">
                        <a href="#">
                          <img
                            src="https://images.unsplash.com/photo-1578643463396-0997cb5328c1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=736&q=80"
                            alt="Appliances"
                          />
                          <h4 className="title">Category 4</h4>
                        </a>
                      </div>
                    </div>
                  </li>
                  <li className="menu-item-has-children">
                    <a href="#">
                      Platform
                      <i className="fa fa-angle-down"></i>
                    </a>
                    <div className="sub-menu mega-menu mega-menu-column-4">
                      <div className="list-item">
                        <h4 className="title">Menu items</h4>
                        <ul>
                          <li>
                            <a href="#">sub menu items</a>
                          </li>
                          <li>
                            <a href="#">sub menu items</a>
                          </li>
                          <li>
                            <a href="#">sub menu items</a>
                          </li>
                          <li>
                            <a href="#">sub menu items</a>
                          </li>
                          <li>
                            <a href="#">sub menu items</a>
                          </li>
                        </ul>
                        <h4 className="title">Menu items</h4>
                        <ul>
                          <li>
                            <a href="#">sub menu items</a>
                          </li>
                          <li>
                            <a href="#">sub menu items</a>
                          </li>
                          <li>
                            <a href="#">sub menu items</a>
                          </li>
                        </ul>
                      </div>
                      <div className="list-item">
                        <h4 className="title">Menu items</h4>
                        <ul>
                          <li>
                            <a href="#">sub menu items</a>
                          </li>
                          <li>
                            <a href="#">sub menu items</a>
                          </li>
                          <li>
                            <a href="#">sub menu items</a>
                          </li>
                          <li>
                            <a href="#">sub menu items</a>
                          </li>
                        </ul>
                        <h4 className="title">Menu items</h4>
                        <ul>
                          <li>
                            <a href="#">sub menu items</a>
                          </li>
                          <li>
                            <a href="#">sub menu items</a>
                          </li>
                          <li>
                            <a href="#">sub menu items</a>
                          </li>
                          <li>
                            <a href="#">sub menu items</a>
                          </li>
                        </ul>
                      </div>
                      <div className="list-item">
                        <h4 className="title">Menu items</h4>
                        <ul>
                          <li>
                            <a href="#">sub menu items</a>
                          </li>
                          <li>
                            <a href="#">sub menu items</a>
                          </li>
                          <li>
                            <a href="#">sub menu items</a>
                          </li>
                          <li>
                            <a href="#">sub menu items</a>
                          </li>
                          <li>
                            <a href="#">sub menu items</a>
                          </li>
                          <li>
                            <a href="#">sub menu items</a>
                          </li>
                          <li>
                            <a href="#">sub menu items</a>
                          </li>
                          <li>
                            <a href="#">sub menu items</a>
                          </li>
                          <li>
                            <a href="#">sub menu items</a>
                          </li>
                        </ul>
                      </div>
                      <div className="list-item">
                        <img
                          src="https://images.unsplash.com/photo-1549497538-303791108f95?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=761&q=80"
                          alt="Chair"
                        />
                      </div>
                    </div>
                  </li>
                  {/* <li className="menu-item-has-children">
                    <a href="#">
                      Community
                      <i className="fas fa-angle-down"></i>
                    </a>
                    <div className="sub-menu single-column-menu">
                      <ul>
                        <li>
                          <a href="#">Standard Layout</a>
                        </li>
                        <li>
                          <a href="#">Grid Layout</a>
                        </li>
                        <li>
                          <a href="#">single Post Layout</a>
                        </li>
                      </ul>
                    </div>
                  </li> */}
                  <li className="menu-item-has-children">
                    <a href="#">
                      Resources
                      <i className="fas fa-angle-down"></i>
                    </a>
                    <div
                      className="sub-menu custom_mega_menu mega-menu mega-menu-column-4"
                      style={{ maxWidth: "500px" }}
                    >
                      <div
                        className="list-item"
                        style={{ width: "100%", flex: "0 0 50%" }}
                      >
                        <ul>
                          {visibilityData.map((items, index) => {
                            return items.page_visibility === "visible" ? (
                              <li>
                                <Link to={"/" + items.page}>
                                  {_.startCase(items.page)}
                                </Link>
                                <small>{items.page_subheading}</small>
                              </li>
                            ) : (
                              ""
                            );
                          })}
                          {/* <li>
                            <Link to="/blogs">Blogs</Link>
                            <small>Research, guides, interviews</small>
                          </li>

                          <li>
                            <Link to="/news">News</Link>
                            <small>Headlines in cyberspace</small>
                          </li>
                          <li>
                            <Link to="/events">Events</Link>
                            <small>
                              Stay tuned to our cybersecurity events
                            </small>
                          </li> */}
                          <li>
                            <a href="#">Use Cases</a>
                            <small>Dashboards, rules, parsers, ML</small>
                          </li>
                          <li>
                            <a href="#">Integrations</a>
                            <small>
                              Work together for a safer cyber future
                            </small>
                          </li>
                        </ul>
                      </div>
                      {/* <div
                        className="list-item"
                        style={{ width: "100%", flex: "0 0 50%" }}
                      >
                        <h4 className="title">Menu items</h4>
                        <ul>
                          <li>
                            <a href="#">sub menu items</a>
                          </li>
                          <li>
                            <a href="#">sub menu items</a>
                          </li>
                          <li>
                            <a href="#">sub menu items</a>
                          </li>
                          <li>
                            <a href="#">sub menu items</a>
                          </li>
                        </ul>
                      </div> */}
                    </div>
                  </li>
                  <li>
                    <a href="#">Pricing</a>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="header-item item-right">
              {loggedIn === "true" && <Link to="/userprofile">Profile</Link>}
              {loggedIn !== "true" ? (
                <Link to="/login">Log In</Link>
              ) : (
                <Link to="/" onClick={logOut}>
                  Log Out
                </Link>
              )}

              {loggedIn !== "true" && (
                <Link to="/signup" className="sign_up_button">
                  Sign Up
                </Link>
              )}
              <div
                className="mobile-menu-trigger"
                id="mobile-menu-trigger"
                onClick={mobileMenuTrigger}
              >
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
