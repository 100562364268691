import React from "react";
import { Link } from "react-router-dom";

const NoPage = () => {
  return (
    <div className="noPagebg">
      <div class="container-scroller">
        <div class="container-fluid page-body-wrapper full-page-wrapper">
          <div class="content-wrapper d-flex align-items-center text-center error-page noPagebg">
            <div class="row flex-grow">
              <div class="col-lg-7 mx-auto text-white">
                <div class="row align-items-center d-flex flex-row">
                  <div class="col-lg-6 text-lg-right pr-lg-4">
                    <h1 class="display-1 mb-0">404</h1>
                  </div>
                  <div class="col-lg-6 error-page-divider text-lg-left pl-lg-4">
                    <h2>SORRY!</h2>
                    <h3 class="font-weight-light">
                      The page you’re looking for was not found.
                    </h3>
                  </div>
                </div>
                {/* <div class="row mt-5">
                  <div class="col-12 text-center mt-xl-2">
                    <Link
                      to="/profile"
                      class="text-white font-weight-medium btn"
                    >
                      {" "}
                      Back To Profile
                    </Link>
                  </div>
                </div> */}
                <div class="row mt-5 noPageLink">
                  <div class="col-12 text-center mt-xl-2">
                    <Link to="/" class="font-weight-medium btn link">
                      {" "}
                      Go Back To Home
                    </Link>
                  </div>
                </div>
                <div class="row mt-5">
                  <div class="col-12 mt-xl-2">
                    <p class="font-weight-medium text-center noPageLink">
                      Copyright &copy; 2023, All Right Reserved &nbsp;
                      <a href="https://bitbyteindia.com/" target="_blank">
                        Bitbyteindia.com
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoPage;
