import React from "react";
import ParticleBackground from "react-particle-backgrounds";

function Services() {
  const settings = {
    canvas: {
      canvasFillSpace: true,
      width: 200,
      height: 200,
      useBouncyWalls: false,
    },
    particle: {
      particleCount: 60,
      color: "#98D2EB",
      minSize: 2,
      maxSize: 5,
    },
    velocity: {
      directionAngle: 0,
      directionAngleVariance: 360,
      minSpeed: 1,
      maxSpeed: 3,
    },
    opacity: {
      minOpacity: 0,
      maxOpacity: 0.5,
      opacityTransitionTime: 3000,
    },
  };
  return (
    <>
      <main className="site-wrapper">
        <ParticleBackground
          style={{
            position: "absolute",
            top: 0,
            overflow: "hidden",
            bottom: 0,
            left: 0,
            right: 0,
          }}
          className="particleBackground"
          settings={settings}
        />
        {/* <canvas className="background"></canvas> */}
        <div className="">
          <div className="page-home relative">
            <div className="container">
              <div className="row  justify-content-center align-items-center">
                <div className="col-xs-12 col-md-offset-1 col-md-10 col-lg-offset-2 col-lg-8 col_box">
                  <div className="page-title  home text-center">
                    <span className="heading-page">
                      <span className="highlighed_title">Stay Ahead </span>
                      of the Curve
                    </span>
                    <p className="mt20">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam.
                    </p>
                  </div>
                  <div className="hexagon-menu clear">
                    <div className="hexagon-item">
                      <div className="hex-item">
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                      <div className="hex-item">
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                      <a className="hex-content">
                        <span className="hex-content-inner">
                          <span className="icon">
                            <i className="fa fa-universal-access"></i>
                          </span>
                          <span className="title">Welcome</span>
                        </span>
                        <svg
                          viewBox="0 0 173.20508075688772 200"
                          height="200"
                          width="174"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                            fill="#1e2530"
                          ></path>
                        </svg>
                      </a>
                    </div>
                    <div className="hexagon-item">
                      <div className="hex-item">
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                      <div className="hex-item">
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                      <a className="hex-content">
                        <span className="hex-content-inner">
                          <span className="icon">
                            <i className="fa fa-bullseye"></i>
                          </span>
                          <span className="title">About</span>
                        </span>
                        <svg
                          viewBox="0 0 173.20508075688772 200"
                          height="200"
                          width="174"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                            fill="#1e2530"
                          ></path>
                        </svg>
                      </a>
                    </div>
                    <div className="hexagon-item">
                      <div className="hex-item">
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                      <div className="hex-item">
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                      <a className="hex-content">
                        <span className="hex-content-inner">
                          <span className="icon">
                            <i className="fa fa-braille"></i>
                          </span>
                          <span className="title">Services</span>
                        </span>
                        <svg
                          viewBox="0 0 173.20508075688772 200"
                          height="200"
                          width="174"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                            fill="#1e2530"
                          ></path>
                        </svg>
                      </a>
                    </div>
                    <div className="hexagon-item">
                      <div className="hex-item">
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                      <div className="hex-item">
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                      <a className="hex-content">
                        <span className="hex-content-inner">
                          <span className="icon">
                            <i className="fa fa-id-badge"></i>
                          </span>
                          <span className="title">Resume</span>
                        </span>
                        <svg
                          viewBox="0 0 173.20508075688772 200"
                          height="200"
                          width="174"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                            fill="#1e2530"
                          ></path>
                        </svg>
                      </a>
                    </div>
                    <div className="hexagon-item">
                      <div className="hex-item">
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                      <div className="hex-item">
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                      <a className="hex-content">
                        <span className="hex-content-inner">
                          <span className="icon">
                            <i className="fa fa-life-ring"></i>
                          </span>
                          <span className="title">Works</span>
                        </span>
                        <svg
                          viewBox="0 0 173.20508075688772 200"
                          height="200"
                          width="174"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                            fill="#1e2530"
                          ></path>
                        </svg>
                      </a>
                    </div>
                    <div className="hexagon-item">
                      <div className="hex-item">
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                      <div className="hex-item">
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                      <a className="hex-content">
                        <span className="hex-content-inner">
                          <span className="icon">
                            <i className="fa fa-clipboard"></i>
                          </span>
                          <span className="title">Testimonials</span>
                        </span>
                        <svg
                          viewBox="0 0 173.20508075688772 200"
                          height="200"
                          width="174"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                            fill="#1e2530"
                          ></path>
                        </svg>
                      </a>
                    </div>
                    <div className="hexagon-item">
                      <div className="hex-item">
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                      <div className="hex-item">
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                      <a className="hex-content">
                        <span className="hex-content-inner">
                          <span className="icon">
                            <i className="fa fa-map-signs"></i>
                          </span>
                          <span className="title">Contact</span>
                        </span>
                        <svg
                          viewBox="0 0 173.20508075688772 200"
                          height="200"
                          width="174"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                            fill="#1e2530"
                          ></path>
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Services;
