import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import _ from "lodash";
const ManageBlog = () => {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const navigate = useNavigate();
  const [blogData, setBlogData] = useState([]);
  const [paginationData, setPaginationData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  function truncate(str, n) {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  }

  useEffect(() => {
    getBlogData();
  }, []);

  const getBlogData = async () => {
    let result = await fetch(SERVER_URL + "/blogData", {
      headers: {
        authorization: JSON.parse(localStorage.getItem("token")),
      },
    });
    result = await result.json();
    setPaginationData(_(result).slice(0).take(pageSize).value());
    setBlogData(result);
  };

  const deleteBlog = async (id) => {
    console.log(id);
    const confirmation = window.confirm(
      "Are You Sure ? Want to Delete this Blog"
    );
    if (confirmation) {
      let result = await fetch(`${SERVER_URL}/blogData/${id}`, {
        method: "Delete",
      });
      result = await result.json();
      if (result) {
        getBlogData();
      }
    }
  };

  const pageSize = 10;
  const pageCount = blogData ? Math.ceil(blogData.length / pageSize) : 0;
  // console.log(pageCount);
  // if (pageCount === 0) {
  //   return null;
  // }
  const pages = _.range(1, pageCount + 1);
  function pagination(pageNo) {
    setCurrentPage(pageNo);
    const startIndex = (pageNo - 1) * pageSize;
    const paginationData = _(blogData).slice(startIndex).take(pageSize).value();

    console.log(paginationData);
    setPaginationData(paginationData);
  }
  function dateChange(str) {
    let date = new Date(str);
    return date.toDateString();
  }
  return (
    <div className="col-md-12 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4 pb-4">MANAGE BLOGS</h4>
          <div className="table-responsive">
            <table className="table table-dark table-bordered">
              <thead>
                <th>S.No</th>
                <th>Title</th>
                <th>Category</th>
                <th>Blog Type</th>
                <th>Top Type</th>
                <th>Writer's Name</th>
                {/* <th>Description</th> */}
                <th>Date</th>
                <th>Image</th>
                <th>Action</th>
              </thead>
              <tbody>
                {paginationData.length > 0 ? (
                  paginationData.map((items, index) => (
                    <tr key={items._id}>
                      <td>{index + 1}</td>
                      <td>{truncate(items.title, 30)}</td>
                      <td>{items.category.toUpperCase()}</td>
                      <td>{items.blog_type?.toUpperCase()}</td>
                      <td>{items.top_type?.toUpperCase()}</td>
                      <td>{items.writer_name}</td>
                      {/* <td>{truncate(items.description, 50)}</td> */}
                      <td>{dateChange(items.current_date)}</td>
                      <td>
                        <img
                          src={SERVER_URL + "/blog_img/" + items.blog_pic}
                          height="50px"
                          width="50px"
                        />
                      </td>
                      <td>
                        <button
                          className="btn btn-danger btnDel"
                          onClick={() => deleteBlog(items._id)}
                        >
                          Delete
                        </button>{" "}
                        &nbsp;{" "}
                        <button
                          className="btn btn-primary btnUpdate"
                          onClick={() => navigate("/updateblog/" + items._id)}
                        >
                          Update
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr class="center">
                    <td colSpan="9">No Result Found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="bg_pageNum">
            <nav className="d-flex justify-content-center">
              <ul className="pagination">
                {pages.map((page) => {
                  return (
                    <div>
                      <li
                        className={`page-item ${
                          page === currentPage ? "active" : ""
                        }
                          `}
                      >
                        {/* {page} */}
                        <button
                          className="page-link"
                          onClick={() => pagination(page)}
                        >
                          {page}
                        </button>
                      </li>
                    </div>
                  );
                })}

                {/* <li className="page-link">1</li> */}
              </ul>
            </nav>
            <div className="d-flex justify-content-center fnt">
              <p className="ln-ht">Total Rows - {blogData.length}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageBlog;
