import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
// import "bootstrap/dist/css/bootstrap.min.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
const UpdateNews = () => {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const params = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [reqMsg, setReqMsg] = useState(
    <span style={{ color: "red", fontSize: "0.875rem" }}>Field Required *</span>
  );
  const [saved, setSaved] = useState("");
  const [image, setImage] = useState("");

  const [RESImage, setRESImage] = useState("");

  const [title, setTitle] = useState("");
  const [writer_name, setWriter_name] = useState("");
  const [description, setDescription] = useState("");
  const [current_date, setCurrent_date] = useState("");
  const [time, setTime] = useState("");
  const [newsData, setNewsData] = useState({
    title: "",
    writer_name: "",
    description: "",
    current_date: "",
    time: "",
  });

  let name, value;
  const handleFormData = (e) => {
    // console.log(e);
    name = e.target.name;
    value = e.target.value;

    setNewsData({ ...newsData, [name]: value });
    // console.log({ ...newsData, [name]: value });
  };
  const handleEditorChange = (newContentState) => {
    console.log(newContentState);
    setNewsData((prevData) => ({
      ...prevData,
      // description: JSON.stringify(newContentState),
      description: newContentState,
    }));
  };
  const handleImageData = (e) => {
    // console.log(e);
    setImage(e.target.files[0]);
    // console.log(e.target.files[0]);
  };

  const getNewsData = async () => {
    // console.warn(params);
    if (params.id.length !== 24) {
      navigate("/manageNews");
      return false;
    }
    let result = await fetch(`${SERVER_URL}/newsData/${params.id}`);
    result = await result.json();
    setTitle(result.title);
    setWriter_name(result.writer_name);
    setCurrent_date(result.current_date);
    setTime(result.time);
    setDescription(result.description);

    setRESImage(result.news_pic);
  };

  useEffect(() => {
    getNewsData();
  }, []);

  const updateNews = async () => {
    // console.warn(product_name, price, category, company)
    const adminId = JSON.parse(localStorage.getItem("admin"))._id;
    if (
      !newsData.title ||
      !newsData.description ||
      // !newsData.current_date ||
      // !newsData.time ||
      !newsData.writer_name
      // ||
      // !image
    ) {
      setError(true);
      return false;
    }
    const { title, description, writer_name } = newsData;

    const formData = new FormData();

    formData.append("news_pic", image || ("news_pic", RESImage));
    // formData.append("news_pic", image);
    formData.append("title", title);
    formData.append("writer_name", writer_name);
    formData.append("description", description);
    formData.append("current_date", current_date);
    formData.append("time", time);
    formData.append("adminId", adminId);
    console.log(formData);
    let result = await fetch(`${SERVER_URL}/newsData/${params.id}`, {
      method: "PUT",
      body: formData,
    });
    const data = await result.json();
    if (result.ok) {
      setSaved(<span style={{ color: "green" }}>News Saved</span>);

      navigate("/managenews");
    } else if (data.error || data.status === 422) {
      window.alert(data.error + "Invalid");
    }
  };
  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      // ["blockquote", "code-block"],
      [{ list: "ordered" }],
      [{ align: [] }],
      ["link", "image"],
    ],
  };
  const fileInputRef = useRef(null);
  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };
  return (
    <>
      <div>
        <form class="my-form bg_main">
          <div class="col-md-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title mb-4 pb-4">UPDATE NEWS</h4>
                <div class="form-group">
                  <label>Title Of News</label>
                  <input
                    type="text"
                    placeholder="Enter Title"
                    value={newsData.title || (newsData.title = title)}
                    name="title"
                    className="form-control"
                    onChange={handleFormData}
                  />
                </div>

                <form class="forms-sample" enctype="multipart/form-data">
                  <div className="row">
                    <form class="forms-sample col">
                      <div class="form-group">
                        <label>News Image</label>
                        <input
                          type="file"
                          name="news_pic"
                          class="file-upload-default"
                          ref={fileInputRef}
                          onChange={handleImageData}
                        />
                        <div class="input-group col-xs-12">
                          <input
                            type="text"
                            class="form-control file-upload-info"
                            readOnly
                            onClick={handleUploadButtonClick}
                            placeholder={image ? image.name : "Upload Image"}
                          />
                          <span class="input-group-append">
                            <button
                              class="file-upload-browse btn appButton"
                              type="button"
                              onClick={handleUploadButtonClick}
                            >
                              Upload
                            </button>
                          </span>
                        </div>
                        {error && !image && reqMsg}
                      </div>
                      {/* <div className="form-group">
                        <label>Upload Image</label>
                        <input
                          type="file"
                          name="news_pic"
                          className="form-control"
                          onChange={handleImageData}
                        />
                        {error && !image && reqMsg}
                      </div> */}
                    </form>
                    <div class="form-group col">
                      <label>Writer's Name</label>
                      <input
                        type="text"
                        placeholder="Enter Writer's Name"
                        // value={newsData.writer_name}
                        value={
                          newsData.writer_name ||
                          (newsData.writer_name = writer_name)
                        }
                        name="writer_name"
                        className="form-control"
                        onChange={handleFormData}
                      />
                      {error && !newsData.writer_name && reqMsg}
                    </div>
                  </div>
                  {/* <div className="row">
                    <div className="form-group col">
                      <label>Date</label>
                      <input
                        type="date"
                        className="date form-control"
                        value={
                          newsData.current_date ||
                          (newsData.current_date = current_date)
                        }
                        name="current_date"
                        placeholder="Date"
                        onChange={handleFormData}
                      />
                      {error && !newsData.current_date && reqMsg}
                    </div>
                    <div className="form-group col">
                      <label>News Time</label>

                      <input
                        type="time"
                        className="form-control"
                        value={newsData.time || (newsData.time = time)}
                        name="time"
                        placeholder="Time"
                        onChange={handleFormData}
                      />
                      {error && !newsData.time && reqMsg}
                    </div>
                  </div> */}
                  {/* <div class="form-group">
                    <label>Description</label>

                    <textarea
                      rows="10"
                      placeholder="Enter The News Description"
                      value={
                        newsData.description ||
                        (newsData.description = description)
                      }
                      name="description"
                      className="form-control"
                      onChange={handleFormData}
                    />
                    {error && !newsData.description && reqMsg}
                  </div> */}
                  <div class="form-group">
                    <label>News Description</label>

                    <ReactQuill
                      placeholder="Enter The News Description"
                      theme="snow"
                      modules={modules}
                      // name="description"
                      value={
                        newsData.description ||
                        (newsData.description = description)
                      }
                      onChange={handleEditorChange}
                    />
                    {error && !newsData.description && reqMsg}
                  </div>
                  <div className="form-group">
                    <button
                      onClick={updateNews}
                      className="btn appButton"
                      type="button"
                    >
                      Update News
                    </button>{" "}
                    {saved}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default UpdateNews;
