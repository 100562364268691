import React, { useEffect, useState, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";

import Moment from "react-moment";
import moment from "moment";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
const AddNews = () => {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const [error, setError] = useState(false);
  const [reqMsg, setReqMsg] = useState(
    <span style={{ color: "red", fontSize: "0.875rem" }}>Field Required *</span>
  );
  const [saved, setSaved] = useState("");
  const [image, setImage] = useState("");

  const [newsData, setNewsData] = useState({
    title: "",
    writer_name: "",
    description: "",
    current_date: "",
    time: "",
  });

  let name, value;
  const handleFormData = (e) => {
    // console.log(e);
    name = e.target.name;
    value = e.target.value;

    setNewsData({ ...newsData, [name]: value });
    // console.log({ ...newsData, [name]: value });
  };
  const handleEditorChange = (newContentState) => {
    console.log(newContentState);
    setNewsData((prevData) => ({
      ...prevData,
      // description: JSON.stringify(newContentState),
      description: newContentState,
    }));
  };
  const handleImageData = (e) => {
    // console.log(e);
    setImage(e.target.files[0]);
    // console.log(e.target.files[0]);
  };
  const navigate = useNavigate();

  const collectData = async () => {
    // const admin = localStorage.getItem('admin');
    const date = moment();
    const d = date.format("YYYY-MM-DD");
    const t = date.format("HH:mm");
    if (
      !newsData.title ||
      !newsData.description ||
      // !newsData.current_date ||
      // !newsData.time ||
      !newsData.writer_name ||
      !image
    ) {
      setError(true);
      return false;
    }

    const { title, description, writer_name } = newsData;

    const formData = new FormData();

    formData.append("news_pic", image);
    formData.append("title", title);
    formData.append("writer_name", writer_name);
    formData.append("description", description);
    formData.append("current_date", d);
    formData.append("time", t);
    console.log(formData);
    const res = await fetch(SERVER_URL + "/newsData", {
      method: "POST",
      body: formData,
    });
    const data = await res.json();
    if (data.status === 422 || !data) {
      window.alert("Invalid Information");
    } else if (res.ok) {
      // window.alert("News Saved");
      setSaved(<span style={{ color: "green" }}>News Saved</span>);
      document.getElementById("reset").reset();
      setImage("");
      setNewsData({
        title: "",
        writer_name: "",
        description: "",
        current_date: "",
        time: "",
      });
      setTimeout(() => {
        setSaved("");
      }, 2000);
    }
  };
  const date_create = moment().format("YYYY-MM-DD");
  let newDate = new Date();
  let date_raw = newDate.getDate();
  let month_raw = newDate.getMonth() + 1;
  let year = newDate.getFullYear();

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      [{ list: "ordered" }],
      [{ align: [] }],
      ["link", "image"],
    ],
  };
  const fileInputRef = useRef(null);
  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };
  return (
    <>
      <div>
        <form class="my-form bg_main">
          <div>
            <div>
              <div class="col-md-12 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title mb-4 pb-4">ADD NEWS</h4>
                    <div className="text-center">{saved}</div>
                    <div className="text-center">
                      {/* {new Date().toLocaleString() + ""}
                      {year + "-" + month_raw + "-" + date_raw} */}
                    </div>
                    <form id="reset" enctype="multipart/form-data">
                      <div class="form-group">
                        <label>News Title</label>

                        <input
                          type="text"
                          placeholder="Enter Title"
                          value={newsData.title}
                          name="title"
                          className="form-control"
                          onChange={handleFormData}
                        />
                        {error && !newsData.title && reqMsg}
                      </div>
                      <div className="row">
                        {/* <div className="form-group col">
                          <label>News Image</label>

                          <input
                            type="file"
                            name="news_pic"
                            className="form-control"
                            onChange={handleImageData}
                          />
                          {error && !image && reqMsg}
                        </div> */}
                        <div class="form-group col">
                          <label>News Image</label>
                          <input
                            type="file"
                            name="news_pic"
                            class="file-upload-default"
                            ref={fileInputRef}
                            onChange={handleImageData}
                          />
                          <div class="input-group col-xs-12">
                            <input
                              type="text"
                              class="form-control file-upload-info"
                              readOnly
                              onClick={handleUploadButtonClick}
                              placeholder={image ? image.name : "Upload Image"}
                            />
                            <span class="input-group-append">
                              <button
                                class="file-upload-browse btn appButton"
                                type="button"
                                onClick={handleUploadButtonClick}
                              >
                                Upload
                              </button>
                            </span>
                          </div>
                          {error && !image && reqMsg}
                        </div>
                        <div class="form-group col">
                          <label>Writer's Name </label>

                          <input
                            type="text"
                            placeholder="Enter Writer's Name"
                            value={newsData.writer_name}
                            name="writer_name"
                            className="form-control"
                            onChange={handleFormData}
                          />
                          {error && !newsData.writer_name && reqMsg}
                        </div>
                      </div>
                      {/* <div className="row">
                        <div className="form-group col">
                          <label>News Date</label>

                          <input
                            type="date"
                            className="date form-control"
                            name="current_date"
                            value={newsData.current_date}
                            placeholder="Date"
                            onChange={handleFormData}
                          />
                          {error && !newsData.current_date && reqMsg}
                        </div>
                        <div className="form-group col">
                          <label>News Time</label>

                          <input
                            type="time"
                            className="form-control"
                            value={newsData.time}
                            name="time"
                            placeholder="Time"
                            onChange={handleFormData}
                          />
                          {error && !newsData.time && reqMsg}
                        </div>
                      </div> */}
                      {/* <div class="form-group">
                        <label>News Description</label>

                        <textarea
                          rows="10"
                          placeholder="Enter The News Description"
                          value={newsData.description}
                          name="description"
                          className="form-control"
                          onChange={handleFormData}
                        />
                        {error && !newsData.description && reqMsg}
                      </div> */}
                      <div class="form-group">
                        <label>News Description</label>

                        <ReactQuill
                          placeholder="Enter The News Description"
                          theme="snow"
                          modules={modules}
                          // name="description"
                          value={newsData.description}
                          onChange={handleEditorChange}
                        />
                        {error && !newsData.description && reqMsg}
                      </div>
                      <div className="form-group">
                        <button
                          onClick={collectData}
                          className="btn appButton"
                          type="button"
                        >
                          Add News
                        </button>{" "}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddNews;
