import React, { useState, useEffect } from "react";
import $ from "jquery";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  useParams,
  Navigate,
} from "react-router-dom";
import queryString from "query-string";

import "./App.css";
import SignUp from "./Components/Auth/SignUp";
import Login from "./Components/Auth/Login";
import AdminProfile from "./Components/Admin/AdminProfile";
import Nav from "./Components/Nav";
import AddBlog from "./Components/Add/AddBlog";
import ManageBlog from "./Components/Manage/ManageBlog";
import Dashboard from "./Components/GlobalComp/Dashboard";
import NavExample from "./Components/GlobalComp/NavExample";
import AddCategory from "./Components/Add/AddCategory";
import NoPage from "./Components/NoPage";
import RemoveCategory from "./Components/Manage/RemoveCategory";
import UpdateBlog from "./Components/Update/UpdateBlog";
import AddNews from "./Components/Add/AddNews";
import ManageNews from "./Components/Manage/ManageNews";
import UpdateNews from "./Components/Update/UpdateNews";
import AddEvent from "./Components/Add/AddEvent";
import ManageEvent from "./Components/Manage/ManageEvent";
import UpdateEvent from "./Components/Update/UpdateEvent";
import ChangePassword from "./Components/Admin/ChangePassword";
import ForgotPassword from "./Components/Auth/ForgotPassword";
import ResetPassword from "./Components/Auth/ResetPassword";
import PrivateComponent from "./Components/ValidationComp/PrivateComponent";
import PublicComponent from "./Components/ValidationComp/PublicComponent";

import Banner from "./Components/UserSide/home/Banner";
import Services from "./Components/UserSide/home/Services";
import About from "./Components/UserSide/home/About";

import Blog from "./Components/UserSide/Blogs/Blog";
import BlogsDetails from "./Components/UserSide/Blogs/BlogsDetails";

import News from "./Components/UserSide/News/News";
import NewsDetail from "./Components/UserSide/News/NewsDetail";

import Event from "./Components/UserSide/Events/Event";

import Header from "./Components/UserSide/common/Header";
import Footer from "./Components/UserSide/common/Footer";
import LoginUser from "./Components/UserSide/AuthUser/LoginUser";
import SignUpUser from "./Components/UserSide/AuthUser/SignUpUser";
import Visibility from "./Components/ValidationComp/Visibility";
import UpdateVisibility from "./Components/ValidationComp/UpdateVisibility";
import CheckEditor from "./Components/Add/CheckEditor";
import UserProfile from "./Components/UserSide/Profile/UserProfile";
import Verfication from "./Components/UserSide/AuthUser/Verfication";
import NewVerification from "./Components/UserSide/AuthUser/NewVerification";
import ForgotPassUser from "./Components/UserSide/AuthUser/ForgotPassUser";
import ResetPassUser from "./Components/UserSide/AuthUser/ResetPassUser";
// import PremiumComp from "./Components/ValidationComp/PremiumComp";
import PremiumBlogsDetails from "./Components/UserSide/Blogs/PremiumBlogsDetails";
function App() {
  // const auth = localStorage.getItem("admin");
  // Fetch the premium state here below in auth
  const auth = "true";
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const [visibilityData, setVisibilityData] = useState([]);

  const getvisibility = async () => {
    let result = await fetch(SERVER_URL + "/visibility", {
      headers: {
        authorization: JSON.parse(localStorage.getItem("token")),
      },
    });
    result = await result.json();
    setVisibilityData(result);
  };

  useEffect(() => {
    getvisibility();
  }, []);
  const loggedIn = localStorage.getItem("loggedIn");
  // const params = useParams();
  // const location = useLocation();
  // const { premiumType } = queryString.parse(location.search);

  return (
    <div>
      {/* {console.log(visibilityData[0]?.page_visibility === "visible")} */}
      {/* {visibilityData.map((items) => {
        return console.log(items.page), console.log(items.page_visibility);
      })} */}
      {/* <WebRoutes /> */}

      <BrowserRouter>
        <Routes>
          <Route
            exact
            path="/"
            element={
              <div>
                <Header />
                <Banner />
                <Services />
                <About />
                <Footer />
              </div>
            }
          />
          <Route
            exact
            path="/signup"
            element={
              <div>
                <Header />
                <SignUpUser />
                <Footer />
              </div>
            }
          />
          <Route
            exact
            path="/verifyemail"
            element={
              <div>
                <Header />
                <Verfication />
                <Footer />
              </div>
            }
          />
          <Route
            exact
            path="/newverifyemail"
            element={
              <div>
                <Header />
                <NewVerification />
                <Footer />
              </div>
            }
          />
          <Route
            exact
            path="/login"
            element={
              <div>
                <Header />
                <LoginUser />
                <Footer />
              </div>
            }
          />
          <Route
            exact
            path="/forgotpassword"
            element={
              <div>
                <Header />
                <ForgotPassUser />
                <Footer />
              </div>
            }
          />
          <Route
            exact
            path="/resetpassword"
            element={
              <div>
                <Header />
                <ResetPassUser />
                <Footer />
              </div>
            }
          />
          {/* {loggedIn === "true" && ( */}
          <Route
            exact
            path="/userprofile"
            element={
              <div>
                <Header />
                <UserProfile />
                <Footer />
              </div>
            }
          />
          {/* )} */}
          {visibilityData[0]?.page_visibility === "visible" && (
            <Route
              exact
              path="/blogs"
              element={
                <div>
                  <Header />
                  <Blog />
                  <Footer />
                </div>
              }
            />
          )}
          {/* <Route element={<PremiumComp />}> */}
          {visibilityData[0]?.page_visibility === "visible" && (
            <Route
              exact
              path="/blog-details/:id"
              element={
                <div>
                  <Header />
                  <BlogsDetails />
                  <Footer />
                </div>
              }
            />
          )}
          {visibilityData[0]?.page_visibility === "visible" && loggedIn && (
            <Route
              exact
              path="/premium-blog-details/:id"
              element={
                <div>
                  <Header />
                  <PremiumBlogsDetails />
                  <Footer />
                </div>
              }
            />
          )}
          {/* </Route> */}
          {visibilityData[1]?.page_visibility === "visible" && (
            <Route
              exact
              path="/news"
              element={
                <div>
                  <Header />
                  <News />
                  <Footer />
                </div>
              }
            />
          )}
          {visibilityData[1]?.page_visibility === "visible" && (
            <Route
              exact
              path="/news-description/:id"
              element={
                <div>
                  <Header />
                  <NewsDetail />
                  <Footer />
                </div>
              }
            />
          )}
          {visibilityData[2]?.page_visibility === "visible" && (
            <Route
              exact
              path="/events"
              element={
                <div>
                  <Header />
                  <Event />
                  <Footer />
                </div>
              }
            />
          )}
          <Route element={<PublicComponent />}>
            {/* <Route exact path="/admin-signup" element={<SignUp />} /> */}

            <Route
              exact
              path="/forgotpass"
              element={
                <div className="bg_main">
                  <div className="container-scroller">
                    <Dashboard />
                    <div className="container-fluid page-body-wrapper">
                      <div className="main-panel">
                        <NavExample />
                        <div className="content-wrapper bg_main">
                          <ForgotPassword />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            />

            <Route
              exact
              path="/resetpass"
              element={
                <div className="bg_main">
                  <div className="container-fluid page-body-wrapper">
                    <div className="main-panel">
                      <NavExample />
                      <div className="content-wrapper bg_main">
                        <ResetPassword />
                      </div>
                    </div>
                  </div>
                </div>
              }
            />

            <Route
              exact
              path="/admin-login"
              element={
                <div className="bg_main">
                  <div className="container-scroller">
                    <Dashboard />
                    <div className="container-fluid page-body-wrapper">
                      <div className="main-panel">
                        <NavExample />
                        <div className="content-wrapper bg_main">
                          <Login />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            />
          </Route>
          <Route element={<PrivateComponent />}>
            <Route
              exact
              path="/profile"
              element={
                <div className="container-scroller">
                  <Dashboard />
                  <div className="container-fluid page-body-wrapper">
                    <div className="main-panel">
                      <NavExample />
                      <div className="content-wrapper bg_main">
                        <AdminProfile />
                      </div>
                    </div>
                  </div>
                </div>
              }
            />

            <Route
              exact
              path="/changepass"
              element={
                <div className="container-scroller">
                  <Dashboard />
                  <div className="container-fluid page-body-wrapper">
                    <div className="main-panel">
                      <NavExample />
                      <div className="content-wrapper bg_main">
                        <ChangePassword />
                      </div>
                    </div>
                  </div>
                </div>
              }
            />

            <Route
              exact
              path="/addcategory"
              element={
                <div className="container-scroller">
                  <Dashboard />
                  <div className="container-fluid page-body-wrapper">
                    <div className="main-panel">
                      <NavExample />
                      <div className="content-wrapper bg_main">
                        <AddCategory />
                      </div>
                    </div>
                  </div>
                </div>
              }
            />

            <Route
              exact
              path="/rmcategory"
              element={
                <div className="container-scroller">
                  <Dashboard />
                  <div className="container-fluid page-body-wrapper">
                    <div className="main-panel">
                      <NavExample />
                      <div className="content-wrapper bg_main">
                        <RemoveCategory />
                      </div>
                    </div>
                  </div>
                </div>
              }
            />

            <Route
              exact
              path="/addblog"
              element={
                <div className="container-scroller">
                  <Dashboard />
                  <div className="container-fluid page-body-wrapper">
                    <div className="main-panel">
                      <NavExample />
                      <div className="content-wrapper bg_main">
                        <AddBlog />
                      </div>
                    </div>
                  </div>
                </div>
              }
            />

            <Route
              exact
              path="/updateblog/:id"
              element={
                <div className="container-scroller">
                  <Dashboard />
                  <div className="container-fluid page-body-wrapper">
                    <div className="main-panel">
                      <NavExample />
                      <div className="content-wrapper bg_main">
                        <UpdateBlog />
                      </div>
                    </div>
                  </div>
                </div>
              }
            />

            <Route
              exact
              path="/manageblog"
              element={
                <div className="container-scroller">
                  <Dashboard />
                  <div className="container-fluid page-body-wrapper">
                    <div className="main-panel">
                      <NavExample />
                      <div className="content-wrapper bg_main">
                        <ManageBlog />
                      </div>
                    </div>
                  </div>
                </div>
              }
            />
            <Route
              exact
              path="/managevisibility"
              element={
                <div className="container-scroller">
                  <Dashboard />
                  <div className="container-fluid page-body-wrapper">
                    <div className="main-panel">
                      <NavExample />
                      <div className="content-wrapper bg_main">
                        <Visibility />
                      </div>
                    </div>
                  </div>
                </div>
              }
            />
            {/* <Route
              exact
              path="/updatevisibility/:id"
              element={
                <div className="container-scroller">
                  <Dashboard />
                  <div className="container-fluid page-body-wrapper">
                    <div className="main-panel">
                      <NavExample />
                      <div className="content-wrapper bg_main">
                        <UpdateVisibility />
                      </div>
                    </div>
                  </div>
                </div>
              }
            /> */}
            {/* <Route
              exact
              path="/checkeditor"
              element={
                <div className="container-scroller">
                  <Dashboard />
                  <div className="container-fluid page-body-wrapper">
                    <div className="main-panel">
                      <NavExample />
                      <div className="content-wrapper bg_main">
                        <CheckEditor />
                      </div>
                    </div>
                  </div>
                </div>
              }
            /> */}

            <Route
              path="/addnews"
              element={
                <div className="container-scroller">
                  <Dashboard />
                  <div className="container-fluid page-body-wrapper">
                    <div className="main-panel">
                      <NavExample />
                      <div className="content-wrapper bg_main">
                        <AddNews />
                      </div>
                    </div>
                  </div>
                </div>
              }
            />

            <Route
              exact
              path="/managenews"
              element={
                <div className="container-scroller">
                  <Dashboard />
                  <div className="container-fluid page-body-wrapper">
                    <div className="main-panel">
                      <NavExample />
                      <div className="content-wrapper bg_main">
                        <ManageNews />
                      </div>
                    </div>
                  </div>
                </div>
              }
            />

            <Route
              exact
              path="/updatenews/:id"
              element={
                <div className="container-scroller">
                  <Dashboard />
                  <div className="container-fluid page-body-wrapper">
                    <div className="main-panel">
                      <NavExample />
                      <div className="content-wrapper bg_main">
                        <UpdateNews />
                      </div>
                    </div>
                  </div>
                </div>
              }
            />

            <Route
              exact
              path="/addevent"
              element={
                <div className="container-scroller">
                  <Dashboard />
                  <div className="container-fluid page-body-wrapper">
                    <div className="main-panel">
                      <NavExample />
                      <div className="content-wrapper bg_main">
                        <AddEvent />
                      </div>
                    </div>
                  </div>
                </div>
              }
            />

            <Route
              exact
              path="/manageevent"
              element={
                <div className="container-scroller">
                  <Dashboard />
                  <div className="container-fluid page-body-wrapper">
                    <div className="main-panel">
                      <NavExample />
                      <div className="content-wrapper bg_main">
                        <ManageEvent />
                      </div>
                    </div>
                  </div>
                </div>
              }
            />

            <Route
              exact
              path="/updateevent/:id"
              element={
                <div className="container-scroller">
                  <Dashboard />
                  <div className="container-fluid page-body-wrapper">
                    <div className="main-panel">
                      <NavExample />
                      <div className="content-wrapper bg_main">
                        <UpdateEvent />
                      </div>
                    </div>
                  </div>
                </div>
              }
            />
          </Route>
          <Route exact path="*" element={<NoPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
